interface Constant {
    [key: string]: string
}
export const Routes: Constant = {
    DASHBOARD: '/dashboard',
    DEFAULT: '/',
    HOME: '/home',
    APPLICATION_ROUTE: '/application',
    ANNOUNCEMENTS: '/announcements',
    DASHBOARD_NEW: '/dashboard_new',
    REPORTS: '/reports',
    POLICIES: '/policies',
    ASSIGN: '/assign',
    TASK_LIST: '/tasklist',
    ADD_NEW_WORK_Item: '/addWorkItem',
    SUPPORT_TICKET: '/supportTicket',
    CREATE_SUPPORT_TICKET: '/createSupportTicket',
    EDIT_SUPPORT_TICKET: '/editSupportTicket',
    RESOURCE_MANAGER_TOOLS: '/resourceManagerTools',
    RESOURCE_MANAGER_TOOLS_NEW: '/resourceManagerToolsNew',
    RESOURCE_MANAGER_EDIT: '/resourceManagerEdit',
    PROJECTION_PO: '/projectionPO',
    PROJECTION_ADMIN: '/projectionAdmin',
    PROJECTION_CONTAINER: '/projections',
    ALLOCATION_CONTAINER: '/allocationContainer',
    REPORTS_CONTAINER: '/reportsContainer',
}

export const AppConstant: Constant = {
    USER_ID: 'userId',
    ACCOUNT: 'account',
    ROLE: 'role',
    APPLICATIONS: 'Applications',
    ADMIN_ROLE: 'adminRole',
    AUTHENTICATED: 'Authenticated',
    ADMIN: 'Admin',
    JWT_TOKEN: 'jwt_token',
    ASSIGN: 'Assign',
    UN_ASSIGN: 'Unassign',
    APPLICATION_TYPE: 'application',
    USER_GROUP: 'User Group',
    HOME: 'home',
    GROUP: 'group',
    ALL_APPS: 'All Apps',
    ASSIGN_APPLICATION: 'Assign Application',
    SEARCH: 'search',
    TASK_LIST: 'tasklist',
}

export const ButtonName: Constant = {
    CREAT_NEW_WORKITEM: 'Create new WorkItem',
    ADD_WATCHER: 'Add Watcher',
    FILL_FORM_TEMPLATE: 'Fill Form Template',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    ADD_CHILD_TASK: 'Add Child Task',
    UPLOAD_FILE: 'Upload file',
    SUBMIT: 'Submit',
    ASSIGN_COPIES: 'Assign Copies',
    CREATE_SUPPORT_TICKET: 'Create Support Ticket',
    CHOOSE_FILE: 'Choose file',
}

export const Color: Constant = {
    RED: '#DE3831',
    GREY: '#6C6F70',
}

export const Logo: Constant = {
    HEADER_LOGO:
        'https://desk.zoho.com/portal/api/publicImages/153618000008347091?portalId=edbsn6f4f4877fc34ff735778208854c58be76edf4a926c699fdf8fe9e3e53ad21b7d',
}
