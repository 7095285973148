import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Policy.module.css';
import Download from '../../Assets/Images/download.svg';
import File from '../../Assets/Images/pdf-file.svg';
import Folder from '../../Assets/Images/folder.svg';
import Home from '../../Assets/Images/home.svg';
import Back from '../../Assets/Images/back-arrow.svg';
import { setUILoaderState } from '../../actions/UIAction';
import { connect } from 'react-redux';
import { API_URL } from '../../Services/Constants';

interface Props {

}


const getHash = (key) => {
    const substr = window.location.hash.substr(1);
    const items = substr ? substr.split('&') : [];
    items.forEach(element => {
        const array = element.split('=');
        if (array[0] === key) {
            return array[1];
        }
    });
    return substr ? substr.split('=')[1] : '/';
}

const PolicyComponent = (props) => {

    const history = useHistory();
    const baseURL = 'https://optimusinfo.sharepoint.com/Shared%20Documents';
    let items = [];
    const [displayItems, setDisplayItems] = useState([]);

    useEffect(() => {

        window.addEventListener("hashchange", handleHashChange);

        props.setUILoaderState(true);

        window.location.hash = `path=/`;

        axios.get(`${API_URL}/api/sharepoint`).then(response => {

            items = response.data;
            props.setUILoaderState(false);
            handleHashChange();

        });

    }, []);

    const handleHashChange = () => {

        const hashValue = getHash('path');

        const strCompare = baseURL + hashValue;

        const toDisplay = items.filter(item => {

            const { webUrl = '' } = item;

            const replacedString = webUrl.replace(strCompare, '');

            return replacedString && replacedString.indexOf('/') === -1;


        });

        setDisplayItems(items);

    }

    const heading = (<div className={`${styles.item} ${styles.heading}`}>
        <div className={styles.heading}>
            <span className={styles.name}> NAME </span>
            <span className={styles.modified}>MODIFIED BY</span>
            <span className={styles.type}> Type </span>
            {<span></span>}
        </div>
    </div>);

    const handleItemClick = (item: any) => {

        if (item.folder) {
            window.location.hash = `path=/${getName(item.webUrl)}/`;
        } else {
            window.open(item.webUrl, '_blank');
        }

    }

    const getName = (webUrl) => {
        const hashValue = getHash('path');
        return decodeURI(webUrl.replace(baseURL + hashValue, ''));
    }

    const handleDownload = (event) => {
        event.stopPropagation();
    }

    const goTo = (path) => {
        window.location.hash = `path=${path}`;
    }

    const goBack = () => {
        const pathValue = getHash('path');
        if (pathValue !== '/') {
            history.goBack();
        }
    }

    const backContainer = (<div className={styles.backContainer}>
        <img onClick={() => goTo('/')} className={styles.icons} src={Home} />
        <img onClick={() => goBack()} className={styles.icons} src={Back} />
    </div>);

    const gridMarkup = displayItems.map((item: any, index) => {

        const { name, lastModifiedBy, microsoftGraphDownloadUrl } = item;

        return <div key={index} className={styles.item}>
            <div
                className={item.file ? styles.file : styles.folder}
                onClick={() => handleItemClick(item)}
            >
                <span className={styles.name}> {name} </span>
                <span className={styles.modified}> {lastModifiedBy.user.displayName} </span>
                {<span className={styles.type}>
                    <img src={item.file ? File : Folder} />
                </span>}
                {item.file ? <span className={styles.download}>
                    <a href={microsoftGraphDownloadUrl} download onClick={handleDownload}>
                        <img src={Download} />
                    </a>
                </span> : <span></span>}
            </div>
        </div>
    });

    return <div className={styles.container}>
        {backContainer}
        {heading}
        {gridMarkup}
    </div>
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
    setUILoaderState: (isLoading: boolean) => dispatch(setUILoaderState(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(PolicyComponent);
