import { put, call, takeEvery, select, take, fork, all } from 'redux-saga/effects';
import { ITEM } from '../actions/types';
import watchLogin from "./authenticationSaga";
import watchApplicationAndUserListLoad from './ApplicationAndUserSaga';
import { UIActionConstants } from '../actions/Constants';
import { resolveModalPromise } from '../actions/UIAction';
import { AppConstant } from '../Constants';
import watchWorkItemLoad from './WorkItemSaga';
import watchTemplateLoad from './TemplateSaga';

function* setModalFunction(action) {
  yield put(resolveModalPromise(action.data));
}
export function *modalFunction() {
  yield takeEvery(UIActionConstants.ASYNC_MODAL_FUNCTION, setModalFunction)
}

export default function* rootSaga(): any {
    while (true) {
        // const task = yield fork(watchLogin, accessToken);
        yield all([logoutWatch(), 
                   watchApplicationAndUserListLoad(), 
                   modalFunction(), 
                   watchWorkItemLoad(),
                   watchTemplateLoad()
                  ]);
        yield takeEvery(UIActionConstants.ASYNC_MODAL_FUNCTION, setModalFunction)
    }
}

export function* loginFunc(data) {
  const {data: {authState: accessToken}} = data;
  const task = yield fork(watchLogin, accessToken);
}

export function *logoutWatch() {
  yield takeEvery(ITEM.GET_JWT_TOKEN, loginFunc);
}
