import { put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { WorkItemList } from '../actions/types';

import {  asyncActionModal, setModalState, setUILoaderState } from '../actions/UIAction';
import { deleteWorkItemService, getWorkItemList } from '../Services/WorkItemAndTaskService/WorkItemList';
import { callWorkItemApi, deleteWorkItem, setWorkItemList } from '../actions/WorkItemActions';
import { WorkItem } from '../Interfaces/WorkItemInterface';
import { WorkItemStatusEnum } from '../Enum/WorkItemStatus.enum';
import { setErrorMessage } from '../actions/ErrorsAction';

function addDepth(taskItem, depth = 0) {
    taskItem.forEach(obj => {
      obj['depth'] = depth;
      obj['isOpen'] = false;
      obj['isWatched'] = setWatcherState(obj);
      obj['showAddItemInput'] = false
      obj.state = obj.workItemStatusId === 1 ? WorkItemStatusEnum[WorkItemStatusEnum.Assigned] :
      obj.workItemStatusId === 2 ? WorkItemStatusEnum[WorkItemStatusEnum.Closed] :
      obj.workItemStatusId === 3 ? WorkItemStatusEnum[WorkItemStatusEnum.Completed] :
      obj.workItemStatusId === 4 ? WorkItemStatusEnum[WorkItemStatusEnum.InProgress] :
      WorkItemStatusEnum[WorkItemStatusEnum.New]
      obj.updatedAt = obj.updatedAt.split('T')[0];
      addDepth(obj.childWorkItems, depth + 1)
    });
}

function setWatcherState(taskItem) {
    const userId: any = localStorage.getItem('userId');
    if(taskItem.watchers !== null) {
        const watcherId = taskItem.watchers.findIndex(watcher => {
            return watcher.userId === +userId;
        })
        return watcherId === -1 ? false: true;
    }
}

export function* handleWorkItemList(params) {
    try {
        //yield put(setUILoaderState(true));
        const {params: fetchData, filterParams} = params.data;
        const WorkItemList: WorkItem[] = yield call(getWorkItemList, fetchData, filterParams);
        addDepth(WorkItemList, 0);
        yield put(setWorkItemList(WorkItemList));
        //yield put(setUILoaderState(false));
    } catch (error) {
        //yield put(setUILoaderState(false));
    }
}

export function* handleDeleteWorkItem(params) {
    try {
        yield put(setUILoaderState(true));
        const {id, guid, taskId, filterParams} = params.data;
        const response: any = yield call(deleteWorkItemService, id, guid);
        if(response && response.response) {
            yield put (setErrorMessage(
                {
                    errorMessage: " ",
                    errorMessageHeader: `${response && response.response ? response.response.data.errorCause 
                        : 'Something went wrong'}`
                }
            ))
            yield put(setModalState(true));
            yield put(asyncActionModal(() => {setModalState(false)}))
        }

        if(taskId === 1) {
            const userId = localStorage.getItem('userId');
            const userGuid = localStorage.getItem('guid');
            yield put(callWorkItemApi({userId, userGuid,filterParams}));
        } else if(taskId === 2) {
            const paramsObject = Object.assign({}, {
                IsWatchedWorkItems: true,
                userId: localStorage.getItem('userId'),
                userGuid: localStorage.getItem('guid'),
                filterParams: filterParams
            });
            yield put(callWorkItemApi(paramsObject));
        } else {
            yield put(callWorkItemApi(undefined, filterParams));
        }
        //yield put(setUILoaderState(false));
    } catch (error) {
        yield put(setUILoaderState(false));
        yield put (setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: `${error && error.response ? error.response.errorCause 
                    : 'Something went wrong'}`
            }
        ))
        yield put(setModalState(true));
        yield put(asyncActionModal(() => {setModalState(false)}))
    }
}

export default function* watchWorkItemLoad() {
    yield takeEvery(WorkItemList.CALL_WORKITEM, handleWorkItemList);
    yield takeEvery(WorkItemList.DELETE_WORKITEM, handleDeleteWorkItem);
}
