import React from 'react'
import axios from 'axios'
import { API_URL } from 'Services/Constants'
//service
const paramApi = 'CharudattaShinde'
const paramOrg = 'Optimus-Projects'
const assignGroupsToUser = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/azureactivedirectory/AssignGroupToUser`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const assignOrganisationToUser = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/UserToOrganization?organizationName=${paramApi}`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const assignProjectToUser = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/AddToProject?organizationName=${paramApi}`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const addToProjectWithOutOrg = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/AddToProjectWithOutOrg?organizationName=${paramOrg}`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}
const getGroups = async () => {
    try {
        const baseUrl = `${API_URL}/api/azureactivedirectory/GetGroups`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getUserDetails = async (userPrincipalName) => {
    try {
        const baseUrl = `${API_URL}/api/users/UserDetail?userAdId=${userPrincipalName}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getUsersList = async () => {
    try {
        const baseUrl = `${API_URL}/api/azureactivedirectory/ListUsers`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjects = async (organisation) => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/GetProjects?_getProjectName=${organisation}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjectsFromKickOffBilling = async () => {
    try {
        const baseUrl = `${API_URL}/api/kickoffandbilling/GetProjectList`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getReportingManagers = async () => {
    try {
        const baseUrl = `${API_URL}/api/ZohoAccount/Designation/ReportingManagers`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getOrganisations = async () => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/GetOrganization?organizationName=${paramOrg}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getTeamId = async (organisationName, projectId) => {
    try {
        const baseUrl = `${API_URL}/api/azuredevops/GetTeamId?organizationName=${organisationName}&projectId=${projectId}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const sendResourceItemForm = async (postFormData, personalEmail) => {
    try {
        const baseUrl = `${API_URL}/api/azureactivedirectory?personalMailId=${personalEmail}`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const createZohoUser = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/ZohoAccount/CreateUser`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const updateUser = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/users/UpdateUser`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.patch(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjection = async ({ projectId, projectOwnerId, year, month }) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/GetProjection?projectId=${projectId.id}&month=${month.id}&year=${year.id}&projectOwnerId=${projectOwnerId.id}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const createProjection = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/Create`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const updateProjection = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/Update`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjectionList = async ({
    year,
    month,
}) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/GetProjectionList?month=${month.id}&year=${year.id}`
        
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}


const lockProjection = async (month,year) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/LockProjection?month=${month}&year=${year}`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, "",config)
    } catch (e) {
        console.log(e.config)
    }
}
const getZohoNextEmployeeId = async () => {
    try {
        const baseUrl = `${API_URL}/api/ZohoAccount/GetNextAvailableEmployeeId`
        
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjectionListCsv = async ({
    year,
    month,
}) => {
    try {
        const baseUrl = `${API_URL}/api/Projection/GetProjectionListCsv?month=${month.id}&year=${year.id}`
        
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getProjectOwners = async () => {
    try {
        const baseUrl = `${API_URL}/api/Projection/ProjectOwners`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
export {
    assignGroupsToUser,
    sendResourceItemForm,
    getProjects,
    getGroups,
    assignOrganisationToUser,
    createZohoUser,
    getReportingManagers,
    getOrganisations,
    getTeamId,
    assignProjectToUser,
    getUsersList,
    getUserDetails,
    updateUser,
    addToProjectWithOutOrg,
    getProjection,
    createProjection,
    getProjectsFromKickOffBilling,
    updateProjection,
    getProjectionList,
    lockProjection,
    getZohoNextEmployeeId,
    getProjectionListCsv,
    getProjectOwners
}
