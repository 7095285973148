import React from 'react';
import styles from './Search.module.css';

interface Props {
    onChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode[];
    value: string;
}

const SearchComponent = (props) => {
    const {onChange, value} = props;
    return (
        <div className = {styles.moveRight} style = {props.style}>
        <input 
            type="text" 
            placeholder="Search.."
            onKeyDown={props.keyPress}
            name="search"
            id = "search"
            onChange = {(event) => props.searchUserTable(event.target.value)}
        />
        <div className = {styles.searchIcon} onClick = {props.searchList}>
            <span className="material-icons">
                search
            </span>
        </div>
        </div>
    )
  };
export default SearchComponent;
