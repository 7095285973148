import React from 'react'
import axios from 'axios'
import { API_URL } from 'Services/Constants'
//service
const paramApi = 'CharudattaShinde'
const paramOrg = 'Optimus-Projects'

const getProjectViewReports = async (date, projectId) => {
    try {
        const baseUrl = `${API_URL}/api/Report/GetProjectViewReport?MonthAndYear=${date}&ProjectId=${projectId}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getProjectViewReportsFromAllocation = async (year, month, projectId) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/Report/ProjectwiseMonthlyReport?projectId=${projectId}&month=${month}&year=${year}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getUserViewReportsFromAllocation = async (
    year,
    month,
    userMail,
    page
) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/Report/UserViewMonthlyReport?userMail=${userMail}&month=${month}&year=${year}&PageNo=${page}&PageSize=10`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getUserList = async () => {
    try {
        const baseUrl = `${API_URL}/api/ZohoAccount/GetEmployeeDetailsList`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
const getUserListCsv = async (year, month, userMail, page) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/Report/GetUserViewMonthlyReportCSV?userMail=${userMail}&month=${month}&year=${year}&PageNo=${page}&PageSize=10`

        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getProjectReportListCsv = async (date, projectId) => {
    try {
        const baseUrl = `${API_URL}/api/Report/GetProjectViewReportCsv?ProjectId=${projectId}&MonthAndYear=${date}`

        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}
export {
    getProjectViewReports,
    getProjectViewReportsFromAllocation,
    getUserViewReportsFromAllocation,
    getUserList,
    getUserListCsv,
    getProjectReportListCsv,
}
