import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from '../../../../Components/Button/Button';
import { Multiselect } from 'multiselect-react-dropdown';

export function AddWatcherModal(props) {
    const {showModal, selectedValues, currRef} = props;
    const [show, setShow] = useState(false);
    const [watcherList, setWatcherList] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSelect = (selectedList, selectedItem) => {
        setWatcherList(selectedList);
    }
     
    const onRemove = (selectedList, removedItem) => {
        setWatcherList(selectedList);
    }
  
    return (
        <Modal
          show={showModal}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Watcher</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Multiselect
            options={props.options} // Options to display in the dropdown
            selectedValues={props.selectedValue} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue= {props.propertyName} // Property name to display in the dropdown options
            />          
        </Modal.Body>
          <Modal.Footer>
          <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={props.onHide}
            name ={'Cancel'}/>
        <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => props.setModalShow(currRef, watcherList)}
            name ={'Ok'}/>
          </Modal.Footer>
        </Modal>
    );
  }