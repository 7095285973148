import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AppConstant, Routes } from '../Constants';
import { authProvider } from '../Services/Authentication/AuthProvider';

export interface ProtectedRouteProps extends RouteProps {
    isAuthenticated?: boolean;
    authenticationPath?: string;
    children?: React.ReactNode
}

export function ProtectedRoute(props) {

    const { location: { pathname } } = props;
    const roleName = localStorage.getItem(AppConstant.ADMIN_ROLE);

    if (authProvider.authenticationState !== AppConstant.AUTHENTICATED) {
        const redirectPath: string = Routes.DASHBOARD;
        const renderComponent = () => (<Redirect to={{ pathname: redirectPath }} />);
        return <Route {...props} component={renderComponent} render={undefined} />;
    } if (roleName !== AppConstant.ADMIN && (pathname === Routes.DEFAULT
        || pathname.includes(Routes.APPLICATION_ROUTE)
    )) {
        const redirectPath: string = Routes.DASHBOARD;
        const renderComponent = () => (<Redirect to={{ pathname: redirectPath }} />);
        return <Redirect to={{ pathname: redirectPath }} />;
    } else {
        if (pathname === Routes.DEFAULT) {
            const redirectPath: string = Routes.HOME;
            const renderComponent = () => (<Redirect to={{ pathname: redirectPath }} />);
            return <Route {...props} component={renderComponent} render={undefined} />;
        } if (roleName !== AppConstant.ADMIN && (pathname === Routes.DEFAULT || pathname.includes(Routes.APPLICATION_ROUTE))) {
            const redirectPath: string = Routes.HOME;
            return <Redirect to={{ pathname: redirectPath }} />;
        } else {
            if (pathname === Routes.DEFAULT) {
                const redirectPath: string = Routes.HOME;
                return <Redirect to={{ pathname: redirectPath }} />
            } else {
                return <Route exact {...props} component={props.component} />;
            }
        }
    }
}