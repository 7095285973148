import axios from '../Interceptor';
import { API_URL } from '../Constants';

const getApplicationList = async(userGroupId: number) => {
    const baseUrl = `${API_URL}/api/user-management/groups/${userGroupId}/applications`;
    try {
        const response = await axios.get(baseUrl);
        const data = response.data;
        if (response.status >= 400) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e.config);
    }
};

const getUserApplicationList = async(userId) => {
    const baseUrl = `${API_URL}/api/applications/${userId}`;
    try {
        const response: any = await axios.get(baseUrl);
        const data = response.data;
        if (response?.response?.status === 401) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e.config);
    }
}

const getUserGroupList = async() => {
    const baseUrl = `${API_URL}/api/user-management/groups`;
    try {
        const response = await axios.get(baseUrl);
        const data = response.data;
        if (response.status >= 400) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e.config);
    }
};

const postApplicationList = async(postData) => {
    const baseUrl = `${API_URL}/api/user-management/application-assignment`
    try {
        const response = await axios.post(baseUrl, postData);
        const data = response.data;
        if (response.status >= 400) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e.config);
    }
};

const getAllUsers = async() => {
    const baseUrl = `${API_URL}/api/users`;
    try {
        return await axios.get(baseUrl) as any;
    } catch(e) {
        console.log(e.config);
    }
}

export {getUserGroupList, getApplicationList, postApplicationList, getUserApplicationList, getAllUsers};