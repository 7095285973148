import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { ButtonComponent } from '../../../../Components/Button/Button';
import { Category } from '../../../../Enum/CategoryEnum';
import { SubCategory } from '../../../../Enum/SubCategoryEnum';
import { TemplateForm, WorkItemTemplate } from '../../../../Models/AuthenticationModels/TemplateModels/TemplateFormModel';

interface DropDownList {
    id: number,
    name: string
}

export function SaveAsTemplateModal(props) {
    const {showModal, selectedValues, currRef} = props;
    const [show, setShow] = useState(false);
    const [categoryList, setCategoryList] = useState<DropDownList[]>([]);
    const [subCategoryList, setSubCategoryList] = useState<DropDownList[]>([]);
    const [templateForm, setTemplateForm] = useState<TemplateForm>(new TemplateForm());
    const [workItemTemplate, setWorkItemTemplate] = useState<WorkItemTemplate>(new WorkItemTemplate());

    const setTemplate = (type, value) => {
        switch(type) {
            case 'category': {
                setTemplateForm(
                    {
                        ...templateForm,
                        workItemTemplate: {
                            ...templateForm.workItemTemplate,
                            categoryId: value,
                            category: {
                                id: value,
                                name: Category[value] 
                            }
                        }
                    }
                );
                break;
            }
            case 'sub category': {
                setTemplateForm(
                    {
                        ...templateForm,
                        workItemTemplate: {
                            ...templateForm.workItemTemplate,
                            subcategoryId: value,
                            subCategory: {
                                id: value,
                                name: SubCategory[value] 
                            }
                        }
                    }
                );
                break;
            }
            case 'name': {
                setTemplateForm(
                    {
                        ...templateForm,
                        workItemTemplate: {
                            ...templateForm.workItemTemplate,
                            name: value
                        }
                    }
                );
                break;
            }
        }
    }

    useEffect(() => {
        setTemplateForm({
            ...templateForm,
            workItemTemplate: {
                ...templateForm.workItemTemplate,
                workItem: {...props.workItem} as any
            }
        });
    }, [props.workItem])
    useEffect(() => {
        let categories: any[] = [];
        let subCategories: any[] = [];
        const categoryLength: number = Object.values(Category).length;
        let currentLength: number = 0;
        for(const key in Category) {
            if(currentLength < categoryLength && currentLength >= categoryLength/2)
            categories.push({
                id: +Category[key],
                name: key
            });
            currentLength++;
        }
        const subCategoryLength: number = Object.values(SubCategory).length;
        currentLength = 0;
        for(const key in SubCategory) {
            if(currentLength < subCategoryLength && currentLength >= subCategoryLength/2)
            subCategories.push({
                id: +SubCategory[key],
                name: key
            });
            currentLength++;
        }
        setCategoryList(categories);
        setSubCategoryList(subCategories);
    }, [])
    const category = [`Category`].map((data, index) =>  <Col key ={index} 
    className = "mt-3"
    md={12}>
    <DropdownButton
        className = "align-dropdown"
        key={"secondary"}
        id={`dropdown-variants-secondary`}
        variant={"secondary"}
        title={`${templateForm.workItemTemplate.category.name}`}
    >
    {categoryList.map((data, index) => {
        return <Dropdown.Item
            key = {index}
            eventKey = {`${index + 1}`}
            onClick = {() => setTemplate('category', data.id)}
            >
        {data.name}
        </Dropdown.Item>
    })}
    </DropdownButton>
    </Col>)
        const subCategory = [templateForm.workItemTemplate.subCategory.name].map((data, index) =>  <Col key ={index} 
        className = "mt-3"
        md={12}>
        <DropdownButton
            className = "align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={templateForm.workItemTemplate.subCategory.name}
        >
        {subCategoryList.map((data, index) => {
            return <Dropdown.Item
                key = {index}
                eventKey = {`${index + 1}`}
                onClick = {() => setTemplate('sub category', data.id)}
                >
            {data.name}
            </Dropdown.Item>
        })}
        </DropdownButton>
        </Col>)
    return (
        <Modal
          show={showModal}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton onClick = {() => {
              props.setTemplateModal(props.thisRef)
          }}>
            <Modal.Title>Save as Template</Modal.Title>
          </Modal.Header>
          <Form>
          <Modal.Body>
                <Row>
                    <Col md = {12} >
                        <Form.Control 
                        placeholder = "Name*"
                        required
                        onChange = {(event) => setTemplate('name', event.target.value)}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col md = {6}className = "pl-0">
                        {category}
                    </Col>
                    <Col md = {6} className = "pr-0">
                        {subCategory}
                    </Col>
                </Row>
        </Modal.Body>
          <Modal.Footer>
          <ButtonComponent style = {{width: '80px'}}
            size = 'sm' 
            onClick={() => {
                props.setTemplateModal(props.thisRef)
            }}
            name ={'Cancel'}/>
        <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => {
                props.SaveAsTemplate(templateForm, props.thisRef);
            }}
            name ={'Ok'}
            disabled = {
                templateForm.workItemTemplate.name === workItemTemplate.name
                || templateForm.workItemTemplate.categoryId === workItemTemplate.categoryId
                || templateForm.workItemTemplate.subcategoryId === workItemTemplate.subcategoryId
            }
            />
          </Modal.Footer>
          </Form>
        </Modal>
    );
  }