import React from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap'
import ProjectionAdmin from './ProjectionAdmin'
import ProjectionPo from './ProjectionPo'
function ProjectionContainer() {
    interface StateAdmin {
        isLocked: boolean
        projectionDropDownItems: any
    }
    return (
        <React.Fragment>
            <Tabs
                defaultActiveKey="listOfTickets"
                id="uncontrolled-tab-example"
            >
                <Tab
                    eventKey="listOfTickets"
                    title="Add Projection"
                    statusSet={new Set([])}
                >
                    <div id="firstTab">
                        <ProjectionPo />
                    </div>
                </Tab>
                {localStorage.getItem('adminRole') != 'Admin' ? null : (
                    <Tab eventKey="assignedTickets" title="Projection Admin">
                        <div id="secondTab">
                            <ProjectionAdmin  />
                        </div>
                    </Tab>
                    
                )}
            </Tabs>
        </React.Fragment>
    )
}

export default ProjectionContainer
