import {ITEM} from '../actions/types';
import {AuthState} from '../Interfaces/AuthConfiginterface';

const initialState: AuthState = {
    accessToken: '',
    userName: '',
    role: [],
    isAuthenticated: false
};

export const authReducer = (state = initialState, action: any): AuthState => {
    if(action.type === ITEM.GET_JWT_TOKEN) {
        const {authState} = action.data;
        return {
            ...authState
        }
    } else if(action.type === ITEM.SET_AUTH_DATA) {
        const {authState} = action.data
        return {
            ...authState
        }
    } else {
        return state;
    }
};
