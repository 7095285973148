import { createActionTypes } from '../Utils/createActionType';

export const ITEM = createActionTypes('AUTHENTICATION', [
  'GET_JWT_TOKEN',
  'SET_ROLE',
  'SET_AUTH_DATA'
]);

export const applicationAndUserGroupList = createActionTypes('LIST', [
  'CALL_APPLICATION',
  'CALL_USER_APPLICATION',
  'CALL_USER_GROUP',
  'SET_APPLICATION',
  'SET_USER_GROUP',
  'POST_APPLICATION_LIST',
  'ASSIGN_APPLICATION',
  'SEARCH_USER_GROUP',
  'SEARCH_FILTER',
  'SET_APPLICATION_DASHBOARD'
]);

export const WorkItemList = createActionTypes('LIST', [
  'CALL_WORKITEM',
  'SET_WORKITEM',
  'DELETE_WORKITEM',
  'WORKITEM_PAGINATION'
]);

export const TemplateList = createActionTypes('LIST', [
  'CALL_TEMPLATE',
  'SET_TEMPLATE',
  'DELETE_TEMPLATE',
  'TEMPLATE_PAGINATION',
  'TEMPLATE_STATUS'
]);

export const Error = createActionTypes('Error', [
  'SET_ERROR_MESSAGE',
]);

export default ITEM
