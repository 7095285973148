import React, { Component } from 'react';
import Policy from '../../Components/Policy/Policy';

class Policies extends Component {
    render() {
        return (
            <Policy />
        )
    }
}

export default Policies;
