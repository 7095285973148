import React from 'react';
import styles from './Card.module.css';

interface Props {
    applicationIconUrl: string | undefined,
    applicationName: string | undefined,
    applicationUrl: string | undefined
}

const Card= (props: Props) => {
    const style  = props.applicationName === "Fundraising Dashboard" ? "#cccccc":"#ffffff";
    return (
            <section>
            <div className ={styles.linkCard} style={{backgroundColor: style}}>
                <a href = {props.applicationUrl} target="_blank">
                    <img id = {styles.dashboardCard} src = {props.applicationIconUrl}></img>
                    <span>
                    {props.applicationName}
                    </span>
                </a>
            </div>
            </section>
    )
  };
  
  export default Card;