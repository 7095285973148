import axios from '../Interceptor';
import { API_URL, AuthenticationConstants } from '../Constants';


const getJwtToken = async(accessTokenObject) => {
    const baseUrl = `${API_URL}/api/Login`;
    const {accessToken, isAuthenticated} = accessTokenObject;
    try {
        if(localStorage.getItem('userId') === null || !isAuthenticated) {
            const response = await axios.get(baseUrl, 
                { headers: {"Authorization" : `Bearer ${accessToken}`} });
            const data = response.data;
            if (response.status >= 400) {
                console.log(data);
            }
            return data;
        } else {
            return {};
        }
    } catch(e) {
        console.log(e.config);
    }
};

export {getJwtToken};
