import { createAction } from '../Utils/createActionType';
import { applicationAndUserGroupList } from './types'
import { Application } from '../Interfaces/ApplicationListInterface';
import { UserGroup } from '../Interfaces/UserGroupInterface';

export const callApplicationListApi = (userGroupId: number) => {
    return createAction(applicationAndUserGroupList.CALL_APPLICATION, {data: userGroupId});
};

export const callUserApplicationListApi = (userId) => {
    return createAction(applicationAndUserGroupList.CALL_USER_APPLICATION, {data: userId});
};

export const callUserGroupListApi = () => {
    return createAction(applicationAndUserGroupList.CALL_USER_GROUP, {});
};

export const setAssignUnassignApplication = (applicationId) => {
    return createAction(applicationAndUserGroupList.ASSIGN_APPLICATION, {data: applicationId});
};

export const setApplicationListApi = (applicationList: Application[]) => {
    return createAction(applicationAndUserGroupList.SET_APPLICATION, {data: applicationList});
};

export const setDasboardApplication = (applicationList: Application[]) => {
    return createAction(applicationAndUserGroupList.SET_APPLICATION_DASHBOARD, {data: applicationList});
};

export const setUserGroupListApi = (userGroupList: UserGroup[]) => {
    return createAction(applicationAndUserGroupList.SET_USER_GROUP, {data: userGroupList});
};

export const postApplicationList = (postData) => {
    return createAction(applicationAndUserGroupList.POST_APPLICATION_LIST, {data: postData});
};

export const searchUserGroupList = (searchValue: string) => {
    return createAction(applicationAndUserGroupList.SEARCH_USER_GROUP, {searchValue});
};

export const searchAndFilterApplicationList = (searchValue: string, filterValue: string) => {
    return createAction(applicationAndUserGroupList.SEARCH_FILTER, {searchValue, filterValue});
};
