import React from 'react';
import Styles from './PopUp.module.css';
import { useHistory } from 'react-router-dom';
import {setActiveRoute} from '../../actions/UIAction';
import { connect } from 'react-redux';
import {UIState} from '../../Interfaces/UIStateInterface';
import { authProvider } from '../../Services/Authentication/AuthProvider';

const PopUp = (props) => {
    const {setActiveRoute, setSettingOpen} = props;
    const history = useHistory();
    // navigate on click
    function navigate() {
        if(history.location.pathname !== '/assign') {
            history.push('/assign');
        }
        setActiveRoute(0);
        setSettingOpen(false);
    }
    // logout and clear storage on logout 
    const logoutHandler = () => {
        authProvider.logout();
        localStorage.clear();
    };
    const roleName = localStorage.getItem('adminRole');
    return (
    <React.Fragment>
    <div className={Styles.ToolTip} onClick = {() => navigate()}>
        {
            roleName ===  'Admin' ?  <React.Fragment><div className ={Styles.link}>
            <span className = "icons">
            <span className="material-icons">
                 apps
             </span> 
             </span>
            <span id = {Styles.assignApps}>
                Assign Apps
             </span>
         </div>
        <hr id = "hrLine"/>
        </React.Fragment>
         : 
         null
        }
        <div className ={Styles.link} onClick = {() => logoutHandler()}>
           <span className = "icons">
           <span className="material-icons">
           power_settings_new
            </span> 
            </span>
           <span id = {Styles.assignApps}>
               Logout
            </span>
        </div>
    </div>
    </React.Fragment>
)
}

const mapStateToProps = (UIState: UIState) => {
    return UIState;
  };

const mapDispatchToProps = (dispatch) => ({
    setActiveRoute: (data) => dispatch(setActiveRoute(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
