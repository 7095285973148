import React, { Component } from 'react'
import { Row, Col, Card, Form, Table, Modal } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import RichTextEditor from 'react-rte'
import ButtonComponent from 'Components/Button/Button'
import {
    API_URL,
    classWithId,
    deptNameWithId,
    supportTicketStatus,
} from '../../Services/Constants'
import MyStatefulEditor from '../../Components/Text-Editor/TextEditor'
import NoImg from '../../Assets/Images/no-img.png'
import './EditSupportTicket.css'
import { ButtonName } from '../../Constants'
import { Category, Priority } from 'Interfaces/CategoryInterface'
import { getInnerHtmlContent } from '../../Services/helper'

interface State {
    FormData: FormData
    fileName: any
    categories: Array<Category> | null
    priorities?: Array<Priority> | null
    error: boolean
    errorMessage: string
}

interface FormData {
    body: any
    subject: any
    supportTicketDepartmentId: number
    supportTicketCategoryId: number | null
    supportTicketPriorityId: number
    supportTicketClassificationId: number
    userId: number
    id: any
    guid: any
    sender: string
    attachments: any
    receivedAt: any
    supportTicketStatusId: any
    assingedTo: any
    comments: any
    postComment: any
    commentAttachments: any
    supportGroupData: any
    assigneeId: any
    supportGroupMemberGuid: any
    supportGroupDepartmentMemberId: any
    supportGroupDepartmentMemberName: any
}
export class EditSupportTicket extends Component<any, State> {
    file: any
    localeId = this.props.match.params.id
    id = parseInt(this.localeId!)
    guid = this.props.match.params.guid
    locale = localStorage.getItem('userId')
    userId = parseInt(this.locale!)
    attchementsArray: any
    ticketData: any
    commentsData: any
    supportGroupMemberGuid = localStorage.getItem('supportGroupMemberGuid')
    fileRef: any
    constructor(props) {
        super(props)
        this.fileRef = React.createRef()
        this.state = {
            FormData: {
                body: RichTextEditor.createEmptyValue(),
                subject: '',
                sender: '',
                supportTicketDepartmentId: 1,
                supportTicketCategoryId: 1,
                supportTicketPriorityId: 1,
                supportTicketClassificationId: 1,
                userId: this.userId,
                id: this.id,
                guid: this.guid,
                attachments: [],
                receivedAt: new Date(),
                supportTicketStatusId: 1,
                assingedTo: '',
                comments: [],
                postComment: RichTextEditor.createEmptyValue(),
                commentAttachments: '',
                supportGroupData: [],
                assigneeId: null,
                supportGroupMemberGuid: this.supportGroupMemberGuid,
                supportGroupDepartmentMemberId: '',
                supportGroupDepartmentMemberName: '',
            },
            fileName: '',
            categories: [...deptNameWithId[0]['categories']],
            priorities: [...deptNameWithId[0]['priorities']],
            error: false,
            errorMessage:
                'Oops! Something went wrong! Could not create the ticket!',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onSubmitComment = this.onSubmitComment.bind(this)
        this.uploadCommentAttachment = this.uploadCommentAttachment.bind(this)
        this.handleChangeSupportGroup = this.handleChangeSupportGroup.bind(this)
    }

    componentDidMount() {
        this.getSupportTicketById()
        this.getAttachment()
        this.getComments()
        this.getSupportTicketGroupData()
        console.log('TESTING: ', this.state.FormData)
        console.log('TICKET DATA', this.state)
    }
    async getSupportTicketById() {
        // const ticketGuid = this.props.match.params.guid
        // const ticketLocaleId = this.props.match.params.supportTicketId
        const ticketId = Number(this.props.match.params.id)
        const baseUrl = await `${API_URL}/supports/${ticketId}/${this.guid}`
        let respData: any
        axios.get(baseUrl).then((response) => {
            respData = response.data
            console.log('EDIT SUPPORT TICKET RESPONSE DATA', respData)
            this.setState({
                FormData: {
                    ...this.state.FormData,
                    body: RichTextEditor.createValueFromString(
                        respData.body,
                        'html'
                    ),
                    sender: respData.sender,
                    subject: respData.subject,
                    supportTicketDepartmentId:
                        respData.supportTicketDepartmentId,
                    supportTicketCategoryId: respData.supportTicketCategoryId,
                    supportTicketPriorityId: respData.supportTicketPriorityId,
                    supportTicketClassificationId:
                        respData.supportTicketClassificationId,
                    receivedAt: respData.receivedAt,
                    supportTicketStatusId: respData.supportTicketStatusId,
                    assingedTo: respData.assignedTo,
                    assigneeId: respData.assigneeId,
                    supportGroupDepartmentMemberId:
                        respData.supportGroupDepartmentMemberId,
                    supportGroupDepartmentMemberName:
                        respData.supportGroupDepartmentMemberName,
                },
            })
            this.handleDepartmentChange(
                respData.supportTicketDepartmentId,
                deptNameWithId
            )
            this.ticketData = this.state
            const elmReceivedAt = document.getElementById(
                'support-ticket-received-at'
            ) as HTMLElement
            if (elmReceivedAt) {
                elmReceivedAt.innerHTML = moment(this.ticketData.receivedAt)
                    .add(5, 'hours')
                    .add(30, 'minutes')
                    .format('MMM DD, YYYY hh:mm a')
            }

            const elmStatusColor = document.getElementById(
                'status-color'
            ) as HTMLElement
            if (+this.ticketData.supportTicketStatusId === 1) {
                elmStatusColor.style.backgroundColor = 'rgb(50, 205, 140)'
            } else if (+this.ticketData.supportTicketStatusId === 2) {
                elmStatusColor.style.backgroundColor = 'rgb(163,88,232)'
            } else if (+this.ticketData.supportTicketStatusId === 3) {
                elmStatusColor.style.backgroundColor = 'rgb(207,20,43)'
            }
        })
    }

    async getAttachment() {
        const ticketGuid = this.props.match.params.guid
        const ticketId = Number(this.props.match.params.id)
        let array: any = []
        const baseUrl = await `${API_URL}/support-attachments/supports/${ticketId}/${ticketGuid}`
        axios
            .get(baseUrl)
            .then((resp) => {
                if (resp.status !== 200) {
                    this.setState({
                        error: true,
                    })
                    return
                }
                const respFilter = resp.data
                respFilter.data.forEach((item: any) => {
                    array.push(item)
                })
                this.setState({
                    FormData: { ...this.state.FormData, attachments: array },
                })
            })
            .catch((error) => {
                this.setState({ error: true })
            })
    }

    async getComments() {
        const ticketGuid = this.props.match.params.guid
        const ticketId = Number(this.props.match.params.id)
        const baseUrl = await `${API_URL}/support-responses/supports/${ticketId}/${ticketGuid}`
        axios
            .get(baseUrl)
            .then((resp) => {
                this.setState({
                    FormData: {
                        ...this.state.FormData,
                        comments: resp.data.data,
                    },
                })
                this.commentsData = this.state.FormData.comments
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleInputChange(event) {
        event.preventDefault()
        this.setState({
            FormData: {
                ...this.state.FormData,
                [event.target.name]: event.target.value,
            },
        })
    }

    handleChange(event, array) {
        if (array === null || array === undefined) return
        event.preventDefault()
        let store = array.filter((data: any) => {
            if (+data[Object.keys(data)[0]] === +event.target.value) {
                return data
            } else {
                return false
            }
        })
        const localeValue = parseInt(event.target.value)
        let key = Object.keys(store[0])[0]
        this.setState({
            FormData: { ...this.state.FormData, [key]: localeValue },
        })
    }

    handleDepartmentChange(departmentId, array) {
        if (isFinite(departmentId)) {
            if (+departmentId === 4) {
                this.setState({
                    FormData: {
                        ...this.state.FormData,
                        supportTicketDepartmentId: 4,
                        supportTicketCategoryId: null,
                    },
                    categories: null,
                    priorities: deptNameWithId[3]['priorities'],
                })
            } else {
                this.setState({
                    FormData: {
                        ...this.state.FormData,
                        supportTicketDepartmentId: +departmentId,
                    },
                    categories: [...array[+departmentId - 1]['categories']],
                    priorities: [...array[+departmentId - 1]['priorities']],
                })
            }
        }
    }

    handleChangeSupportGroup(event, array) {
        const localeAssigneeid = parseInt(event.target.value)
        this.setState({
            FormData: {
                ...this.state.FormData,
                assigneeId: localeAssigneeid,
            },
        })
    }

    submitEditFormData() {
        const bodyVal = this.state.FormData.body.toString('html')
        const dataBody = {
            body: bodyVal,
            guid: this.state.FormData.guid,
            id: this.state.FormData.id,
            subject: this.state.FormData.subject,
            supportTicketDepartmentId: this.state.FormData
                .supportTicketDepartmentId,
            supportTicketCategoryId: this.state.FormData
                .supportTicketCategoryId,
            supportTicketPriorityId: this.state.FormData
                .supportTicketPriorityId,
            supportTicketClassificationId: this.state.FormData
                .supportTicketClassificationId,
            supportTicketStatusId: this.state.FormData.supportTicketStatusId,
            userId: this.state.FormData.userId,
            assigneeId: this.state.FormData.assigneeId,
            supportGroupMemberGuid: this.state.FormData.supportGroupMemberGuid,
        }
        const baseUrl = `${API_URL}/supports`
        axios
            .put(baseUrl, dataBody)
            .then((resp) => {
                if (resp.status !== 200) {
                    alert('Could not Update')
                    return
                }
                this.props.history.push('/supportTicket')
            })
            .catch((error) => {
                console.log(error)
                // alert('Error:  Could not Update')
                this.setState({ error: true })
            })
    }

    onSubmitComment() {
        const ticketId: any = parseInt(this.props.match.params.id)
        const userId: any = Number(this.locale)
        const formData = new FormData()
        const commentVal = this.state.FormData.postComment.toString('html')
        formData.append('Description', commentVal)
        formData.append('UserId', userId)
        formData.append('SupportTicketId', ticketId)
        formData.append(
            'commentAttachments',
            this.state.FormData.commentAttachments
        )

        const baseUrl = `${API_URL}/support-responses`
        axios
            .post(baseUrl, formData)
            .then((resp) => {
                this.getComments()
                this.getAttachment()
                this.setState({
                    FormData: {
                        ...this.state.FormData,
                        postComment: RichTextEditor.createEmptyValue(),
                        commentAttachments: '',
                    },
                    fileName: '',
                })
                this.fileRef.current.value = null
            })
            .catch((error) => {
                console.log(error)
                alert('Error: Could not create')
            })
    }

    async onChangeComment(curRef, description: any, value) {
        curRef.setState({
            FormData: { ...curRef.state.FormData, postComment: value },
        })
    }

    uploadCommentAttachment(e) {
        e.target.files &&
            this.setState({
                FormData: {
                    ...this.state.FormData,
                    [e.target.name]: e.target.files[0],
                },
                fileName: e.target.files[0].name,
            })
    }

    handleRemoveFile = () => {
        this.fileRef.current.value = null
        this.setState({
            FormData: {
                ...this.state.FormData,
                commentAttachments: '',
            },
            fileName: '',
        })
    }

    async getSupportTicketGroupData() {
        // const supportGroupMemberGuid = localStorage.getItem(
        //     'supportGroupMemberGuid'
        // )
        // const supportGroupDepartmentId = localStorage.getItem(
        //     'supportGroupDepartmentId'
        // )
        // const supportGroupDepartmentName = localStorage.getItem(
        //     'supportGroupDepartmentName'
        // )
        const baseUrl = await `${API_URL}/support-group`
        axios
            .get(baseUrl)
            .then((resp) => {
                this.setState({
                    FormData: {
                        ...this.state.FormData,
                        supportGroupData: resp.data,
                    },
                })
            })
            .catch((error) => {
                console.log(error)
                alert('Error:  Could not Update')
            })
    }

    onClickCancel() {
        this.componentDidMount()
        this.props.history.push('/supportTicket')
    }

    fileUpload = () => {
        this.fileRef.current.click()
    }

    render() {
        return (
            <Form>
                <Modal
                    style={{
                        zIndex: 50000,
                        marginTop: '35vh',
                    }}
                    show={this.state.error}
                    onHide={() => {
                        this.setState({ error: false })
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessage}</Modal.Body>
                </Modal>
                <Row className="mt-3 mb-3">
                    <Col md={9}>
                        <h5>Edit Support Ticket</h5>
                    </Col>
                    <Col
                        md={3}
                        className="form-action-button-container ml-auto"
                    >
                        <div className="save-button">
                            <ButtonComponent
                                name={ButtonName.SAVE}
                                style={{
                                    width: '80px',
                                }}
                                onClick={() => this.submitEditFormData()}
                            />
                        </div>
                        <div>
                            <ButtonComponent
                                name={ButtonName.CANCEL}
                                style={{
                                    width: '80px',
                                }}
                                onClick={() => this.onClickCancel()}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={{ span: 9 }}>
                        <Card className="shadow p-3 mb-5 bg-white rounded">
                            <Card.Body className="card-body">
                                <div className="card-top-container">
                                    <div className="card-top-container-left">
                                        <div>
                                            <p id="support-ticket-received-at"></p>
                                        </div>
                                    </div>
                                    <div className="card-top-container-right">
                                        <div id="support-ticket-status-container-right">
                                            <p id="status-color"></p>
                                            {(localStorage.getItem(
                                                'supportGroupDepartmentId'
                                            ) === '' &&
                                                localStorage.getItem(
                                                    'supportGroupMemberGuid'
                                                ) === '') ||
                                            this.props.match.params
                                                .currentTab === '1' ? (
                                                <Form.Control
                                                    className="status-select-dropdown mb-4 disabled-select"
                                                    as="select"
                                                    onChange={(event) =>
                                                        this.handleChange(
                                                            event,
                                                            supportTicketStatus
                                                        )
                                                    }
                                                    value={
                                                        this.state.FormData
                                                            .supportTicketStatusId
                                                    }
                                                    disabled
                                                >
                                                    {supportTicketStatus.map(
                                                        (item: any, i) => (
                                                            <option
                                                                key={i}
                                                                value={
                                                                    item.supportTicketStatusId
                                                                }
                                                            >
                                                                {
                                                                    item.displayName
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            ) : (
                                                <Form.Control
                                                    className="status-select-dropdown mb-4"
                                                    as="select"
                                                    onChange={(event) =>
                                                        this.handleChange(
                                                            event,
                                                            supportTicketStatus
                                                        )
                                                    }
                                                    value={
                                                        this.state.FormData
                                                            .supportTicketStatusId
                                                    }
                                                >
                                                    {supportTicketStatus.map(
                                                        (item: any, i) => (
                                                            <option
                                                                key={i}
                                                                value={
                                                                    item.supportTicketStatusId
                                                                }
                                                            >
                                                                {
                                                                    item.displayName
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                            )}
                                        </div>

                                        {/* </div> */}
                                    </div>
                                </div>
                                <Row className="pl-3 mt-2">
                                    <label className="form-control-label">
                                        Department *
                                    </label>
                                    <Form.Control
                                        className="set-input-width  mb-4"
                                        as="select"
                                        onChange={(event) =>
                                            this.handleDepartmentChange(
                                                event.target.value,
                                                deptNameWithId
                                            )
                                        }
                                        value={
                                            this.state.FormData
                                                .supportTicketDepartmentId
                                        }
                                    >
                                        {deptNameWithId.map((item: any, i) => (
                                            <option
                                                key={i}
                                                value={
                                                    item.supportTicketDepartmentId
                                                }
                                            >
                                                {item.departmentName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {this.state.categories && (
                                        <>
                                            <label className="form-control-label">
                                                Category *
                                            </label>
                                            <Form.Control
                                                className="set-input-width  mb-4"
                                                as="select"
                                                onChange={(event) =>
                                                    this.handleChange(
                                                        event,
                                                        this.state.categories
                                                    )
                                                }
                                                value={
                                                    this.state.FormData
                                                        .supportTicketCategoryId
                                                        ? this.state.FormData
                                                              .supportTicketCategoryId
                                                        : 1
                                                }
                                            >
                                                {this.state.categories?.map(
                                                    (item: Category, i) => (
                                                        <option
                                                            key={i}
                                                            value={
                                                                item.supportTicketCategoryId
                                                            }
                                                        >
                                                            {item.categoryName}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                        </>
                                    )}

                                    <label className="form-control-label">
                                        Subject *
                                    </label>
                                    <Form.Control
                                        className="set-input-width  mb-4"
                                        name="Subject"
                                        value={this.state.FormData.subject}
                                        placeholder=""
                                        onChange={this.handleInputChange}
                                        disabled
                                    />

                                    <label className="form-control-label mt-2">
                                        Description *
                                    </label>
                                    <div id="text-editor-container">
                                        <MyStatefulEditor
                                            id="rich-text-editor"
                                            curRef={this}
                                            name="body"
                                            value={this.state.FormData.body}
                                            disabled={true}
                                        />
                                    </div>
                                    <label className="form-control-label mt-4">
                                        Classifications *
                                    </label>
                                    <Form.Control
                                        className="set-input-width  mb-4"
                                        as="select"
                                        onChange={(event) =>
                                            this.handleChange(
                                                event,
                                                classWithId
                                            )
                                        }
                                        value={
                                            this.state.FormData
                                                .supportTicketClassificationId
                                        }
                                    >
                                        {classWithId.map((item: any, i) => (
                                            <option
                                                key={i}
                                                value={
                                                    item.supportTicketClassificationId
                                                }
                                            >
                                                {item.classificationName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <label className="form-control-label">
                                        Priority *
                                    </label>
                                    <Form.Control
                                        className="set-input-width  mb-4"
                                        as="select"
                                        onChange={(event) =>
                                            this.handleChange(
                                                event,
                                                this.state.priorities
                                            )
                                        }
                                        value={
                                            this.state.FormData
                                                .supportTicketPriorityId
                                        }
                                    >
                                        {this.state.priorities?.map(
                                            (item: Priority, i) => (
                                                <option
                                                    key={i}
                                                    value={
                                                        item.supportTicketPriorityId
                                                    }
                                                >
                                                    {item.priorityName}
                                                </option>
                                            )
                                        )}
                                    </Form.Control>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <h5 className="assign-title-center">
                                    Assignee
                                </h5>
                                <div className="assign-title-center mb-3">
                                    <img
                                        id="avatar"
                                        alt="avatar"
                                        src={NoImg}
                                    ></img>
                                </div>
                                {(localStorage.getItem(
                                    'supportGroupDepartmentId'
                                ) === '' &&
                                    localStorage.getItem(
                                        'supportGroupMemberGuid'
                                    ) === '') ||
                                this.props.match.params.currentTab === '1' ? (
                                    <Form.Control
                                        className="set-input-width  mb-4 disabled-select"
                                        as="select"
                                        onChange={(event) =>
                                            this.handleChangeSupportGroup(
                                                event,
                                                this.state.FormData
                                                    .supportGroupData
                                            )
                                        }
                                        value={
                                            this.state.FormData.assigneeId ||
                                            this.state.FormData
                                                .supportGroupDepartmentMemberId
                                        }
                                        disabled
                                    >
                                        {this.state.FormData.supportGroupData &&
                                            this.state.FormData.supportGroupData.map(
                                                (item: any, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.id}
                                                    >
                                                        {item.userName}
                                                    </option>
                                                )
                                            )}
                                    </Form.Control>
                                ) : (
                                    <Form.Control
                                        className="set-input-width  mb-4"
                                        as="select"
                                        onChange={(event) =>
                                            this.handleChangeSupportGroup(
                                                event,
                                                this.state.FormData
                                                    .supportGroupData
                                            )
                                        }
                                        value={
                                            this.state.FormData.assigneeId ||
                                            this.state.FormData
                                                .supportGroupDepartmentMemberId
                                        }
                                    >
                                        {this.state.FormData.supportGroupData &&
                                            this.state.FormData.supportGroupData.map(
                                                (item: any, i) => (
                                                    <option
                                                        key={i}
                                                        value={item.id}
                                                    >
                                                        {item.userName}
                                                    </option>
                                                )
                                            )}
                                    </Form.Control>
                                )}
                            </Card.Body>
                        </Card>
                        <Card className="created-by-top-margin">
                            <Card.Body>
                                <h5 className="assign-title-center">
                                    Created by
                                </h5>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: 'rgb(108, 111, 112)',
                                    }}
                                >
                                    {this.state.FormData.sender}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Card className="shadow p-3 mb-5 bg-white rounded">
                            <Card.Body>
                                <div className="attachment-container">
                                    <div className="attachment-container-left">
                                        Attachments
                                    </div>
                                    <div className="attachment-container-right">
                                        {this.state.FormData.attachments.length}
                                    </div>
                                </div>
                                <Table className="mt-4">
                                    <tbody>
                                        {this.state.FormData.attachments &&
                                            this.state.FormData.attachments.map(
                                                (data: any, i) => (
                                                    <tr
                                                        key={data.id}
                                                        id="attachment-data-row"
                                                    >
                                                        <td className="attachment-name-container">
                                                            <a href={data.url}>
                                                                {data.name}
                                                            </a>
                                                        </td>
                                                        <td className="attachment-type-container">
                                                            {data.type}
                                                        </td>
                                                        <td>
                                                            {moment(
                                                                data.uploadedAt
                                                            )
                                                                .add(5, 'hours')
                                                                .add(
                                                                    30,
                                                                    'minutes'
                                                                )
                                                                .format(
                                                                    'MMM DD, YYYY hh:mm a'
                                                                )}
                                                        </td>
                                                        <td className="attachment-date">
                                                            {data.userName}
                                                        </td>
                                                        <td className="attachment-action">
                                                            <a href={data.url}>
                                                                <span className="material-icons">
                                                                    get_app
                                                                </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={9}>
                        <Card className="shadow p-3 mb-5 bg-white rounded">
                            <div className="comments-heading-container">
                                Comments
                            </div>
                            <Card.Body>
                                {this.state.FormData.comments &&
                                    this.state.FormData.comments.map(
                                        (item: any, i) => (
                                            <Row key={i}>
                                                <Col
                                                    md={8}
                                                    className={
                                                        +this.userId ===
                                                        +item.userId
                                                            ? 'ml-auto mr-1'
                                                            : ''
                                                    }
                                                >
                                                    <Card className="other-comments-card mt-2 mb-2">
                                                        <Card.Body>
                                                            <div className="comments-top-container">
                                                                <div className="comments-top-container-left">
                                                                    <div className="comments-top-container-left-first-letter">
                                                                        {item.userName
                                                                            .slice(
                                                                                0,
                                                                                1
                                                                            )
                                                                            .toUpperCase()}
                                                                    </div>
                                                                    <div className="comments-top-container-left-full-name">
                                                                        {
                                                                            item.userName
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="comments-top-container-right">
                                                                    <span>
                                                                        {moment(
                                                                            item.createdAt
                                                                        )
                                                                            .add(
                                                                                5,
                                                                                'hours'
                                                                            )
                                                                            .add(
                                                                                30,
                                                                                'minutes'
                                                                            )
                                                                            .format(
                                                                                'MMM DD, YYYY hh:mm a'
                                                                            )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="comments-second-container">
                                                                <p
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            item.description,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                            <span className="text-right card-text">
                                                                {item.attachments.map(
                                                                    (data) => (
                                                                        <a
                                                                            key={
                                                                                data.id
                                                                            }
                                                                            className="text-muted"
                                                                            href={
                                                                                data.url
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                        </a>
                                                                    )
                                                                )}
                                                            </span>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                    )}
                            </Card.Body>
                            <Card.Body>
                                Post Comments
                                <MyStatefulEditor
                                    onChange={this.onChangeComment}
                                    curRef={this}
                                    name="postComment"
                                    value={this.state.FormData.postComment}
                                />
                                <form
                                    className="form-inline"
                                    encType="multipart/form-data"
                                >
                                    <input
                                        className="mt-2 file-upload"
                                        type="file"
                                        name="commentAttachments"
                                        ref={this.fileRef}
                                        onChange={(event) =>
                                            this.uploadCommentAttachment(event)
                                        }
                                    />
                                </form>
                                <Row>
                                    <Col md={6} className="mt-4 mr-1">
                                        <span>
                                            <ButtonComponent
                                                name={ButtonName.CHOOSE_FILE}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.fileUpload()
                                                }}
                                            />
                                            {'  '}
                                            {this.state.fileName}
                                        </span>
                                    </Col>
                                    {this.state.FormData.commentAttachments && (
                                        <Col md={1} className="mt-4 mr-1">
                                            <div
                                                className="clear-icon"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.handleRemoveFile()
                                                }}
                                            >
                                                <span className="material-icons">
                                                    clear
                                                </span>
                                            </div>
                                        </Col>
                                    )}

                                    <Col md={4} className="mt-4 ml-auto mr-1">
                                        <ButtonComponent
                                            name={ButtonName.SUBMIT}
                                            disabled={
                                                getInnerHtmlContent(
                                                    this.state.FormData
                                                        .postComment._cache.html
                                                ).length === 0
                                            }
                                            style={{
                                                width: '100%',
                                                fontSize: '14px',
                                            }}
                                            onClick={this.onSubmitComment}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default EditSupportTicket
