import ButtonComponent from 'Components/Button/Button'
import { AppConstant, ButtonName, Routes } from 'Constants'
import React, { useEffect, useState } from 'react'
import {
    Col,
    Dropdown,
    DropdownButton,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
    Table,
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { getDepartmentwiseEmployeeCount } from 'Services/AllocationService/AllocationService'
import moment from 'moment'
import {
    getProjectReportListCsv,
    getProjectViewReports,
    getProjectViewReportsFromAllocation,
} from 'Services/ReportService/ReportService'
import { Typeahead } from 'react-bootstrap-typeahead'
import { getProjectsFromKickOffBilling } from 'Services/ResourceService/ResourceService'
import download from 'downloadjs'
interface State {
    isLocked?: boolean
    reportDropDownItems?: any
}
function ProjectReports(state: State) {
    state = {
        isLocked: false,
        reportDropDownItems: {
            year: {
                id: 0,
                name: 'Year',
            },
            month: {
                id: 0,
                name: 'Month',
            },
        },
    }
    const [reportsData, setreportsData]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredResults, setFilteredResults] = useState([])
    const [uiLoader, setUiLoader] = useState(true)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date())
    const [projects, setProjects] = useState([])
    const roleName = localStorage.getItem('adminRole')
    const [projectSelected, setProjectSelected]: any = useState()
    let monthPairs = [
        { id: '01', name: 'Jan - Feb', firstMonth: 'Jan', secondmonth: 'Feb' },
        { id: '02', name: 'Feb - Mar', firstMonth: 'Feb', secondmonth: 'Mar' },
        { id: '03', name: 'Mar - Apr', firstMonth: 'Mar', secondmonth: 'Apr' },
        { id: '04', name: 'Apr - May', firstMonth: 'Apr', secondmonth: 'May' },
        { id: '05', name: 'May - Jun', firstMonth: 'May', secondmonth: 'Jun' },
        { id: '06', name: 'Jun - Jul', firstMonth: 'Jun', secondmonth: 'Jul' },
        { id: '07', name: 'Jul - Aug', firstMonth: 'Jul', secondmonth: 'Aug' },
        { id: '08', name: 'Aug - Sep', firstMonth: 'Aug', secondmonth: 'Sep' },
        { id: '09', name: 'Sep - Oct', firstMonth: 'Sep', secondmonth: 'Oct' },
        { id: '10', name: 'Oct - Nov', firstMonth: 'Oct', secondmonth: 'Nov' },
        { id: '11', name: 'Nov - Dec', firstMonth: 'Nov', secondmonth: 'Dec' },
        { id: '12', name: 'Dec - Jan', firstMonth: 'Dec', secondmonth: 'Jan' },
    ]
    const [years, setYears]: any = useState([])
    const [reportState, setReportState] = useState(state)
    let projectId = ''
    let formattedDate = ''
    useEffect(() => {
        getProjectsFromKickOffBilling()
            .then((response: any) => {
                setProjects(response.data)
            })
            .catch((error) => console.log('error'))

        let options: any = []
        let prevYear = 2000
        for (let i = 0; i <= 40; i++) {
            options.push((prevYear = prevYear + 1))
        }
        setYears([...years, ...options])
        let currentMonth = new Date().getMonth() + 1
        let monthResult = monthPairs.find((months) => {
            return months.id == currentMonth + ''
        })
        const dropDownValue = {
            ...reportState,
            reportDropDownItems: {
                ...reportState.reportDropDownItems,
                month: {
                    id: monthResult?.id,
                    name: monthResult?.firstMonth,
                },
                year: {
                    id: new Date().getFullYear(),
                    name: new Date().getFullYear(),
                },
            },
        }
        setReportState(dropDownValue)
    }, [])
    useEffect(() => {
        let formattedDate = ''

        formattedDate = reportState.reportDropDownItems.year.id
            ? reportState.reportDropDownItems.year.id +
              '-' +
              reportState.reportDropDownItems.month.id +
              '-01'
            : moment(new Date()).format('YYYY-MM-DD')
        setUiLoader(true)

        if (projectSelected && projectSelected.length) {
            projectId = projectSelected[0].id
        }
        getProjectViewReports(formattedDate, projectId)
            .then((response: any) => {
                setreportsData(response.data)
                setUiLoader(false)
            })
            .catch((error) => console.log('error'))
    }, [
        reportState.reportDropDownItems.year.id,
        reportState.reportDropDownItems.month.id,
        projectSelected,
    ])
    const exportReport = () => {
        if (projectSelected && projectSelected.length) {
            projectId = projectSelected[0].id
        }

        formattedDate = reportState.reportDropDownItems.year.id
            ? reportState.reportDropDownItems.year.id +
              '-' +
              reportState.reportDropDownItems.month.id +
              '-01'
            : moment(new Date()).format('YYYY-MM-DD')
        getProjectReportListCsv(formattedDate, projectId)
            .then((response: any) => response.data)
            .then((data) =>
                download(
                    data,
                    `${reportState.reportDropDownItems.month.name}_Project_Report.csv`,
                    'text/csv'
                )
            )
            .catch((error) => console.log('Errorrr'))
    }
    const setDropDownValue = (type: string, value: any) => {
        if (type === 'year') {
            const dropDownValue = {
                ...reportState,
                reportDropDownItems: {
                    ...reportState.reportDropDownItems,
                    year: {
                        id: value,
                        name: value,
                    },
                },
            }
            setReportState(dropDownValue)
        } else if (type === 'month') {
            const dropDownValue = {
                ...reportState,
                reportDropDownItems: {
                    ...reportState.reportDropDownItems,
                    month: {
                        id: value.id,
                        name: value.firstMonth,
                    },
                },
            }
            setReportState(dropDownValue)
        }
    }
    const renderResources = (reportData) => {
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <span>{reportData.projectName || '-'}</span>
                    </td>
                    <td>
                        <span>{reportData.projectId || '0'}</span>
                    </td>

                    <td>{reportData.projectedHours || '0'}</td>
                    <td>{reportData.budgetedHours || '0'}</td>
                    <td>{reportData.budgetedResources || '0'}</td>
                    <td>{reportData.projectLoggedHours || '0'}</td>
                    <td>{reportData.projectBilledHours || '0'}</td>
                    <td>{reportData.projectWastage || '0'}</td>
                </tr>
            </React.Fragment>
        )
    }

    return uiLoader || roleName != 'Admin' ? (
        <div className="loader-list">Loading....</div>
    ) : (
        <div>
            <React.Fragment>
                <div className="table-allocation">
                    <div className="right-holder-report">
                        <Nav.Item id="button-nav-project">
                            <Row>
                                <Col>
                                    {
                                        <Typeahead
                                            id="basic-example"
                                            labelKey="name"
                                            options={projects}
                                            placeholder="Choose a Project..."
                                            selected={projectSelected}
                                            onChange={setProjectSelected}
                                            style={{ width: '300px' }}
                                        />
                                    }
                                </Col>
                                <Col>
                                    {
                                        <DropdownButton
                                            className={`align-dropdown 
                                   assign-title-center dropdown-font`}
                                            key={'secondary'}
                                            id={`dropdown-variants-secondary`}
                                            variant={'secondary'}
                                            title={
                                                reportState.reportDropDownItems
                                                    .month.name
                                            }
                                        >
                                            {monthPairs.map(
                                                (data: any, index) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={index}
                                                            eventKey={`${
                                                                index + 1
                                                            }`}
                                                            onClick={() =>
                                                                setDropDownValue(
                                                                    'month',
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            {data.firstMonth}
                                                        </Dropdown.Item>
                                                    )
                                                }
                                            )}
                                        </DropdownButton>
                                    }
                                </Col>
                                <Col>
                                    <DropdownButton
                                        className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                        key={'secondary'}
                                        id={`dropdown-variants-secondary`}
                                        variant={'secondary'}
                                        title={
                                            reportState.reportDropDownItems.year
                                                .name
                                        }
                                    >
                                        {years.map((data, index) => {
                                            return (
                                                <Dropdown.Item
                                                    key={index}
                                                    eventKey={`${index + 1}`}
                                                    onClick={() =>
                                                        setDropDownValue(
                                                            'year',
                                                            data
                                                        )
                                                    }
                                                >
                                                    {data}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton>
                                </Col>
                                <Col className="export-btn">
                                    {
                                        <ButtonComponent
                                            style={{
                                                fontSize: '14px',

                                                padding: '30px',
                                            }}
                                            name={'Export'}
                                            onClick={() => exportReport()}
                                        />
                                    }
                                </Col>
                            </Row>
                        </Nav.Item>
                    </div>
                    {!reportsData || !reportsData.length ? (
                        <div className="loader-list">No Data Found</div>
                    ) : (
                        <Table responsive hover size="sm">
                            <thead>
                                <tr id="tableHeading">
                                    <th style={{ width: '20rem' }}>
                                        Project Name
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Project ID
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Projected Hours
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Budgeted Hours
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Budgeted Resources
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Project logged hours
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Project Billed Hours
                                    </th>
                                    <th style={{ width: '20rem' }}>
                                        Project Wastage
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tbody">
                                {reportsData.map((report, index) => {
                                    return renderResources(report)
                                })}
                            </tbody>
                        </Table>
                    )}
                </div>
            </React.Fragment>
        </div>
    )
}

export default ProjectReports
