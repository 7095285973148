import React from 'react'
import axios from 'axios'
import { API_URL } from 'Services/Constants'
//service
const paramApi = 'CharudattaShinde'
const paramOrg = 'Optimus-Projects'

const createAllocation = async (postFormData) => {
    try {
        const baseUrl = `${API_URL}/api/allocation`
        const config = {
            headers: { 'content-type': 'application/json' },
        }
        return await axios.post(baseUrl, postFormData, config)
    } catch (e) {
        console.log(e.config)
    }
}

const getDepartmentwiseEmployeeCount = async () => {
    try {
        const baseUrl = `${API_URL}/api/allocation/Report/DepartmentwiseEmployeeCount`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getSkillWiseEmployeeCount = async () => {
    try {
        const baseUrl = `${API_URL}/api/ZohoAccount/GetEmployeeSkills`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getAllocationResourceDetails = async (month, year, projectId) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/AllocationResourceDetails?month=${month}&year=${year}&projectId=${projectId}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getAllocationResources = async (month, year) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/AllocationResources?month=${month}&year=${year}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

const getAllocationResourcesAvailabilityHours = async (month, year, email) => {
    try {
        const baseUrl = `${API_URL}/api/allocation/AllocationResourceAvailability?month=${month}&year=${year}&email=${email}`
        return await axios.get(baseUrl)
    } catch (e) {
        console.log(e.config)
    }
}

export {
    getDepartmentwiseEmployeeCount,
    getAllocationResourceDetails,
    getAllocationResources,
    getAllocationResourcesAvailabilityHours,
    createAllocation,
    getSkillWiseEmployeeCount
}
