import React, { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import { connect } from 'react-redux';
import { setErrorMessage } from '../../actions/ErrorsAction';
import { asyncActionModal, setModalState, setUILoaderState } from '../../actions/UIAction';
import { deleteWorkItem } from '../../actions/WorkItemActions';
import { Routes } from '../../Constants';
import { ErrorMessage } from '../../Interfaces/ErrorMessageInterface';
import { WorkItem } from '../../Interfaces/WorkItemInterface';
import { delteWatcherById, notifyUser } from '../../Services/WorkItemAndTaskService/WorkItemList';
import './TaskList.css';

interface Props { };

export function TaskList(props) {
  const { setWorkItemList, taskId, taskList } = props;

  useEffect( () => {
    props.setUILoaderState( false );
  } , [ taskList.length ])

  function renderSubMenu(parent, children) {
    if (children && children.length > 0) {
      return (
        <React.Fragment>
          {parent.isOpen ? renderMenuItems(children, parent) : null}
          {/* {props.activeRowSubTask && parent && parent.isOpen ? <div style = {{'paddingLeft': '30%'}}>
            Hello2</div> : null} */}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {parent.showAddItemInput ? <Row className="mt-1 mb-1" style={{
          'paddingLeft': parent.workItemTypeId == 3 ? '20%' : '30%'
        }}>
          <div className="add-workItem-button"
            onClick={() => props.saveWorkItemParentIdGuId(parent.id, parent.guid, parent.workItemTypeId)}>
            <Form onSubmit={(event) => props.addQuickWorkItem(event, false)}>
              <div className='input-holder'>
                <input
                  id="workitem-input-task"
                  onChange={(event) => props.setWorkItemName(event.target.value)}
                  placeholder={`${parent.workItemTypeId == 3 ? 'Add Task' : 'Add Sub Task'}`} />
                <button type="submit" className="span-item">
                  <i className="material-icons icon-style"> arrow_right
                          </i>
                </button>
              </div>
            </Form>
          </div>
        </Row> : null
        }
      </React.Fragment>
    )
  };

  const renderOnTreeStateChange = (event, item, taskItems) => {
    event.stopPropagation();
    const newTaskList = taskItems.map((task, index) => {
      if (JSON.stringify(task) === JSON.stringify(item)) {
        task.isOpen = !task.isOpen;
        return task;
      } else {
        renderOnTreeStateChange(event, item, task.childWorkItems);
        return task;
      }
    });
    setWorkItemList(newTaskList);
  }

  const deleteWorkItem = (id: number, guId: string) => {
    props.setErrorMessage(
      {
        errorMessage: " ",
        errorMessageHeader: "Are you sure, you want to delete?"
      }
    )
    props.setModalState(true);
    props.asyncActionModal(() => {
      props.deleteWorkItem(id, guId, taskId, props.filterParams);
      props.setModalState(false)
    });
  }

  const editItem = ({ depth, id, guid, name, parentWorkItemId, parentWorkItemGuid }) => {
    const depthValue = (depth == 0 ? '3' : depth == 1 ? '2' : '1');
    props.history.push(`${Routes.ADD_NEW_WORK_Item}/true/${depthValue}/${id}/${guid}/${parentWorkItemId}/${parentWorkItemGuid}/false/${name}`)
  }

  const addChildItem = ({ depth, id, guid, name }) => {
    const depthValue = (depth == 0 ? '2' : '1');
    props.history.push(`${Routes.ADD_NEW_WORK_Item}/false/${depthValue}/null/null/${id}/${guid}/false/${name}`)
  }

  const notifyUserByEmail = ({ id, userId }) => {
    props.setUILoaderState(true);
    const notifyUserData = Object.assign({}, {
      userId: userId,
      workItemId: id
    })
    notifyUser(notifyUserData)
      .then((response: any) => {
        props.setUILoaderState(false);
        if (response['response'] && response['response']['status'] >= 400) {
          props.setErrorMessage(
            {
              errorMessage: response['response'] && response['response']
                && response['response']['data'] ? response['response']['data']['errorCause']
                : "Something Went Wrong",
              errorMessageHeader: "Error Message"
            }
          )
          props.setModalState(true);
          props.asyncActionModal(() => props.setModalState(false));
        }
      })
  }

  const handleIconClick = (event, item, items) => {
    props.setWorkItem(item, items)
    renderOnTreeStateChange(event, item, props.taskList)
  }

  const renderItems = (item, index, parent?: any, items?: any) => {

    return <React.Fragment key={index}>
      <tr className={`${item.showAddItemInput && item.isOpen ? 'table-row' : null}`}
        onClick={() => props.setWorkItem(item, items)}>
        <td id="onHover-row" style={{ 'paddingLeft': `${item.depth * 3}%` }}>
          <div className="first-column" onClick={() => {
            editItem(item)
          }}>
            {
              item.isOpen ? <span onClick={(event) => handleIconClick(event, item, items)} className="material-icons">
                keyboard_arrow_up
      </span> : <span onClick={(event) => handleIconClick(event, item, items)} className="material-icons">
                  keyboard_arrow_down
      </span>
            }
            <span>
              {item.name || '-'}
            </span>
          </div>
        </td>
        <td>
          <span>{item.assigneeName || '-'}</span>
        </td>
        <td>
          <span>{item.id === 0 ? '-' : item.state || '-'}</span>
        </td>
        <td>
          <span>{item.dueDate || '-'}</span>
        </td>
        <td>
          <span>{item.id === 0 ? '-' : item.updatedAt || '-'}</span>
        </td>
        <td>
          {
            +item.id !== 0 ? <OverlayTrigger
              trigger="click"
              key={'right'}
              rootClose
              placement={'right'}
              overlay={
                <Popover id={`popover-positioned-${'right'}`}>
                  <Popover.Content>
                    <ul id="popover-list">
                      {
                        item.isWatched ? <li onClick={() => {
                          props.setUILoaderState( true );
                          props.deleteWatchItem(item.id, item.guid, item.watchers)
                        }}>
                          Unwatch
                            </li> : <li onClick={() => {
                            props.addWorkItem(item.id, item.userId)
                          }}>
                            Watch Item
                            </li>
                      }
                      <li onClick={() => {
                        notifyUserByEmail(item)
                      }}>
                        Notify Assignee
                            </li>
                      {
                        item.depth < 2 ? <li onClick={() => {
                          addChildItem(item)
                        }}>
                          Add Child
                              </li> : null
                      }
                      <li onClick={() => {
                        editItem(item)
                      }}>Edit</li>
                      <li onClick={() => deleteWorkItem(item.id, item.guid)}>Delete</li>
                    </ul>
                  </Popover.Content>
                </Popover>
              }
            >
              <span className="material-icons">
                more_horiz
                </span>
            </OverlayTrigger>
              : null
          }
        </td>
      </tr>
      {renderSubMenu(item, item.childWorkItems)}
    </React.Fragment>
  }

  function renderMenuItems(items, parent?: any) {

    const { tempObject, showFirst } = props;
    const itemsToRender = items.map((item, index) => { return renderItems(item, index, parent, items) })
    return <React.Fragment>
      { showFirst && tempObject.workItemName && renderItems({ name: tempObject.workItemName, state: 'New', depth: tempObject.depth }, 'temp', parent, items) }
      {itemsToRender}
      { !showFirst && tempObject.workItemName && renderItems({ name: tempObject.workItemName, state: 'New', depth: tempObject.depth }, 'temp', parent, items) }
      {parent && parent.isOpen && parent.showAddItemInput ? <Row className="mt-1 mb-1" style={{
        'paddingLeft': parent.workItemTypeId == 3 ? '20%' : '30%'
      }}>
        <div className="add-workItem-button task"
          onClick={() => props.saveWorkItemParentIdGuId(parent.id, parent.guid, parent.workItemTypeId)}>
          <Form onSubmit={(event) => props.addQuickWorkItem(event, false, parent.workItemTypeId === 3 ? 1 : 2 )}>
            <div className='input-holder'>
              <input id="workitem-input-subtask"
                onChange={(event) => props.setWorkItemName(event.target.value)}
                placeholder={`${parent.workItemTypeId == 3 ? 'Add Task' : 'Add Sub Task'}`} />
              <button type="submit" className="span-item">
                <i className="material-icons icon-style"> arrow_right
                          </i>
              </button>
            </div>
          </Form>
        </div>
      </Row> : null
      }
    </React.Fragment>
  }

  return (
    <Table responsive hover size="sm">
      <thead>
        <tr id="tableHeading">
          <th>Name</th>
          <th>Assigned To</th>
          <th>State</th>
          <th>Due</th>
          <th>Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody id="tbody">
        {
          renderMenuItems(props.taskList)
        }
      </tbody>
    </Table>
  )
}

const mapStateToProps = (UIState, Error) => {
  return { UIState, Error };
};

const mapDispatchToProps = (dispatch) => ({
  deleteWorkItem: (id, guid, taskId) => dispatch(deleteWorkItem(id, guid, taskId)),
  setModalState: (modalState) => dispatch(setModalState(modalState)),
  asyncActionModal: (functionPassed) => dispatch(asyncActionModal(functionPassed)),
  setUILoaderState: (isLoading: boolean) => dispatch(setUILoaderState(isLoading)),
  setErrorMessage: (messageObject: ErrorMessage) => dispatch(setErrorMessage(messageObject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
