import { call, fork, put } from 'redux-saga/effects';
import { callUserApplicationListApi } from '../actions/ApplicationAndUserGroupAction';
import { setAuthDataRedux } from '../actions/AuthenticationAction';
import { setApiResponseFlag, setUILoaderState } from '../actions/UIAction';
import { AppConstant } from '../Constants';
import { AuthState } from '../Interfaces/AuthConfiginterface';
import {getJwtToken} from '../Services/Authentication/SignIn';

export default function* watchLogin(accessToken = '') {
    try {
            yield put(setUILoaderState(true));
            const data = yield call(getJwtToken, accessToken);
            if(Object.keys(data).length === 0 && data.constructor === Object) {
                const userId = localStorage.getItem('userId');
                yield put(callUserApplicationListApi(userId));
            } else {
                yield put(callUserApplicationListApi(data.userId));
                const groups = data.groups;
                const AuthData: AuthState = {
                    accessToken: accessToken || '',
                    userId: data.userId,
                    userName: data.emailId,
                    role: data.groups || [],
                    isAuthenticated: true
                }
                yield put(setAuthDataRedux(AuthData));
                localStorage.setItem(AppConstant.USER_ID, data.userId);
                const adminGroup = groups.find(group => {
                    return group.groupName === AppConstant.ADMIN;
                })
                const roles = JSON.stringify(data.groups);
                localStorage.setItem(AppConstant.ROLE, roles);
                adminGroup !== undefined ? localStorage.setItem(AppConstant.ADMIN_ROLE, adminGroup.groupName) :
                localStorage.setItem(AppConstant.ADMIN_ROLE, 'not admin');
                localStorage.setItem("firstName", data.firstName);
                localStorage.setItem(AppConstant.JWT_TOKEN, data.token);
                localStorage.setItem('guid', data.guid);
                if(data.supportGroup != null) {
                    localStorage.setItem('supportGroupMemberGuid', data.supportGroup.supportGroupMemberGuid);
                    localStorage.setItem('supportGroupDepartmentId', data.supportGroup.supportGroupDepartmentId);
                    localStorage.setItem('supportGroupDepartmentName', data.supportGroup.supportGroupDepartmentName);
                }
                
            }
    } catch (error) {
        yield put(setUILoaderState(false));
    }
}
