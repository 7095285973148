import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './Store';

import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import { createBrowserHistory } from "history";


const store = configureStore();

function AppWrapper() {

  const history = createBrowserHistory();
  return (
    <Provider store = {store}>
        <Router>
        <App/>
        </Router>
    </Provider>
  );
}

export default(AppWrapper);
