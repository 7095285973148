import { UIActionConstants } from './Constants';

const setActiveRoute = (activeRouteId: number) => {
    return {
        type: UIActionConstants.SET_ACTIVE_ROUTE_ID,
        data: activeRouteId
    }
};

const setHeaderHeading = (name: string) => {
    return {
        type: UIActionConstants.HEADER_HEADING,
        data: name
    }
}

const setModalState = (modalState: boolean) => {
    return {
        type: UIActionConstants.MODAL_STATE,
        data: modalState
    }
}

const resolveModalPromise = (modalResolve) => {
    return {
        type: UIActionConstants.MODAL_FUNCTION,
        data: modalResolve
    }
}

const asyncActionModal = (modalResolve) => {
    return {
        type: UIActionConstants.ASYNC_MODAL_FUNCTION,
        data: modalResolve
    };
}

const setUILoaderState = (isLoading: boolean) => {
    return {
        type: UIActionConstants.IS_LOADING,
        data: isLoading
    };
}

const setApiResponseFlag = (responseFlag: boolean) => {
    return {
        type: UIActionConstants.API_RESPONSE,
        data: responseFlag
    };
}

export {setActiveRoute, setHeaderHeading, setModalState, resolveModalPromise, 
        asyncActionModal, setUILoaderState, setApiResponseFlag};

