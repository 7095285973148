import { createAction } from '../Utils/createActionType';
import {  TemplateList } from './types'
import { WorkItem } from '../Interfaces/WorkItemInterface';
import { changeStatusTemplateInterface } from '../Containers/TaskList/MyItems/Template';

export const  callTemplateList = (filterParams?:any) => createAction(TemplateList.CALL_TEMPLATE, {filterParams});

export const  setTemplateList = (Template: any[]) => createAction(TemplateList.SET_TEMPLATE, { Template });

export const  deleteTemplateList = (id: number, guid: string, filterParams?: any) => createAction(TemplateList.DELETE_TEMPLATE, { id, guid, filterParams });

export const  setWorkItemPagination = ({currentPage, hasNext, hasPrevious, 
    pageSize, totalCount, totalPages}) => createAction(TemplateList.TEMPLATE_PAGINATION, { 
    currentPage, hasNext, hasPrevious, pageSize, totalCount, totalPages
});

export const changeTemplateStatus = (changeStatusTemplate: changeStatusTemplateInterface,
     filterParams?: any) => {
    return createAction(TemplateList.TEMPLATE_STATUS, { changeStatusTemplate, filterParams });
}
