import React, {useEffect} from 'react';

import { connect } from 'react-redux';

import { Row } from 'react-bootstrap';
import Styles from './Dashboard.module.css';

import Card from '../../Components/Card/Card';

import { setHeaderHeading } from '../../actions/UIAction';
import { setActiveRoute } from '../../actions/UIAction';
import { callUserApplicationListApi } from '../../actions/ApplicationAndUserGroupAction';

import { Application } from '../../Interfaces/ApplicationListInterface';

import { AppConstant } from '../../Constants';
import { ApplicationAndUserList } from '../../Interfaces/applicationAndUserGroupInterface';
import { RootState } from '../../reducers/RootReducer';
import { UIState } from '../../Interfaces/UIStateInterface';


interface Props {
  setHeaderHeading: (name: string) => void;
  callUserApplicationListApi: (userId: any) => void;
  children: React.ReactNode[];
  ApplicationAndUserGroup: ApplicationAndUserList;
  setActiveRoute: (routeId: number) => void;
  UIState: UIState
};

const Dashboard = (props: Props) => {

  const {ApplicationAndUserGroup: {dashBoardList: applicationList},
    setHeaderHeading, setActiveRoute, UIState: {isApiResponseFlag}} = props;

  useEffect(() => {
    setHeaderHeading(AppConstant.APPLICATIONS);
  }, [])

  useEffect(() => {
    setHeaderHeading(AppConstant.APPLICATIONS);
    //setActiveRoute(1);
    // if (localStorage.getItem(AppConstant.USER_ID)) {
    //   callUserApplicationListApi(localStorage.getItem(AppConstant.USER_ID));
    // }
  }, [applicationList]);

    // filter assigned application
    const filteredList = applicationList.filter((data: Application) => {
      return data.isAssigned === true;
    });
    const NoAppsAssigned = (applicationList && 
      filteredList.length === 0 && isApiResponseFlag === true ? <h4 className = "alignNoData">
      No Application Assigned</h4>: filteredList.length === 0 && isApiResponseFlag === false? <h4 
      className = "alignNoData">
      Loading your Application Permissions
      </h4>: null);

    return (
      <div>
        <hr/>
        <Row className = {`${filteredList.length === 0? 'justify-content-md-center': 
          null
        }`}>
            <div className = {Styles.links}>
                {
                    applicationList.map((data, index) => {
                      const {applicationIconUrl, applicationUrl, applicationName, isAssigned} = data;
                        return isAssigned ? <Card
                        applicationUrl = {applicationUrl}
                        applicationIconUrl = {applicationIconUrl}
                        applicationName = {applicationName}
                        key = {index}
                        /> : null;
                    })
                }
                {NoAppsAssigned}
            </div>
        </Row>
        <hr/>
      </div>
    );
}

const mapStateToProps = (UIState) => {
  return UIState;
};

const mapDispatchToProps = (dispatch) => ({
  setHeaderHeading: (heading: string) => dispatch(setHeaderHeading(heading)),
  setActiveRoute: (routeId: number) => dispatch(setActiveRoute(routeId)),
  callUserApplicationListApi: (userId) => dispatch(callUserApplicationListApi(userId)),
});

export default connect<Props>(mapStateToProps, mapDispatchToProps)(Dashboard as any);