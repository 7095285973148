import React, { Component } from 'react'
import { Badge, Card, Col, Container, Dropdown, DropdownButton, Form, ListGroup, ListGroupItem, Nav, OverlayTrigger, Popover, Row, Tab } from 'react-bootstrap'
import ButtonComponent from '../../../../Components/Button/Button';
import MyStatefulEditor from '../../../../Components/Text-Editor/TextEditor';
import './AddWorkItemComponent.css';
import NoImg from '../../../../Assets/Images/no-img.png';
import Attachments from '../../../../Components/Attachments/Attachments';
import Comments from '../../../../Components/Comments/Comments';
import { ButtonName, Routes } from '../../../../Constants';
import { CustomToggle, CustomMenu } from '../../../../Components/SearchSelect/SearchSelectDropDown';
import { getAllUsers } from '../../../../Services/ApplicationAndUserGroup/ApplicationAndUserService';
import { PriorityEnum } from '../../../../Enum/Priority.enum';
import { WorkItemStatusEnum } from '../../../../Enum/WorkItemStatus.enum';
import { visibilityEnum } from '../../../../Enum/Visibility.enum';
import { AddWatcherModal } from './AddWatcherModal';
import {
    assignCopies, deleteAttachments, deleteWorkItemComments,
    deleteWorkItemService, delteWatcherById,
    editWorkItemForm, getAllProjects, getAttachmentsFromWorkItem, getByIdWorkItem,
    getChildWorkItem,
    getWatchers, getWorkItemComments, notifyUser, postWatchers, postWorkItemComments,
    sendWorkItemForm
} from '../../../../Services/WorkItemAndTaskService/WorkItemList';
import moment from 'moment';
import ChildWorkItemList from './ChildWorkItemList';
import { asyncActionModal, setModalState, setUILoaderState } from '../../../../actions/UIAction';
import { connect } from 'react-redux';
import { ErrorMessage } from '../../../../Interfaces/ErrorMessageInterface';
import { setErrorMessage } from '../../../../actions/ErrorsAction';
import RecurrenceModal from "../../../../Components/WorkItemSetRepeat/SetRepeat";
import RichTextEditor from 'react-rte';
import { SaveAsTemplateModal } from './SaveTemplateModal';
import { getTemplateById, getTemplateList, saveTemplate, TemplateStatusService } from '../../../../Services/Template/TemplateService';
import { FillTemplateModal } from './FillTemplateModal';
import TemplateCard from './TemplateCard';
import { changeStatusTemplateInterface } from '../Template';
import { SubCategory } from '../../../../Enum/SubCategoryEnum';
import { Category } from '../../../../Enum/CategoryEnum';
import { AppConstant } from '../../../../Constants';
import { authProvider } from '../../../../Services/Authentication/AuthProvider';


interface State {
    filterValue: string;
    isSaveDisabled: boolean,
    inputDisabled: boolean,
    isAdmin: boolean,
    userList: Array<any>;
    workItem: any;
    commentDescription: string,
    showWatcherModal: boolean;
    totalComments: number;
    initialWorkItem: any;
    watchers: Array<any>;
    errors: any;
    showChildTasks: boolean,
    parentWorkItemName: string;
    projectList: any[];
    comments: any[];
    modalType: string;
    isUserNotified: boolean,
    showRecurrenceModal: boolean,
    descriptionValue: any;
    commentValue: any;
    setRepeatState: any,
    workItemName: string,
    showTemplateModal: boolean,
    parentWorkItemDetails: {
        id: number,
        guid: string | null, workItemTypeId: number
    },
    showFillTemplateModal: boolean,
    template?: any
}

export class AddWorkItemComponent extends Component<any, State> {

    state: State = {
        filterValue: 'Ramneek',
        isSaveDisabled: true,
        inputDisabled: false,
        isAdmin: false,
        template: {
            id: null,
            name: '',
            guid: null,
            categoryId: null,
            subcategoryId: null
        },
        showTemplateModal: false,
        showWatcherModal: false,
        workItemName: '',
        modalType: 'watcher',
        showRecurrenceModal: false,
        showChildTasks: false,
        showFillTemplateModal: false,
        descriptionValue: RichTextEditor.createEmptyValue(),
        commentValue: RichTextEditor.createEmptyValue(),
        watchers: [],
        userList: [],
        comments: [],
        totalComments: 0,
        parentWorkItemDetails: {
            id: 0,
            guid: null,
            workItemTypeId: 3
        },
        commentDescription: '',
        projectList: [],
        initialWorkItem: {},
        parentWorkItemName: '',
        isUserNotified: false,
        setRepeatState: {
            interval: 1,
            workItemId: +this.props.match.params.id,
            timePeriodType: 1,
            isRepeatSameWeekDayOfMonth: false,
            endDate: moment(new Date).format("YYY-MM-DD"),
            occurrence: 0
        },
        workItem: {
            name: '',
            id: 0,
            guid: '',
            fileList: [],
            attachments: [],
            childWorkItems: [],
            isWatcherAssigned: false,
            assigneeName: 'Assigned To',
            assigneeId: 0,
            workItemStatusId: 5,
            status: {
                id: 5,
                name: 'New'
            },
            project: {
                id: 0,
                name: 'Project'
            },
            projectId: null,
            workItemPriorityId: 2,
            priority: {
                id: 2,
                name: 'Normal'
            },
            workItemVisibilityId: 2,
            visibility: {
                id: 2,
                name: 'Internal'
            },
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: '',
            description: '',
            workItemTypeId: +this.props.match.params.workItemId,
            createdBy: +(localStorage.getItem('userId') as any),
            updatedBy: +(localStorage.getItem('userId') as any),
        },
        errors: null
    }

    compare(a, b) {
        if (a.firstName < b.firstName) {
            return -1;
        }
        if (a.firstName > b.firstName) {
            return 1;
        }
        return 0;
    }

    addDepth(taskItem, depth) {
        taskItem.forEach(obj => {
            obj['depth'] = depth;
            obj['showAddItemInput'] = false;
            obj['isWatcherAssigned'] = this.setWatcherState(obj);
            this.addDepth(obj.childWorkItems, depth + 1)
        });
    }

    setWatcherState(taskItem) {
        const userId: any = localStorage.getItem('userId');
        if (taskItem.watchers !== null) {
            const watcherId = taskItem.watchers.findIndex(watcher => {
                return watcher.userId === +userId;
            })
            return watcherId === -1 ? false : true;
        }
    }

    getProjectById(projectId: number) {
        const projectIndex = this.state.projectList.findIndex(project => {
            return project.projectId === projectId;
        })
        if (projectIndex !== -1) {
            return this.state.projectList[projectIndex].name
        } else {
            return ''
        }
    }

    componentDidMount() {
        this.initialSetup();
        this.prePopulate();
        const roleName = localStorage.getItem(AppConstant.ADMIN_ROLE);
        const isAdmin = (authProvider.authenticationState === AppConstant.AUTHENTICATED) && (roleName === AppConstant.ADMIN);
        this.setState({
            isAdmin
        });
        if( window.location.search.indexOf('&showChildTasks') > -1 ) {
            this.setState({
                showChildTasks: true
            })
        }
    }

    prePopulate = () => {

        const { search } = window.location;

        if (search) {
            const subSearchArray = search.substr(1).split('&');
            for (const item of subSearchArray) {
                const array = item.split('=');
                const [key, value] = array;
                if (key === 'guid') {
                    this.getTemplateData(value, this, false);
                }
            }
        }
    }

    initialSetup = () => {
        this.props.setUILoaderState(true);
        const isTemplate = this.props.match.params.isTemplate;
        console.log(this.props.match.params.isTemplate);
        // if(isTemplate === 'false') {

        getAllUsers().then((response) => {
            const { data } = response;
            if (data !== undefined) {
                const filteredUserList = data.filter(value => {
                    if (value.firstName != '') {
                        value['name'] = value.firstName + " " + value.lastName;
                        return value;
                    }
                })
                this.setState({
                    userList: filteredUserList.sort(this.compare),
                    initialWorkItem: {
                        ...this.state.workItem,
                        attachments: [...this.state.workItem.attachments]
                    }
                }, () => {
                    if (this.props.match.params.isEdit === "false") {
                        this.props.setUILoaderState(false);
                    }
                })
            } else {
                this.setState({
                    userList: [], initialWorkItem: {
                        ...this.state.workItem,
                        attachments: [...this.state.workItem.attachments]
                    }
                }, () => {
                    if (this.props.match.params.isEdit === "false") {
                        this.props.setUILoaderState(false);
                    }
                })
            }
        });
        getAllProjects().then((response: any) => {
            const { data } = response;
            if (data !== undefined) {
                this.setState({ projectList: data })
            } else {
                this.setState({ projectList: [] })
            }
        });
        // }

        if (this.props.match.params.parentWorkItemId !== "null" &&
            +this.props.match.params.parentWorkItemId !== 0 &&
            +this.props.match.params.workItemId <= 2 && isTemplate === 'false') {
            getByIdWorkItem(+this.props.match.params.parentWorkItemId,
                this.props.match.params.parentWorkItemGuid)
                .then(({ data: workItem }: any) => {
                    if (workItem && workItem.name) {
                        this.setState({ ...this.state, parentWorkItemName: workItem.name })
                    }
                })
        }
        if (this.props.match.params.isEdit === 'true' && isTemplate === 'false') {
            const curRef = this;
            getWorkItemComments(+this.props.match.params.id, this.props.match.params.guid)
                .then(({ data: comments }: any) => {
                    if (comments && Array.isArray(comments.data)) {
                        this.setState({ ...this.state, comments: comments.data })
                    }
                })
            getByIdWorkItem(+this.props.match.params.id, this.props.match.params.guid)
                .then(({ data: workItem }: any) => {
                    if (workItem !== undefined) {
                        const childItems = workItem.childWorkItems;
                        this.addDepth(childItems, 0)
                        const workItems = {
                            name: workItem.name,
                            id: workItem.id,
                            guid: workItem.guid,
                            childWorkItems: childItems,
                            fileList: [],
                            attachments: [],
                            isWatcherAssigned: this.setInitialWatcherState(workItem),
                            assigneeName: workItem.assigneeName || "Assigned To",
                            assigneeId: workItem.userId || 0,
                            workItemStatusId: workItem.workItemStatusId,
                            status: {
                                id: workItem.workItemStatusId,
                                name: WorkItemStatusEnum[+workItem.workItemStatusId]
                            },
                            workItemPriorityId: workItem.workItemPriorityId,
                            priority: {
                                id: workItem.workItemPriorityId,
                                name: PriorityEnum[+workItem.workItemPriorityId]
                            },
                            workItemVisibilityId: workItem.workItemVisibilityId,
                            visibility: {
                                id: workItem.workItemVisibilityId,
                                name: visibilityEnum[+workItem.workItemVisibilityId]
                            },
                            projectId: workItem.projectId,
                            project: {
                                id: workItem.projectId,
                                name: this.getProjectById(workItem.projectId) || 'Project'
                            },
                            startDate: this.getDate(workItem.startDate),
                            endDate: this.getDate(workItem.endDate),
                            description: workItem.description,
                            workItemTypeId: +this.props.match.params.workItemId,
                            createdBy: +(localStorage.getItem('userId') as any),
                            updatedBy: +(localStorage.getItem('userId') as any),
                        }
                        this.setState({
                            ...this.state,
                            descriptionValue: workItem.description === null ? RichTextEditor.createValueFromString(
                                '', 'html') : RichTextEditor.createValueFromString(workItem.description, 'html'),
                            isUserNotified: workItem.isAssigneeNotified,
                            watchers: workItem.watchers,
                            initialWorkItem: { ...workItems, attachments: [...workItems.attachments] },
                            workItem: { ...workItems }
                        })
                        const currRef = this;
                        getAttachmentsFromWorkItem(+this.props.match.params.id,
                            this.props.match.params.guid)
                            .then((response: any) => {

                                this.setState({
                                    ...currRef.state,
                                    totalComments: response.data.totalCount,
                                    watchers: workItem.watchers,
                                    initialWorkItem: {
                                        ...currRef.state.workItem,
                                        attachments: [...response.data.data]
                                    },
                                    workItem: {
                                        ...currRef.state.workItem,
                                        attachments: response.data.data
                                    }
                                }, () => {
                                    currRef.props.setUILoaderState(false);
                                })
                            })
                    }
                })
        }

        if (isTemplate === 'true') {
            getTemplateById(this.props.match.params.guid)
                .then((response) => {
                    console.log(response.data)
                    const { workItemTemplate: { workItem, id, guid, name,
                        categoryId, subcategoryId } } = response.data as any;
                    this.addDepth(workItem.childWorkItems, 1);
                    this.setState({
                        ...this.state,
                        descriptionValue: workItem.description === null ? RichTextEditor.createValueFromString(
                            '', 'html') : RichTextEditor.createValueFromString(workItem.description, 'html'),
                        template: {
                            id,
                            guid,
                            name,
                            categoryId: categoryId,
                            subcategoryId: subcategoryId
                        },
                        watchers: [...workItem.watchers],
                        workItem: {
                            ...this.state.workItem,
                            ...workItem,
                            assigneeName: workItem.assigneeName || "Assigned To",
                            assigneeId: workItem.userId || 0,
                            visibility: {
                                id: workItem.workItemVisibilityId,
                                name: visibilityEnum[+workItem.workItemVisibilityId]
                            },
                            project: {
                                id: workItem.projectId,
                                name: this.getProjectById(workItem.projectId) || 'Project'
                            },
                            priority: {
                                id: workItem.workItemPriorityId,
                                name: PriorityEnum[+workItem.workItemPriorityId]
                            },
                            status: {
                                id: workItem.workItemStatusId,
                                name: WorkItemStatusEnum[+workItem.workItemStatusId]
                            },
                            startDate: this.getDate(workItem.startDate),
                            endDate: this.getDate(workItem.endDate),
                        }
                    }, () => {
                        console.log(this.state.workItem);
                        this.props.setUILoaderState(false)
                    })
                });
        }
    }

    getTemplateDataById(templateGuId, thisRef) {

        thisRef.setState({
            showFillTemplateModal: !thisRef.state.showFillTemplateModal
        });

        thisRef.getTemplateData(templateGuId, thisRef, true);
    }

    getTemplateData(templateGuId, thisRef, isNew = false) {
        getTemplateById(templateGuId)
            .then(response => {
                const { workItemTemplate: { workItem, id, guid,
                    categoryId, subcategoryId, name, creatorName, createdBy } } = response.data;
                thisRef.addDepth(workItem.childWorkItems, 0)
                thisRef.setState({
                    ...thisRef.state,
                    descriptionValue: workItem.description === null ? RichTextEditor.createValueFromString(
                        '', 'html') : RichTextEditor.createValueFromString(workItem.description, 'html'),
                    template: {
                        id,
                        guid,
                        name,
                        subcategoryId: SubCategory[subcategoryId],
                        categoryId: Category[categoryId],

                    },
                    workItem: {
                        ...thisRef.state.workItem,
                        ...workItem,
                        assigneeName: workItem.assigneeName || "Assigned To",
                        assigneeId: createdBy || 0,
                        priority: {
                            id: workItem.workItemPriorityId,
                            name: PriorityEnum[+workItem.workItemPriorityId]
                        },
                        visibility: {
                            id: workItem.workItemVisibilityId,
                            name: visibilityEnum[+workItem.workItemVisibilityId]
                        },
                        project: {
                            id: workItem.projectId,
                            name: thisRef.getProjectById(workItem.projectId) || 'Project'
                        },
                        status: {
                            id: isNew ? 5 : workItem.workItemStatusId,
                            name: WorkItemStatusEnum[isNew ? 5 : +workItem.workItemStatusId]
                        },
                        startDate: thisRef.getDate(moment(new Date()).format("YYYY-MM-DD")),
                    },
                    watchers: [...workItem.watchers]
                })
            });
    }

    applicationFilter(data: any) {
        this.setState({
            ...this.state,
            isSaveDisabled: false,
            workItem: {
                ...this.state.workItem,
                assigneeName: data.firstName + " " + data.lastName,
                assigneeId: data.id
            }
        })
    }
    setDropDownValue(type: string, value: any) {
        if (type === "priority") {
            const dropDownValue = {
                ...this.state,
                workItem: {
                    ...this.state.workItem,
                    priority: {
                        id: +PriorityEnum[value],
                        name: value
                    }
                }
            }
            this.setState(dropDownValue);
        } else if (type === "project") {
            const dropDownValue = {
                ...this.state,
                workItem: {
                    ...this.state.workItem,
                    project: {
                        id: +value,
                        name: this.getProjectById(value)
                    }
                }
            }
            this.setState(dropDownValue);
        } else if (type === 'visibility') {
            const dropDownValue = {
                ...this.state,
                workItem: {
                    ...this.state.workItem,
                    visibility: {
                        id: +visibilityEnum[value],
                        name: value
                    }
                }
            }
            this.setState(dropDownValue);

        } else {
            const dropDownValue = {
                ...this.state,
                workItem: {
                    ...this.state.workItem,
                    status: {
                        id: +WorkItemStatusEnum[value],
                        name: value
                    }
                }
            }
            this.setState(dropDownValue);
        }

        this.setState({
            isSaveDisabled: false
        })
    }

    handleOnBlur = (type: string) => {
        const { workItem: { name, startDate, priority, status, visibility }, errors } = this.state;

        switch (type) {
            case 'name':
                if (name == '') {
                    const nameError = "This field is required";
                    this.setState({
                        errors: {
                            ...errors,
                            nameError: nameError
                        }
                    })
                } else {
                    const nameError = '';
                    this.setState({
                        errors: {
                            ...errors,
                            nameError: nameError
                        }
                    })
                }
                break;
            case 'startDate':
                if (startDate == '') {
                    const startDateError = "This field is required";
                    this.setState({
                        errors: {
                            ...errors,
                            startDateError: startDateError
                        }
                    })
                } else {
                    const startDateError = '';
                    this.setState({
                        errors: {
                            ...errors,
                            startDateError: startDateError
                        }
                    })
                }
                break;
            case 'visibility':
                if (visibility.name == 'Visibility') {
                    const VisibilityError = "This field is required";
                    this.setState({
                        errors: {
                            ...errors,
                            VisibilityError: VisibilityError
                        }
                    })
                } else {
                    const VisibilityError = '';
                    this.setState({
                        errors: {
                            ...errors,
                            VisibilityError: VisibilityError
                        }
                    })
                }
                break;
            case 'Priority':
                if (priority.name == 'Priority') {
                    const PriorityError = "This field is required";
                    this.setState({
                        errors: {
                            ...errors,
                            PriorityError: PriorityError
                        }
                    })
                } else {
                    const PriorityError = '';
                    this.setState({
                        errors: {
                            ...errors,
                            PriorityError: PriorityError
                        }
                    })
                }
                break;
            case 'Status':
                if (status.name == 'Status') {
                    const StatusError = "This field is required";
                    this.setState({
                        errors: {
                            ...errors,
                            StatusError: StatusError
                        }
                    })
                } else {
                    const StatusError = '';
                    this.setState({
                        errors: {
                            ...errors,
                            StatusError: StatusError
                        }
                    })
                }
                break;
        }
    }

    setInitialWatcherState(workItem) {
        const userId: any = localStorage.getItem('userId');
        if (workItem.watchers !== null) {
            const watcherId = workItem.watchers.findIndex(watcher => {
                return watcher.userId === +userId;
            })
            return watcherId === -1 ? false : true;
        }
    }

    handleWorkItem(type: string, event) {
        let workItem;
        switch (type) {
            case 'name':
                workItem = {
                    ...this.state.workItem,
                    name: event.target.value
                }
                this.setState({ workItem: workItem }, () => this.handleOnBlur('name'))
                break;
            case 'startDate':
                workItem = {
                    ...this.state.workItem,
                    startDate: event.target.value
                }
                this.setState({ workItem: workItem })
                break;
            case 'endDate':
                workItem = {
                    ...this.state.workItem,
                    endDate: event.target.value
                }
                this.setState({ workItem: workItem })
                break;
        }
        this.setState({
            isSaveDisabled: false
        })
    }

    setDescription(curRef, description: string, value) {
        const workItem = {
            ...curRef.state.workItem,
            description: description
        }
        curRef.setState({ descriptionValue: value, workItem: workItem, isSaveDisabled: false })
    }

    setCommentDescription(curRef, description: string, value) {
        curRef.setState({ commentValue: value, commentDescription: description })
    }

    setCurrentUserWatcher() {
        const userId: any = localStorage.getItem('userId');
        this.selectedWatcher([{
            id: +userId,
            firstName: '',
            lastName: ''
        }], "current")
    }

    deleteCurrentUserWatcher() {
        this.props.setUILoaderState(true);
        const userId: any = localStorage.getItem('userId');
        const currentWatcherDetail = this.state.watchers.find(watcher => {
            return watcher.userId === +userId;
        })
        if (currentWatcherDetail) {
            delteWatcherById(currentWatcherDetail.id, currentWatcherDetail.guid)
                .then(response => {
                    getWatchers(+this.props.match.params.id, this.props.match.params.guid)
                        .then((response: any) => {
                            const watcherList = response.data.data;
                            this.setState({
                                watchers: watcherList,
                                showWatcherModal: false,
                                workItem: {
                                    ...this.state.workItem,
                                    isWatcherAssigned: !this.state.workItem.isWatcherAssigned
                                }
                            }, () => {
                                this.props.setUILoaderState(false);
                            })
                        })
                })
        } else {
            this.props.setUILoaderState(false);
        }
    }

    selectedWatcher(selectedList, type?: string) {
        const watcherList = [...this.state.watchers];

        const isTemplate = this.props.match.params.isTemplate === "true";
        const id = isTemplate ? +this.state.workItem.id : +this.props.match.params.id;
        const guid = isTemplate ? this.state.workItem.guid : this.props.match.params.guid

        const postWatcherList = selectedList.map((value: any) => {
            // const userId: number = localStorage.getItem('userId');
            return {
                userId: value.id,
                watcherName: value.firstName + ' ' + value.lastname,
                workItemId: id
            }
        })
        const thisRef = this;
        const postList = [...watcherList, ...postWatcherList];
        this.props.setUILoaderState(true);
        postWatchers(postList).then((response: any) => {
            getWatchers(id, guid)
                .then((response: any) => {
                    const watcherList = response.data.data;
                    thisRef.setState({
                        watchers: watcherList,
                        showWatcherModal: false
                    }, () => {
                        if (type === "current") {
                            thisRef.setState({
                                workItem: {
                                    ...this.state.workItem,
                                    isWatcherAssigned: !this.state.workItem.isWatcherAssigned
                                }
                            })
                        }
                        this.props.setUILoaderState(false);
                    })
                })
        })
            .catch(error => {
                thisRef.setState({ watchers: this.state.watchers })
            })
    }

    selectUserForCopies(selectedList) {
        const thisRef = this;
        this.props.setUILoaderState(true);
        const userId = localStorage.getItem('userId') as string;
        const assignees = selectedList.map(value => {
            return {
                userId: value.id,
            }
        })
        const workItemAssignee = Object.assign({}, {
            assignees: assignees,
            userId: +userId,
            workItemId: +this.props.match.params.id
        })
        assignCopies(workItemAssignee)
            .then(response => {
                thisRef.setState({ showWatcherModal: false }, () => {
                    thisRef.props.setUILoaderState(false);
                })
            })
    }

    addWatcherPopUp(currRef, selectedList) {
        if (currRef.state.modalType === 'watcher') {
            currRef.selectedWatcher(selectedList)
        } else {
            currRef.selectUserForCopies(selectedList)
        }
    }

    openModal(type: string) {
        const curRef = this;
        if (type === 'watcher') {
            this.setState({ modalType: 'watcher' }, () => {
                curRef.setState({ showWatcherModal: true })
            })
        } else {
            this.setState({ modalType: 'Assign_Copies' }, () => {
                curRef.setState({ showWatcherModal: true })
            })
        }
    }

    closeModal() {
        this.setState({ showWatcherModal: false })
    }

    closeRepeatModal(thisRef) {
        thisRef.setState({ showRecurrenceModal: false })
    }

    postWorkItemFormData() {
        const { workItem } = this.state;
        const thisref = this;
        this.props.setUILoaderState(true);
        let formData = new FormData();
        formData.set("AssigneeName", workItem.assigneeName);
        if (workItem.assigneeId !== 0 && workItem.assigneeId !== null) {
            formData.set("UserId", workItem.assigneeId);
        }
        formData.set("Description", workItem.description);
        formData.set("Name", workItem.name);
        if (moment(workItem.endDate).format("MMM D YYYY") !== "Invalid date") {
            formData.set("EndDate", moment(workItem.endDate).format("MMM D YYYY"));
        }
        formData.set("StartDate", moment(workItem.startDate).format("MMM D YYYY"));
        formData.set("WorkItemTypeId", `${this.props.match.params.workItemId}`);
        formData.set("WorkItemStatusId", workItem.status.id);
        formData.set("WorkItemPriorityId", workItem.priority.id);
        formData.set("WorkItemVisibilityId", workItem.visibility.id);
        formData.set("UpdatedBy", workItem.updatedBy);
        formData.set("CreatedBy", workItem.createdBy);
        if (workItem.project.id != 0 && workItem.project.id !== null)
            formData.append("ProjectId", workItem.project.id);
        for (let i = 0; i < workItem.fileList.length; i++) {
            formData.append("Attachments", workItem.fileList[i]);
        }
        if (workItem.isTemplate) {
            formData.append("TemplateId", this.state.template.id)
        }

        if ((this.props.match.params.workItemId === "2" ||
            this.props.match.params.workItemId === "1") &&
            this.props.match.params.parentWorkItemGuid !== "null") {
            formData.set("ParentWorkItemId", this.props.match.params.parentWorkItemId)
            formData.set("ParentWorkItemGuid", this.props.match.params.parentWorkItemGuid)
        }

        if (this.props.match.params.isEdit === "false") {
            sendWorkItemForm(formData).then((response: any) => {
                this.props.setUILoaderState(false);
                thisref.props.history.push('/tasklist/1');
            })
        } else {
            formData.set("Id", this.props.match.params.id);
            formData.set("Guid", this.props.match.params.guid);
            editWorkItemForm(formData).then((response: any) => {
                this.props.setUILoaderState(false);
                thisref.props.history.push('/tasklist/1');
            })
        }
    }

    uploadFilesData(event) {
        const files = event.target.files;
        let attachmenstDisplay: any[] = [];
        const { workItem: { attachments, fileList } } = this.state;

        for (var i = 0; i < files.length; i++) {
            attachmenstDisplay.push({
                name: files[i].name,
                type: files[i].type,
                uploadedBy: localStorage.getItem('firstName'),
                uploadedOn: moment(new Date()).format("MMM DD YYYY")
            })
        }
        fileList.push(...files)
        attachments.push(...attachmenstDisplay)

        this.setState({
            ...this.state, workItem: {
                ...this.state.workItem,
                fileList: fileList,
                attachments: attachments
            }
        })
    }

    getDate(date) {
        return date !== null ? date.split('T')[0] : null;
    }

    deleteWatcher(watcherId: number, watcherGuid: string, previousRoute?: boolean) {
        const thisRef = this;
        const isTemplate = this.props.match.params.isTemplate === "true";
        
        const id = isTemplate ? +this.state.workItem.id : +this.props.match.params.id;
        const guid = isTemplate ? this.state.workItem.guid : this.props.match.params.guid;

        thisRef.props.setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: "Are you sure, you want to delete?"
            }
        )
        thisRef.props.setModalState(true);
        thisRef.props.asyncActionModal(() => {
            thisRef.props.setUILoaderState(true);
            delteWatcherById(watcherId, watcherGuid)
                .then(response => {
                    getWatchers(id, guid)
                        .then((response: any) => {
                            const watcherList = response.data.data;
                            thisRef.setState({ watchers: watcherList, showWatcherModal: false }, () => {
                                thisRef.props.setUILoaderState(false);
                            })
                        })
                })
        });
    }

    deleteChildWatcher(thisRef, watcherId: number, watcherGuid: string, childItem) {
        const userId: any = localStorage.getItem('userId');
        const watcher = childItem.watchers.find(watcher => {
            return watcher.userId === +userId;
        })
        const id: number = +thisRef.props.match.params.id;
        const guid: string = thisRef.props.match.params.guid;
        const depth = +thisRef.props.match.params.workItemId - 1;
        if (watcher) {
            delteWatcherById(watcher.id, watcher.guid)
                .then(response => {
                    getChildWorkItem(id, guid)
                        .then(response => {
                            if (response && response.data) {
                                const childWorkItems = response.data.data;
                                thisRef.addDepth(childWorkItems, depth);
                                thisRef.setState({
                                    workItem: {
                                        ...thisRef.state.workItem,
                                        childWorkItems: childWorkItems
                                    }
                                })
                            }
                        })
                })
        }
    }

    addChildWatcher(thisRef, workItemId: number, userId: number, childItem) {
        const myUserId: any = localStorage.getItem('userId');
        const postList = [{
            workItemId: workItemId,
            userId: +myUserId
        }]
        const id: number = +thisRef.props.match.params.id;
        const guid: string = thisRef.props.match.params.guid;
        const depth = +thisRef.props.match.params.workItemId - 1;
        postWatchers(postList).then((response: any) => {
            getChildWorkItem(id, guid)
                .then(response => {
                    if (response && response.data) {
                        const childWorkItems = response.data.data;
                        thisRef.addDepth(childWorkItems, depth);
                        thisRef.setState({
                            workItem: {
                                ...thisRef.state.workItem,
                                childWorkItems: childWorkItems
                            }
                        })
                    }
                })
        })
    }

    navigateToChildTask() {
        const depthValue = +this.props.match.params.workItemId - 1;
        const id = this.props.match.params.id;
        const guid = this.props.match.params.guid;
        const name = this.props.match.params.name || this.state.parentWorkItemName;
        const createA = document.createElement('a');
        const isTemplate = this.props.match.params.isTemplate === "true" || +this.props.match.params.id === 0;
        if (isTemplate) {
            const { id, guid, name } = this.state.workItem;
            createA.setAttribute('href', `${Routes.ADD_NEW_WORK_Item}/false/${depthValue}/null/null/${id}/${guid}/false/${name}`);
            createA.setAttribute('target', "_blank");
            createA.click()
            createA.remove();
        } else {
            createA.setAttribute('href', `${Routes.ADD_NEW_WORK_Item}/false/${depthValue}/null/null/${id}/${guid}/false/${name}`);
            createA.setAttribute('target', "_blank");
            createA.click()
            createA.remove();
        }

    }

    postComments() {
        const userId: any = localStorage.getItem('userId') as any;
        this.props.setUILoaderState(true);
        let rex = /(<([^>]+)>)/ig;
        const postComments = {
            commentVisibilityId: 2,
            createdAt: new Date(),
            description: this.state.commentDescription.replace(rex, ""),
            userId: +userId,
            userName: localStorage.getItem('firstName') || '',
            workItemId: +this.props.match.params.id
        }
        postWorkItemComments(postComments)
            .then(response => {
                getWorkItemComments(+this.props.match.params.id, this.props.match.params.guid)
                    .then(({ data: comments }: any) => {
                        this.props.setUILoaderState(false);
                        if (Array.isArray(comments.data)) {
                            this.setState({
                                ...this.state,
                                commentValue: RichTextEditor.createValueFromString('', 'html'),
                                comments: comments.data,
                                commentDescription: ''
                            })
                        }
                    })
            })
    }

    deleteComment(curRef: any, id: number, guid: string) {
        curRef.props.setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: "Are you sure, you want to delete?"
            }
        )
        curRef.props.setModalState(true);
        curRef.props.asyncActionModal(() => {
            deleteWorkItemComments(id, guid)
                .then(() => {
                    curRef.props.setUILoaderState(true);
                    getWorkItemComments(+curRef.props.match.params.id, curRef.props.match.params.guid)
                        .then(({ data: comments }: any) => {
                            curRef.props.setUILoaderState(false);
                            if (Array.isArray(comments.data)) {
                                curRef.setState({ ...curRef.state, comments: comments.data, commentDescription: '' })
                            }
                        })
                })
        });
    }

    notifyUserByEmail() {
        if (this.state.isUserNotified === false) {
            this.props.setUILoaderState(true);
            const notifyUserData = Object.assign({}, {
                userId: this.state.workItem.assigneeId,
                // workItemId: +this.props.match.params.id
            })
            notifyUser(notifyUserData)
                .then((response: any) => {
                    this.props.setUILoaderState(false);
                    if (response['response'] && response['response']['status'] >= 400) {
                        this.props.setErrorMessage(
                            {
                                errorMessage: "Something Went Wrong",
                                errorMessageHeader: "Error Message"
                            }
                        )
                        this.props.setModalState(true);
                        this.props.asyncActionModal(() => this.props.setModalState(false));
                    } else {
                        this.setState({ isUserNotified: true })
                    }
                })
        }
    }

    deleteChildWorkItem(thisRef, workItemId, workItemGuId) {
        thisRef.props.setUILoaderState(true);
        const id: number = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? +thisRef.state.workItem.id : +thisRef.props.match.params.id;
        const guid: string = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? thisRef.state.workItem.guid : thisRef.props.match.params.guid;
        const depth = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? 0 : +thisRef.props.match.params.workItemId - 1;
        deleteWorkItemService(workItemId, workItemGuId)
            .then(response => {
                if (response && (typeof response.data === "undefined")) {
                    thisRef.props.setErrorMessage(
                        {
                            errorMessage: response.errorCause,
                            errorMessageHeader: `${response && response.response ? response.response.data.errorCause
                                : 'Something went wrong'}`
                        }
                    )
                    thisRef.props.setModalState(true);
                    thisRef.props.asyncActionModal(() => { setModalState(false) })
                }
                getChildWorkItem(id, guid)
                    .then((response: any) => {
                        if (response && response.response) {
                            thisRef.props.setErrorMessage(
                                {
                                    errorMessage: " ",
                                    errorMessageHeader: `${response && response.response ? response.response.data.errorCause
                                        : 'Something went wrong'}`
                                }
                            )
                            thisRef.props.setModalState(true);
                            thisRef.props.asyncActionModal(() => { setModalState(false) })
                        } else {
                            const childWorkItems = response.data.data;
                            thisRef.addDepth(childWorkItems, depth);
                            thisRef.setState({
                                workItem: {
                                    ...thisRef.state.workItem,
                                    childWorkItems: childWorkItems
                                }
                            }, () => {
                                thisRef.props.setUILoaderState(false);
                            })
                        }
                    })
            })
    }

    // editWorkItemChild(workItemId) {

    // }

    deleteAttachment(curRef, id: number, guid: string) {
        curRef.props.setErrorMessage(
            {
                errorMessage: " ",
                errorMessageHeader: "Are you sure, you want to delete?"
            }
        )
        curRef.props.setModalState(true);
        curRef.props.asyncActionModal(() => {
            curRef.props.setUILoaderState(true);
            deleteAttachments(id, guid)
                .then(() => {
                    getAttachmentsFromWorkItem(+curRef.props.match.params.id,
                        curRef.props.match.params.guid)
                        .then((response: any) => {
                            curRef.setState({
                                ...curRef.state,
                                workItem: {
                                    ...curRef.state.workItem,
                                    attachments: response.data.data
                                }
                            }, () => {
                                curRef.props.setModalState(false)
                                curRef.props.setUILoaderState(false);
                            })
                        })
                })
        });
    }

    setWorkItemName(value, thisRef) {
        thisRef.setState({
            ...thisRef.state,
            workItemName: value
        })
    }
    makeShowInputFalse(taskItem) {
        taskItem.forEach(obj => {
            obj['showAddItemInput'] = false
            this.makeShowInputFalse(obj.childWorkItems)
        });
    }
    setWorkItemForInput(item, items, thisRef) {
        const initValue = item["showAddItemInput"];
        thisRef.makeShowInputFalse(items);
        item["showAddItemInput"] = initValue;
        if (item.workItemTypeId !== 1) {
            item["showAddItemInput"] = !item["showAddItemInput"];
            thisRef.setState({
                ...thisRef.state,
                workItem: {
                    ...thisRef.state.workItem,
                    childWorkItems: items
                }
            })
        }
    }
    saveWorkItemParentIdGuId(id: number, guid: string | null, workItemTypeId: number, thisRef) {
        thisRef.setState({
            ...thisRef.state,
            parentWorkItemDetails: {
                id: id,
                guid: guid,
                workItemTypeId: workItemTypeId
            }
        })
    }
    addChildAndSubTask(event, thisRef) {
        event.preventDefault();
        thisRef.setState({
            inputDisabled: true
        }, () => {
            thisRef.props.setUILoaderState(true);
            const workItem = { ...thisRef.state.initialWorkItem };
            const formData = new FormData();
            const id: number = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? +thisRef.state.workItem.id : +thisRef.props.match.params.id;
            const guid: string = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? thisRef.state.workItem.guid : thisRef.props.match.params.guid;
            const depth = (thisRef.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) ? 0 : +thisRef.props.match.params.workItemId - 1;
            formData.set("Description", workItem.description);
            formData.set("Name", thisRef.state.workItemName);
            formData.set("StartDate", moment(workItem.startDate).format("MMM D YYYY"));
            formData.set("WorkItemStatusId", workItem.status.id);
            formData.set("WorkItemPriorityId", workItem.priority.id);
            formData.set("WorkItemVisibilityId", workItem.visibility.id);
            formData.set("UpdatedBy", workItem.updatedBy);
            formData.set("CreatedBy", workItem.createdBy);

            if (thisRef.state.parentWorkItemDetails.id) {
                const { id, guid, workItemTypeId } = thisRef.state.parentWorkItemDetails;
                formData.set("ParentWorkItemId", `${id}`)
                formData.set("ParentWorkItemGuid", `${guid}`)
                formData.set("WorkItemTypeId", `${workItemTypeId - 1}`);
            } else if (this.props.match.params.isTemplate === "true" || +thisRef.props.match.params.id === 0) {
                const { id, guid, workItemTypeId } = thisRef.state.workItem;
                formData.set("ParentWorkItemId", `${id}`)
                formData.set("ParentWorkItemGuid", `${guid}`)
                formData.set("WorkItemTypeId", `${workItemTypeId - 1}`);
            } else {
                formData.set("ParentWorkItemId", `${thisRef.props.match.params.id}`)
                formData.set("ParentWorkItemGuid", `${thisRef.props.match.params.guid}`)
                formData.set("WorkItemTypeId", `${depth}`);
            }
            sendWorkItemForm(formData).then((response: any) => {
                thisRef.props.setUILoaderState(false);
                getChildWorkItem(id, guid)
                    .then(response => {
                        if (response && response.data) {
                            const childWorkItems = response.data.data;
                            thisRef.addDepth(childWorkItems, depth);
                            thisRef.setState({
                                ...this.state,
                                inputDisabled: false,
                                isSaveDisabled: false,
                                workItem: {
                                    ...thisRef.state.workItem,
                                    childWorkItems: childWorkItems
                                },
                                workItemName: ''
                            }, () => {
                                (document.getElementById('workitem-input') as HTMLInputElement).value = '';
                                if ((document.getElementById('workitem-input-task') as HTMLInputElement) !== null)
                                    (document.getElementById('workitem-input-task') as HTMLInputElement).value = '';
                                if ((document.getElementById('workitem-input-subtask') as HTMLInputElement) !== null)
                                    (document.getElementById('workitem-input-subtask') as HTMLInputElement).value = '';
                            })
                        }
                    })
            });
        })
    }

    SaveTemplate = () => {

        const { template, workItem } = this.state;
        const templateForm = {
            templateTypeId: 5,
            workItemTemplate: {
                ...template,
                createdBy: +(localStorage.getItem('userId') as any),
                updatedBy: +(localStorage.getItem('userId') as any),
                workItemTypeId: workItem.workItemTypeId,
                workItem: { ...workItem, workItemStatusId: workItem.status.id, userId: workItem.assigneeId, projectId: this.state.workItem.project.id, workItemVisibilityId: this.state.workItem.visibility.id }
            }
        };

        saveTemplate(templateForm, 'PUT').then(() => {
            this.setState({
                isSaveDisabled: true
            })
        });

    }

    SaveAsTemplate(templateData, thisef) {
        const { categoryId, subcategoryId, templateTypeId,
            createdBy, name, statusId, updatedBy, workItem,
            workItemTypeId } = templateData.workItemTemplate;

        const template = {
            name: templateData.workItemTemplate.name,
            guid: workItem.guid
        };

        const pid = thisef.state.workItem.project.id === 0 ? null : thisef.state.workItem.project.id;
        const endDate = thisef.state.workItem.endDate === "" ? null : thisef.state.workItem.endDate;

        const templateForm = {
            ...templateData,
            templateTypeId: 5,
            workItemTemplate: {
                categoryId,
                subcategoryId,
                templateTypeId: 5,
                createdBy: +(localStorage.getItem('userId') as any),
                updatedBy: +(localStorage.getItem('userId') as any),
                name,
                statusId,
                workItem: { ...thisef.state.workItem, endDate, watchers: thisef.state.watchers, projectId: pid, workItemVisibilityId: thisef.state.workItem.visibility.id },
                workItemTypeId: +thisef.props.match.params.workItemId
            }
        }

        saveTemplate(templateForm)
            .then(() => {
                thisef.setTemplateModal(thisef);
                getTemplateList().then(response => {
                    const templateGuid = template.name;
                    const tData = response && response.filter(temp => temp.name === templateGuid)[0];
                    thisef.editTemplate(tData);
                })
            });
    }

    editTemplate = (template) => {
        const depthValue = 3;
        const { id, guid, name } = template;
        this.props.history.push(`${Routes.ADD_NEW_WORK_Item}/true/${depthValue}/${id}/${guid}/null/null/true/${name}`);
        this.initialSetup();
    }

    setTemplateModal(thisRef) {
        thisRef.setState({
            ...thisRef.state,
            showTemplateModal: !thisRef.state.showTemplateModal
        });
    }

    setFillTemplateModal(thisRef) {
        thisRef.setState({
            ...thisRef.state,
            showFillTemplateModal: !thisRef.state.showFillTemplateModal,
            showChildTasks: true
        });
    }
    acceptTemplate(isActive) {
        const fixedTemplateId = 5;
        const { id, guid, statusId } = this.state.template;
        const changeTemplateValue: changeStatusTemplateInterface = Object.assign({}, {
            isActive: isActive,
            templateGuid: guid,
            templateId: id,
            templateTypeId: fixedTemplateId
        });
        TemplateStatusService(changeTemplateValue)
            .then(() => {
                this.props.history.push('/tasklist/4')
            })
    }
    render() {

        const { filterValue, workItem, errors, watchers, initialWorkItem, isSaveDisabled } = this.state;

        const dropDownStatus = [`${workItem.status.name}`].map((data, index) => <DropdownButton
            className="align-dropdown assign-title-center dropdown-font"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={workItem.status.name}
            onBlur={() => this.handleOnBlur('Status')}
        >
            {[WorkItemStatusEnum[WorkItemStatusEnum.Assigned],
            WorkItemStatusEnum[WorkItemStatusEnum.Closed],
            WorkItemStatusEnum[WorkItemStatusEnum.Completed],
            WorkItemStatusEnum[WorkItemStatusEnum.InProgress],
            WorkItemStatusEnum[WorkItemStatusEnum.New]].map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => this.setDropDownValue('status', data)}
                >
                    {data}
                </Dropdown.Item>
            })}
        </DropdownButton>);
        const dropDownPriority = [`${workItem.priority.name}`].map((data, index) => <DropdownButton
            className="align-dropdown assign-title-center dropdown-font"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={workItem.priority.name}
            onBlur={() => this.handleOnBlur('Priority')}
        >
            {[PriorityEnum[PriorityEnum.Low],
            PriorityEnum[PriorityEnum.Normal],
            PriorityEnum[PriorityEnum.High]].map((data, index) => {
                return <Dropdown.Item
                    key={index}
                    eventKey={`${index + 1}`}
                    onClick={() => this.setDropDownValue('priority', data)}
                >
                    {data}
                </Dropdown.Item>
            })}
        </DropdownButton>);
        return (<Container>
            <FillTemplateModal
                showModal={this.state.showFillTemplateModal}
                setFillTemplateModal={this.setFillTemplateModal}
                thisRef={this}
                getTemplateDataById={this.getTemplateDataById}
                {...this.props}
            />
            <SaveAsTemplateModal
                showModal={this.state.showTemplateModal}
                workItem={this.state.workItem}
                SaveAsTemplate={this.SaveAsTemplate}
                setTemplateModal={this.setTemplateModal}
                thisRef={this}
            />
            <AddWatcherModal
                showModal={this.state.showWatcherModal}
                setModalShow={this.addWatcherPopUp}
                options={this.state.userList}
                selectedValues={this.state.watchers}
                propertyName={'name'}
                currRef={this}
                onHide={() => this.closeModal()}
                modalType={this.state.modalType}
            />
            <RecurrenceModal
                showModal={this.state.showRecurrenceModal}
                currRef={this}
                onHide={this.closeRepeatModal}
                setRepeatState={this.state.setRepeatState}
                workItemId={this.state.workItem.id}
                thisRef={this}
                startDate={this.state.workItem.startDate}
                goToTaskList={() => this.props.history.push('/tasklist/1')}
            />
            <Row id="top-bar" className={this.props.match.params.isTemplate !== "false" ? "edit" : ""}>
                <Col md={{ span: 3 }} lg={{ span: 3 }} sm={{ span: 3 }}>
                    <span id="work-item-text">
                        <h5>
                            {
                                this.props.match.params.isTemplate === "true" ? 'Template' :
                                    +this.props.match.params.workItemId === 3 ? 'Work Item' : 'Task'
                            }
                        </h5>
                    </span>
                </Col>
                <div className="right-holder">
                    {
                        this.props.match.params.isTemplate === "false" ? <Col md={{ span: 2 }}
                            lg={{ span: 2, offset: 3 }} sm={{ span: 3 }}
                            id='fill-button-col' className="text-center">
                            <ButtonComponent
                                style={{ fontSize: '14px', width: '150px' }}
                                name={ButtonName.FILL_FORM_TEMPLATE}
                                onClick={() => this.setFillTemplateModal(this)} />
                        </Col> : null
                    }
                    {
                        this.props.match.params.isTemplate === "false" ? <React.Fragment>
                            <Col md={{ span: 2 }} lg={{ span: 2 }} sm={{ span: 3 }}
                                className=" pr-0 save-btn-align pr-0">
                                <ButtonComponent
                                    style={{ fontSize: '14px', width: '80px' }}
                                    name={ButtonName.SAVE}
                                    disabled={
                                        // this.props.match.params.isEdit === "false" ? 
                                        JSON.stringify(this.state.initialWorkItem) === JSON.stringify(this.state.workItem)
                                    }
                                    onClick={() => this.postWorkItemFormData()} />
                            </Col>
                            <Col md={{ span: 2 }} lg={{ span: 2 }} sm={{ span: 2 }}
                                className="align-button cancel-btn-align pl-0">
                                <ButtonComponent
                                    style={{ fontSize: '14px', width: '80px' }}
                                    name={ButtonName.CANCEL}
                                    onClick={() => {
                                        this.props.history.push('/tasklist/1')
                                    }} />
                            </Col>
                        </React.Fragment> : ( this.state.isAdmin && <React.Fragment>
                                <Col md={{ span: 2, offset: 5 }} lg={{ span: 2 }} sm={{ span: 3 }}
                                    className=" pr-0 save-btn-align pr-0">
                                    <ButtonComponent
                                        style={{ fontSize: '14px', width: '80px' }}
                                        name={"Accept"}
                                        disabled={
                                            // this.props.match.params.isEdit === "false" ? 
                                            JSON.stringify(this.state.initialWorkItem) === JSON.stringify(this.state.workItem)
                                        }
                                        onClick={() => this.acceptTemplate(true)} />
                                </Col>
                                <Col md={{ span: 2 }} lg={{ span: 2 }} sm={{ span: 2 }}
                                    className="align-button cancel-btn-align pl-0">
                                    <ButtonComponent
                                        style={{ fontSize: '14px', width: '80px' }}
                                        name={"Reject"}
                                        onClick={() => {
                                            this.acceptTemplate(false)
                                        }} />
                                </Col>
                                <Col md={{ span: 2 }} lg={{ span: 2 }} sm={{ span: 2 }}
                                    className="align-button cancel-btn-align pl-0">
                                    <ButtonComponent
                                        style={{ fontSize: '14px', width: '80px' }}
                                        name={"Save"}
                                        disabled={
                                            isSaveDisabled
                                        }
                                        onClick={() => {
                                            this.SaveTemplate()
                                        }} />
                                </Col>
                            </React.Fragment>)
                    }
                    {this.props.match.params.isTemplate === "false" && <OverlayTrigger
                        trigger="click"
                        key={'bottom'}
                        placement={'bottom'}
                        rootClose
                        overlay={
                            <Popover id={`popover-positioned-${'bottom'}`}>
                                <Popover.Content>
                                    <ul id="popover-list">
                                        {
                                            this.state.workItem.isWatcherAssigned ? <li onClick={() => {
                                                this.deleteCurrentUserWatcher()
                                            }}>Unwatch</li> :
                                                <li onClick={() => {
                                                    this.setCurrentUserWatcher()
                                                }}>Watch Item</li>
                                        }
                                        <li onClick={() => {
                                            this.setState({ showRecurrenceModal: true })
                                        }}>
                                            Set Repeat
                                    </li>
                                        <li onClick={() => {
                                            this.setTemplateModal(this)
                                        }}>Save As Template</li>
                                    </ul>
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <span className="material-icons">
                            more_horiz
                        </span>
                    </OverlayTrigger>
                    }
                </div>
            </Row>
            <Row className="mt-5">
                <Col md={{ span: 8 }} sm={{ span: 12 }} lg={{ span: 9 }}>
                    {
                        this.props.match.params.isTemplate === 'true' ? <TemplateCard
                            name={this.state.template.name}
                            categoryId={this.state.template.categoryId}
                            subcategoryId={this.state.template.subcategoryId}
                        /> : null
                    }
                    <Card id="text-editor-form1"
                        className="shadow p-3 mb-5 bg-white rounded">
                        <Card.Body>
                            <Form>
                                <Row className="pl-3">
                                    {
                                        this.props.match.params.workItemId === "3" &&
                                            (this.props.match.params.parentWorkItemGuid === 'null') ||
                                            this.props.location.pathname === "/addworkItem/false/2/0/null/0/null/null"
                                            ? <React.Fragment>
                                                <Form.Label>Name*</Form.Label>
                                                <Form.Control className="set-input-width"
                                                    placeholder="Enter Name"
                                                    value={this.state.workItem.name}
                                                    required
                                                    onBlur={() => this.handleOnBlur('name')}
                                                    onChange={(event) => this.handleWorkItem('name', event)}

                                                />
                                                <span className="invalid-feedback">
                                                    {errors && errors.nameError}
                                                </span>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                {(this.state.parentWorkItemName ||  (this.props.match.params.parentWorkItemGuid !== 'null' && this.props.match.params.name !== "null") ) && <Col md={6} className="pl-0">
                                                    <Form.Control
                                                        className="set-input-width"
                                                        value={this.state.parentWorkItemName ||
                                                            this.props.match.params.name}
                                                        disabled
                                                        readOnly={
                                                            this.props.match.params.isTemplate === "true"
                                                        }
                                                    />
                                                </Col>
                                                }
                                                <Col className={`${(this.state.parentWorkItemName || ( this.props.match.params.parentWorkItemGuid !== 'null' && this.props.match.params.name !== "null") ) ? '' : 'no-padding'}`} md={6}>
                                                    <Form.Control className="set-input-width"
                                                        placeholder="Enter Name"
                                                        value={this.state.workItem.name}
                                                        required
                                                        onBlur={() => this.handleOnBlur('name')}
                                                        onChange={(event) => this.handleWorkItem('name', event)}
                                                        readOnly={
                                                            this.props.match.params.isTemplate === "true"
                                                        }
                                                    />
                                                    <span className="invalid-feedback">
                                                        {errors && errors.nameError}
                                                    </span>
                                                </Col>
                                            </React.Fragment>
                                    }
                                </Row>
                                <Row id="project-data" className="pl-3 mt-3 mb-3">
                                    <Col className="project-data pl-0" md={4} lg={3} sm={4}>
                                        <Form.Label>Status*</Form.Label>
                                        {
                                            this.props.match.params.isTemplate === "false" ?
                                                dropDownStatus : dropDownStatus
                                        }
                                        <span className=" mb-2 invalid-feedback">
                                            {errors && errors.StatusError ? errors.StatusError
                                                : null
                                            }
                                        </span>
                                    </Col>
                                    <Col className="project-data " md={4} lg={3} sm={4}>
                                        <Form.Label>Priority*</Form.Label>
                                        {this.props.match.params.isTemplate === "false" ?
                                            dropDownPriority : dropDownPriority
                                        }
                                        {errors && errors.PriorityError}
                                    </Col>
                                    <Col className="project-data align-self-end" md={4} lg={3} sm={4}>
                                        <Form.Label>Start Date*</Form.Label>
                                        <Form.Control
                                            type="date"
                                            required
                                            max={this.state.workItem.endDate}
                                            value={this.state.workItem.startDate}
                                            onBlur={() => this.handleOnBlur('startDate')}
                                            onChange={(event) => this.handleWorkItem('startDate', event)}
                                            className="project-data"

                                        />
                                        <span className=" mb-2 invalid-feedback">
                                            {errors && errors.startDateError}
                                        </span>
                                    </Col>
                                    <Col className="project-data align-self-end" md={4} lg={3} sm={4}>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            className="project-data"
                                            value={this.state.workItem.endDate}
                                            min={this.state.workItem.startDate}
                                            onChange={(event) => this.handleWorkItem('endDate', event)}
                                            type="date"

                                        />
                                        <span className="mb-2 invalid-feedback">

                                        </span>
                                    </Col>
                                    <Col className="project-data align-self-end pl-0" md={4} lg={3} sm={4}>
                                        <Form.Label>Project</Form.Label>
                                        {
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}
                                                    value={this.state.workItem.project.name}
                                                    id="dropdown-custom-components">
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as={CustomMenu} drop={"down"}>
                                                    {this.state.projectList.map((data, index) => {
                                                        return <Dropdown.Item
                                                            key={index}
                                                            eventKey={`${index + 1}`}
                                                            onClick={() => this.setDropDownValue('project', data.projectId)}
                                                        >
                                                            {data.name}
                                                        </Dropdown.Item>
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }

                                    </Col>
                                    <Col className="project-data align-self-end" md={4} lg={3} sm={4}>
                                        <Form.Label>Visibility*</Form.Label>
                                        {
                                            <DropdownButton
                                                className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                key={"secondary"}
                                                id={`dropdown-variants-secondary`}
                                                variant={"secondary"}
                                                title={this.state.workItem.visibility.name}
                                            >
                                                {[visibilityEnum[visibilityEnum.External],
                                                visibilityEnum[visibilityEnum.Internal]
                                                ].map((data, index) => {
                                                    return <Dropdown.Item
                                                        key={index}
                                                        eventKey={`${index + 1}`}
                                                        onBlur={() => this.handleOnBlur('Visibility')}
                                                        onClick={() => this.setDropDownValue('visibility', data)}
                                                    >
                                                        {data}
                                                    </Dropdown.Item>
                                                })}
                                            </DropdownButton>
                                        }
                                    </Col>
                                </Row>
                                <Form.Label className="mt-3 pl-0">Description</Form.Label>
                                <MyStatefulEditor
                                    onChange={this.setDescription}
                                    curRef={this}
                                    value={this.state.descriptionValue}
                                />
                            </Form>
                        </Card.Body>
                    </Card>
                    {
                        this.props.match.params.workItemId === "1" ? null :
                            (this.props.match.params.isEdit === "true" || this.state.showChildTasks ) && <Card
                                className="mt-5 shadow p-3 mb-5 bg-white rounded"
                                id="text-editor-form1">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <span className="ml-3">
                                                Child task
                                    </span>
                                        </Col>
                                        {
                                            !this.state.showChildTasks && <Col className="align-last">
                                                <ButtonComponent
                                                    name={ButtonName.ADD_CHILD_TASK}
                                                    style={{ width: '130px', fontSize: '14px' }}
                                                    onClick={(event) => this.navigateToChildTask()}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <div className="mt-3">
                                        <ChildWorkItemList
                                            list={this.state.workItem.childWorkItems}
                                            deleteChildWatcher={this.deleteChildWatcher}
                                            addChildWatcher={this.addChildWatcher}
                                            deleteChildWorkItem={this.deleteChildWorkItem}
                                            addChildAndSubTask={this.addChildAndSubTask}
                                            setWorkItemName={this.setWorkItemName}
                                            readOnly = { !this.state.showChildTasks }
                                            setWorkItemForInput={this.setWorkItemForInput}
                                            saveWorkItemParentIdGuId={this.saveWorkItemParentIdGuId}
                                            thisRef={this}
                                        />
                                        {
                                            !this.state.showChildTasks && <Row className="mt-2">
                                                <div className="add-workItem-button">
                                                    <Form className='edit-form aaa' onSubmit={(event) => this.addChildAndSubTask(event, this)}>
                                                        <div className='input-holder'>
                                                            <input id="workitem-input"
                                                                disabled={this.state.inputDisabled}
                                                                onChange={(event) => this.setWorkItemName(event.target.value, this)}
                                                                onClick={() => {
                                                                    this.saveWorkItemParentIdGuId(0, null, 3, this)
                                                                }}
                                                                placeholder="Add Task" />
                                                            <button type="submit" className="span-item">
                                                                <i className="material-icons icon-style"> arrow_right </i>
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Row>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                    }
                    {
                        this.props.match.params.isTemplate === "false" ? <Card className="mt-5 shadow p-3 mb-5 bg-white rounded" id="text-editor-form1">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <span>
                                            Attachments
                                         </span>
                                    </Col>
                                    <Col className="align-last">
                                        <input id="file-input"
                                            multiple
                                            className="file-input"
                                            type="file"
                                            onChange={(event) => this.uploadFilesData(event)}
                                        />
                                        <ButtonComponent
                                            name={ButtonName.UPLOAD_FILE}
                                            style={{ width: '100px', fontSize: '14px' }}
                                            onClick={(event) => event?.preventDefault()}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Attachments
                                        attachments={this.state.workItem.attachments}
                                        deleteAttachment={this.deleteAttachment}
                                        curRef={this}
                                        isEdit={this.props.match.params.isEdit}
                                    />

                                </Row>
                            </Card.Body>
                        </Card> : null
                    }

                    {
                        this.props.match.params.isEdit === "true" ? <div className="mt-5">
                            {
                                this.props.match.params.isTemplate === "false" ? <Tab.Container
                                    id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="tabs" className="set-nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">
                                                Internal Comments {this.state.totalComments > 0 && <Badge variant="secondary">{this.state.totalComments}</Badge>}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content id="comments-tab shadow p-3 mb-5 bg-white rounded">
                                        <Tab.Pane eventKey="first">
                                            <Comments
                                                commentList={this.state.comments}
                                                setCommentDescription={this.setCommentDescription}
                                                curRef={this}
                                                deleteComment={this.deleteComment}
                                                value={this.state.commentValue}
                                            />
                                            <Row className="mt-4 mb-3">
                                                <Col >
                                                    <div className="button-move-right pr-3">
                                                        <ButtonComponent
                                                            name={ButtonName.SUBMIT}
                                                            onClick={() => this.postComments()}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container> : null
                            }
                        </div>
                            : null}
                </Col>
                <Col md={{ span: 4 }} sm={{ span: 12 }} lg={{ span: 3 }}>
                    <Card className="shadow p-3 mb-5 bg-white rounded">
                        <Card.Body>
                            <h5 className="assign-title-center">Assignee</h5>
                            <div className="assign-title-center mb-3">
                                <img id="avatar" src={NoImg}></img>
                            </div>
                            {
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle}
                                        value={this.state.workItem.assigneeName}
                                        id="dropdown-custom-components">
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} drop={"down"}>
                                        {this.state.userList.map((data, index) => {
                                            return <Dropdown.Item
                                                key={index}
                                                eventKey={`${index + 1}`}
                                                onClick={() => this.applicationFilter(data)}
                                            >
                                                {data.firstName + " " + data.lastName}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }

                            {
                                ( this.props.match.params.isEdit === "true" || this.state.showChildTasks ) && <React.Fragment>
                                    <div className="mt-2 assign-title-center">
                                        <span className="material-icons" onClick={() => {
                                            this.notifyUserByEmail()
                                        }}>
                                            {
                                                !this.state.isUserNotified ? "mark_email_unread"
                                                    : "mark_email_read"
                                            }
                                        </span>
                                        <hr />
                                        <div>
                                            <h6>Watchers</h6>
                                        </div>
                                    </div>
                                    <ListGroup id="list-top" className="list-group-flush no-border pl-2 pr-2">
                                        {
                                            watchers.map(watcher => {
                                                return <ListGroupItem className="no-border set-padding">
                                                    <span>{watcher.watcherName.length > 12 ? watcher.watcherName.substring(0, 10) + '...'
                                                        : watcher.watcherName
                                                    }
                                                    </span>
                                                    {!this.state.showChildTasks && 
                                                        <span
                                                            onClick={() => this.deleteWatcher(watcher.id, watcher.guid)}
                                                            className="material-icons mat-icons-pos">
                                                            highlight_off
                                            </span>
                                                    }
                                                </ListGroupItem>
                                            })
                                        }
                                    </ListGroup>
                                    <Card.Body>
                                        {
                                            !this.state.showChildTasks && <div className="assign-title-center">
                                                <ButtonComponent
                                                    name={ButtonName.ADD_WATCHER}
                                                    onClick={() => this.openModal('watcher')}
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: "12px",
                                                        width: '100px'
                                                    }}
                                                />
                                            </div>
                                        }

                                        {
                                            +this.props.match.params.workItemId === 2 ||
                                                +this.props.match.params.workItemId === 1 ? <div className="assign-title-center mt-3">
                                                    <ButtonComponent
                                                        name={ButtonName.ASSIGN_COPIES}
                                                        onClick={() => this.openModal('Assign_Copies')}
                                                        style={{
                                                            textAlign: 'center',
                                                            fontSize: "12px",
                                                            width: '100px'
                                                        }}
                                                    />
                                                </div>
                                                :
                                                null
                                        }
                                    </Card.Body>
                                </React.Fragment>
                            }

                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>
        )
    }
}

const mapStateToProps = (messageObject: ErrorMessage) => {
    return messageObject;
};

const mapDispatchToProps = (dispatch) => ({
    setUILoaderState: (isLoading: boolean) => dispatch(setUILoaderState(isLoading)),
    setErrorMessage: (messageObject: ErrorMessage) => dispatch(setErrorMessage(messageObject)),
    setModalState: (modalState) => dispatch(setModalState(modalState)),
    asyncActionModal: (passedFunction) => dispatch(asyncActionModal(passedFunction))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWorkItemComponent);
