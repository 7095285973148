import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {ButtonComponent} from '../Components/Button/Button';
import './Modal.css';
import {resolveModalPromise, setModalState, asyncActionModal} from '../actions/UIAction';
import { connect } from 'react-redux';

function MessageModal(props) {
    const [awaiter, setawaiter] = useState<any>();

    function onOkClick(): void {
        props.onHide(false);
        props.onOk();
    }
      const {Errors: {errorMessage: {errorMessage, errorMessageHeader}}} = props;
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Warning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{errorMessageHeader ? errorMessageHeader: "Data will be lost"}</h4>
          <p>
            {
              errorMessage  ? errorMessage : "Unsave data will be lost, Do you want to continue?"
            }
          </p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => (props.onHide(false))}
            name ={'Cancel'}/>
        <ButtonComponent style = {{width: '80px'}}
            size = 'sm' onClick={() => onOkClick()}
            name ={'Ok'}/>
        </Modal.Footer>
      </Modal>
    );
  }

const mapStateToProps = (UIState, ApplicationAndUserGroup) => {
    return UIState;
};

const mapDispatchToProps = (dispatch) => ({
    asyncActionModal: (data) => dispatch(asyncActionModal(data)),
    setModalState: (data) => dispatch(setModalState(data))
  });

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
