import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { ButtonComponent } from '../../../../Components/Button/Button';
import { Category } from '../../../../Enum/CategoryEnum';
import { SubCategory } from '../../../../Enum/SubCategoryEnum';
import { FillTemplateModel } from '../../../../Models/AuthenticationModels/TemplateModels/FillTemplateModel';
import { getTemplateNameList } from '../../../../Services/Template/TemplateService';

interface DropDownList {
    id: number,
    name: string
}

export function FillTemplateModal(props) {
    const {showModal, selectedValues, currRef} = props;
    const [categoryList, setCategoryList] = useState<DropDownList[]>([]);
    const [subCategoryList, setSubCategoryList] = useState<DropDownList[]>([]);
    const [templateList, setTemplateList] = useState<any[]>([]);
    const [fillTemplate, setFillTemplate] = useState(new FillTemplateModel());
    const [workItemTypeName, setWorkItemTypeId] = useState<string>('');

    useEffect(() => {
        const workItemType = +props.match.params.workItemId;
        workItemType === 3 ? setWorkItemTypeId('Work Item') : workItemType === 2 ? setWorkItemTypeId(
            'Task') : setWorkItemTypeId('Sub Task');
        getTemplateNameList(workItemType)
        .then(response => {
            if(Array.isArray(response?.data?.data)) {
                const templateList = response?.data?.data.filter(template => {
                    return template.statusId === 1;
                });
                console.log(templateList);
                setTemplateList(templateList);
            }
        })

    }, []);

    const filterTemplates = (type, id) => {
        if(id !== 0) {
            const templateNameList = templateList.filter(template => {
                return template[type] === id;
            });
            setTemplateList(templateNameList);
        }
    }

    const setTemplate = (type, value) => {
        switch(type) {
            case 'category': {
                setFillTemplate(
                    {
                        ...fillTemplate,
                            categoryId: value,
                            category: {
                                id: value,
                                name: Category[value] 
                            }
                    }
                );
                filterTemplates('categoryId', value);
                break;
            }
            case 'sub category': {
                setFillTemplate(
                    {
                        ...fillTemplate,
                            subcategoryId: value,
                            subCategory: {
                                id: value,
                                name: SubCategory[value] 
                            }
                    }
                );
                filterTemplates('subcategoryId', value);
                break;
            }
            case 'Template': {
                setFillTemplate(
                    {
                        ...fillTemplate,
                            template: {
                                id: value.id,
                                name: value.name,
                                guid: value.guid
                            }
                    }
                );
                break;
            }
        }
    }

    useEffect(() => {
        let categories: any[] = [];
        let subCategories: any[] = [];
        const categoryLength: number = Object.values(Category).length;
        let currentLength: number = 0;
        for(const key in Category) {
            if(currentLength < categoryLength && currentLength >= categoryLength/2)
            categories.push({
                id: +Category[key],
                name: key
            });
            currentLength++;
        }
        const subCategoryLength: number = Object.values(SubCategory).length;
        currentLength = 0;
        for(const key in SubCategory) {
            if(currentLength < subCategoryLength && currentLength >= subCategoryLength/2)
            subCategories.push({
                id: +SubCategory[key],
                name: key
            });
            currentLength++;
        }
        setCategoryList(categories);
        setSubCategoryList(subCategories);
    }, [])
    const category = [`Category`].map((data, index) =>  <Col key ={index} 
    className = "mt-3"
    md={12}>
    <DropdownButton
        className = "align-dropdown"
        key={"secondary"}
        id={`dropdown-variants-secondary`}
        variant={"secondary"}
        title={`${fillTemplate.category.name || 'Category'}`}
    >
    {categoryList.map((data, index) => {
        return <Dropdown.Item
            key = {index}
            eventKey = {`${index + 1}`}
            onClick = {() => {setTemplate('category', data.id);}}
            >
        {data.name}
        </Dropdown.Item>
    })}
    </DropdownButton>
    </Col>)
        const subCategory = [fillTemplate.subCategory.name].map((data, index) =>  <Col key ={index} 
        className = "mt-3"
        md={12}>
        <DropdownButton
            className = "align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={fillTemplate.subCategory.name || 'Sub Category'}
        >
        {subCategoryList.map((data, index) => {
            return <Dropdown.Item
                key = {index}
                eventKey = {`${index + 1}`}
                onClick = {() => {setTemplate('sub category', data.id)}}
                >
            {data.name}
            </Dropdown.Item>
        })}
        </DropdownButton>
        </Col>)
    const TemplateName = [`${fillTemplate.template.name}`].map((data, index) =>  <Col key ={index} 
        className = "mt-3"
        md={12}>
        <DropdownButton
            className = "align-dropdown"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            variant={"secondary"}
            title={`${fillTemplate.template.name || 'Template Name'}`}
        >
        {templateList.map((data, index) => {
            return <Dropdown.Item
                key = {index}
                eventKey = {`${index + 1}`}
                onClick = {() => setTemplate('Template', data)}
                >
            {data.name}
            </Dropdown.Item>
        })}
        </DropdownButton>
        </Col>)
    return (
        <Modal
          show={showModal}
          onHide={props.onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton onClick = {() => {
              props.setFillTemplateModal(props.thisRef)
          }}>
            <Modal.Title>Fill From Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <Row>
                    <Col md = {6} >
                        <Form.Control 
                        readOnly
                        value = {workItemTypeName}
                        className = "fill-template-name"
                    />
                    </Col>
                </Row>
                <Row>
                    <Col md = {6}className = "pl-0">
                        {category}
                    </Col>
                    <Col md = {6} className = "pr-0">
                        {subCategory}
                    </Col>
                        {TemplateName}
                </Row>
            </Form>
        </Modal.Body>
            <Modal.Footer>
            <ButtonComponent style = {{width: '80px'}}
            size = 'sm' 
            onClick={() => {
                props.setFillTemplateModal(props.thisRef)
            }}
            name ={'Cancel'}/>
            <ButtonComponent style = {{width: '80px'}}
            size = 'sm' 
            onClick={() => {
                props.getTemplateDataById(fillTemplate.template?.guid, props.thisRef);
                console.log(fillTemplate)
            }}
            name ={'Ok'}
            disabled = {fillTemplate.template.id === 0}
            />
          </Modal.Footer>
        </Modal>
    );
  }