import React, { useState, useEffect } from 'react';
import "./Header.css";
import PopUpSelect from '../PopOver/PopUpSelect';
import PopUpControl from '../PopOver/PopUpControl';
import PopUp from '../PopOver/PopUp';
import { AuthState } from '../../Interfaces/AuthConfiginterface';
import { connect } from 'react-redux';
import { Color, Logo } from '../../Constants';
import {
  Tabs, Tab, Container, Row,
  Col, DropdownButton,
  Dropdown
} from 'react-bootstrap';
import { ApplicationAndUserList } from '../../Interfaces/applicationAndUserGroupInterface';
import { UIState } from '../../Interfaces/UIStateInterface';

interface Props {
  AuthState: AuthState;
  ApplicationAndUserGroup: ApplicationAndUserList;
  UIState: UIState;
};

function Header(props: Props) {
  const role = localStorage.getItem('role');
  const [isSettingOpen, setSettingOpen] = useState(false);
  let setColor: any = { backgroundColor: '#6C6F70' };
  const currentSettingState = (state) => {
    setSettingOpen(!state);
  }

  useEffect(() => {
    setColor = isSettingOpen ? { backgroundColor: Color.RED } : { backgroundColor: Color.GREY };
  }, [isSettingOpen]
  );
  const { UIState: { headerHeading }, AuthState: { userName } } = props;

  const settingDropDown = <PopUpControl
    detect="click"
    offsetX="centre"
    returnState={currentSettingState}
    isOpen={isSettingOpen}
    offsetY={12}>
    <PopUpSelect>
      <div style={{ backgroundColor: isSettingOpen ? Color.RED : Color.GREY }} className="settingDiv">
        <i style={{ marginLeft: isSettingOpen ? '10px' : '10px' }}
          className="material-icons setting-icon">settings</i>
        {
          isSettingOpen && <span className={`${'triangleDown'}`}></span>
        }
      </div>
    </PopUpSelect>
    <PopUp setSettingOpen={setSettingOpen} />
  </PopUpControl>

  return (
    <React.Fragment>
      <div className="flex-container">
        <div id="optimusLogo">
          <img id="logoOptimus" src={Logo.HEADER_LOGO} width="100" height="60" />
        </div>
        <span className="page-title">
          <h3 className="mt-2 pt-1">{headerHeading}</h3>
        </span>
        <span className="current-user">
          {props.AuthState.userName}
        </span>
        {settingDropDown}
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (authState) => {
  return authState;
};

export default connect<Props>(mapStateToProps, {})(Header as any);
