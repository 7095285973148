import React from "react";
import { useState } from "react";
import { DropdownButton, FormControl } from "react-bootstrap";
import './SearchSelect.css';

export const CustomToggle = React.forwardRef(({ children, onClick, value }: any, ref: any) => (
    <div
            className = "align-dropdown assign-title-center dropdown-font search-select"
            key={"secondary"}
            id={`dropdown-variants-secondary`}
            // variant={"secondary"}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            title={value}>      
            {value?.length < 16 ? value : `${value?.substring(0, 14)}...`}
    </div>
  ));
  
export const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
      const [value, setValue] = useState('');
      return (
        <div
          ref={ref}
          style={style}
          className={className + " serach-box"}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Search User..."
            onChange={(e) => {setValue(e.target.value)}}
            value={value}
          />
          <ul className="list-unstyled set-box">
            {React.Children.toArray(children).filter(
              (child: any) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );