import React from 'react';
import "./SideNav.css";

interface Props {
    children?: React.ReactNode[];
    imageUrl: string;
    name: string;
    clickSideNav: (id: number, name:string) => void;
    id: number;
    activeMenu: number;
}

export function NavContent({children, imageUrl, name, clickSideNav, id, activeMenu}: Props) {
        return (
            <div id = {`navContent${id}`} className = {`${activeMenu === id?'nav-content-on-click':'nav-content'}`}
                onClick = {() =>clickSideNav(id, name)}>
                <div className = "nav-items">
                    <span className = "span-item">
                          <i className="material-icons icon-style"> {imageUrl}
                          </i>
                    </span>
                    <span className = "name">
                        {name}
                    </span>
                </div>
            </div>
        )
}

export default NavContent;
