import React, { useState } from 'react';

import { TemplateStatus } from '../../Enum/TemplateStatusEnum';
import { SubCategory } from '../../Enum/SubCategoryEnum';
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import Button from '../Button/Button';
import { Category } from '../../Enum/CategoryEnum';

const Item = (props) => {

  const { data, index, isAdmin } = props;
  const [show, setShow] = useState(false);

  return <tr key={index}>
    <td className="tableClickable test">
      <span onClick={() => props.editTemplate(data)} >{data.name}</span>
    </td>
    <td>
      <span>{Category[data.categoryId]}</span>
    </td>
    <td>
      <span>{SubCategory[data.subcategoryId]}</span>
    </td>
    <td>
      <span>{data.creatorName}</span>
    </td>
    <td>
      <span>{TemplateStatus[+data.statusId]}</span>
    </td>
    <td>
      {isAdmin && <OverlayTrigger
        trigger="click"
        key={'right'}
        rootClose
        placement={'right'}
        overlay={
          <Popover id={`popover-positioned-${'right'}`}>
            <Popover.Content>
              <ul id="popover-list">
                <li onClick={() => { setShow(false); props.deleteTemplate(data.id, data.guid) }}>
                  Delete
                          </li>
                <li onClick={() => props.editTemplate(data)}>
                  Edit
                            </li>
                {data.statusId === 1 ? <li onClick={() => props.createItem(data, 'true')}>
                  Create Work Item
                            </li> : null}
                {data.statusId === 3 ? null
                  :
                  <li onClick={() => { setShow(false); props.changeTemplateStatusById(data, false) }}>
                    Reject
                            </li>
                }
                {
                  data.statusId === 1 ? null
                    :
                    <li onClick={() => { setShow(false); props.changeTemplateStatusById(data, true) }} >
                      Accept
                            </li>
                }
              </ul>
            </Popover.Content>
          </Popover>
        }
      >
        <span onClick={() => setShow(true)} className="material-icons">
          more_horiz
                </span>
      </OverlayTrigger>
      }
    </td>
  </tr>
};

export default Item;