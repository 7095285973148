import axios from '../Interceptor';
import { API_URL } from '../Constants';
import {AxiosError} from 'axios';
import { changeStatusTemplateInterface } from '../../Containers/TaskList/MyItems/Template';

const makeFilterParameters = (filterParams) => {
    const param: any = {};
    if(filterParams.category.id !== 0) {
        param.CategoryId = filterParams.category.id;
    } if(filterParams.subCategory.id !== 0) {
        param.SubcategoryId = filterParams.subCategory.id;
    } if(filterParams.status.id !== 0) {
        param.StatusId = filterParams.status.id;
    } if(filterParams.searchKeyWord !== "") {
        param.searchKeyWord = filterParams.searchKeyWord;
    }
    return param;
}

export const getTemplateList = async(filterParams?: any) => {
    try {
        
        let baseUrl: string;
        let response: any;
        let param: any;        
        if(filterParams !== undefined) {
            param = makeFilterParameters(filterParams);
        }
        baseUrl = `${API_URL}/templates`
        const userId = localStorage.getItem('userId') as any;
        response = await axios.get(baseUrl, {params: {
            ...param,
            userId: +userId,
            templateTypeId: 5
        }});
        const data = response.data.data;
        if (response.status >= 400) {
            console.log(data.errors);
        }
        return data;
    } catch(e) {
        console.log(e);
    }
};

export const deleteTemplateService = async(id: number, guid: string) => {
    try {
        const userGuid = localStorage.getItem('guid');
        const params: any = Object.assign({}, {
            templateTypeId: 5
        });
        const baseUrl = `${API_URL}/templates/${id}/${guid}`;
        const response: any = await axios.delete(baseUrl, {params});
        return response;
    } catch(e) {
        console.log(e);
    }
}

export const TemplateStatusService = async(changeStatusTemplate: changeStatusTemplateInterface) => {
    try {
        const userId  = localStorage.getItem('userId') as any;
        const params: any = Object.assign({}, {
            loggedUserId: +userId
        });
        const baseUrl = `${API_URL}/templates/status`;
        const response: any = await axios.put(baseUrl, changeStatusTemplate,{params});
        return response;
    } catch(e) {
        console.log(e);
    }
};

export const saveTemplate = async(templateForm, method = 'POST') => {
    try {
        const userId  = localStorage.getItem('userId') as any;
        const params: any = Object.assign({}, {
            loggedUserId: +userId
        });
        const baseUrl = `${API_URL}/templates`;
        const response: any = method === 'PUT' ? await axios.put(baseUrl, templateForm,{params}) : await axios.post(baseUrl, templateForm,{params});
        return response;
    } catch(e) {
        console.log(e);
    }
};

export const getTemplateNameList = async(workItemTypeId) => {
    try {
        const userId  = localStorage.getItem('userId') as any;
        const params: any = Object.assign({}, {
            loggedUserId: +userId,
            workItemTypeId: workItemTypeId,
            templateTypeId: 5
        });
        const baseUrl = `${API_URL}/templates`;
        const response: any = await axios.get(baseUrl,{params});
        return response;
    } catch(e) {
        console.log(e);
    }
};

export const getTemplateById = async(templateGuId) => {
    try {
        const userId  = localStorage.getItem('userId') as any;
        const params: any = Object.assign({}, {
            loggedUserId: +userId,
            templateTypeId: 5
        });
        const baseUrl = `${API_URL}/templates/${templateGuId}`;
        const response: any = await axios.get(baseUrl,{params});
        return response;
    } catch(e) {
        console.log(e);
    }
};