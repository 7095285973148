import React from 'react'

import './Button.css'

import { Button } from 'react-bootstrap'

import { ButtonProps } from '../../Interfaces/ButtonPropsInterface'

export function ButtonComponent(props: ButtonProps) {
    return (
        <Button
            style={props.style}
            {...props}
            variant="dark"
            onClick={props.onClick}
        >
            {props.name}
        </Button>
    )
}

export default ButtonComponent
