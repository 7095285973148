import React, { useState, useEffect } from 'react'

import './App.css'
import Header from './Components/Header/Header'
import SideNav from './Components/SideNav/SideNav'
import Tabset from './Components/Tabset/Tabset'
import Dashboard from './Containers/Dashboard/Dashboard'
import Policies from './Containers/Policies/Policies'
import SupportTickets from './Containers/SupportTicket/SupportTickets'
import CreateSupportTicket from './Components/CreateSupportTicket/CreateSupportTicket'
import EditSupportTicket from './Components/EditSupportTicket/EditSupportTicket'

import { Switch, Route, RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'

import { setAuthData } from './actions/AuthenticationAction'
import { AuthState } from './Interfaces/AuthConfiginterface'
import { authProvider } from './Services/Authentication/AuthProvider'

import { connect } from 'react-redux'
import { ProtectedRoute } from './Utils/PrivateRoute'
import MessageModal from './Utils/Modal'
import { setModalState } from './actions/UIAction'
import { AuthenticationConstants } from './Services/Constants'
import Loader from './Components/Loader/Loader'
import { Routes, AppConstant } from './Constants'
import Report from './Containers/Report/Report'
import TaskContainer from './Containers/TaskList/TaskContainer'
import AddWorkItemComponent from './Containers/TaskList/MyItems/Create Work Item/AddWorkItemComponent'
import { ApplicationAndUserList } from './Interfaces/applicationAndUserGroupInterface'
import { UIState } from './Interfaces/UIStateInterface'
import Root from './Containers/Root/Root'
import ResourceManagerTool from 'Components/ResourceManagerTools/ResourceManagerTool'
import ResourceList from 'Components/ResourceManagerTools/ResourceList'
import ResourceMangerEdit from 'Components/ResourceManagerTools/ResourceMangerEdit'
import ProjectionPo from 'Components/Projection/ProjectionPo'
import ProjectionAdmin from 'Components/Projection/ProjectionAdmin'
import ProjectionContainer from 'Components/Projection/ProjectionContainer'
import AllocationContainer from 'Components/Allocation/AllocationContainer'
import ReportsContainer from 'Components/Reports/ReportsContainer'
interface Props {
    authState: {
        AuthState: AuthState
        ApplicationAndUserGroup: ApplicationAndUserList
        UIState: UIState
    }
    setAuthData: (data) => void
    setModalState: (data) => void
}

function App(props: RouteComponentProps<Props>) {
    const {
        authState: {
            UIState: { modalResolve },
        },
    } = props
    useEffect(() => {
        const accountObject: any = authProvider.getAccountInfo()
        const { userName } = accountObject[AppConstant.ACCOUNT]
        const { CLIENT_ID } = AuthenticationConstants
        const accessToken = localStorage.getItem(`msal.${CLIENT_ID}.idtoken`)
        const role = JSON.parse(
            JSON.stringify(localStorage.getItem(AppConstant.ROLE))
        )
        let authState: AuthState = {
            accessToken: accessToken || '',
            userId: localStorage.getItem(AppConstant.USER_ID) || 0,
            userName: userName,
            role: role || [],
            isAuthenticated: true,
        }
        props.setAuthData(authState)
    }, [])
    const {
        authState: { UIState: isLoading },
    } = props
    return (
        <div>
            {props.authState.UIState.isLoading && <Loader />}
            <Header />
            <MessageModal
                show={props.authState.UIState.modalState}
                onHide={props.setModalState}
                onOk={modalResolve}
                backdrop="static"
                keyboard={false}
            />
            <div className="container-contents">
                <SideNav />
                <div className="main-container">
                    <Switch>
                        <Route path={Routes.HOME} component={Root}></Route>
                        <Route
                            path={Routes.DASHBOARD}
                            component={Dashboard}
                        ></Route>
                        <Route
                            path={Routes.SUPPORT_TICKET}
                            component={SupportTickets}
                        ></Route>
                        <Route
                            path={Routes.CREATE_SUPPORT_TICKET}
                            component={CreateSupportTicket}
                        ></Route>
                        <Route
                            path={`${Routes.EDIT_SUPPORT_TICKET}/:currentTab/:id/:guid`}
                            component={EditSupportTicket}
                        ></Route>
                        <Route
                            exact
                            path={Routes.DEFAULT}
                            component={Root}
                        ></Route>
                        <Route
                            path={Routes.POLICIES}
                            component={Policies}
                        ></Route>
                        <Route
                            path={Routes.RESOURCE_MANAGER_TOOLS}
                            component={ResourceList}
                        ></Route>
                        <Route
                            path={Routes.RESOURCE_MANAGER_TOOLS_NEW}
                            component={ResourceManagerTool}
                        ></Route>
                        <Route
                            path={Routes.RESOURCE_MANAGER_EDIT}
                            component={ResourceMangerEdit}
                        ></Route>
                        <Route
                            path={Routes.PROJECTION_PO}
                            component={ProjectionPo}
                        ></Route>
                        <Route
                            path={Routes.PROJECTION_PO}
                            component={ProjectionAdmin}
                        ></Route>
                        <Route
                            path={Routes.PROJECTION_CONTAINER}
                            component={ProjectionContainer}
                        ></Route>
                        <Route
                            path={Routes.ALLOCATION_CONTAINER}
                            component={AllocationContainer}
                        ></Route>
                        <Route
                            path={Routes.REPORTS_CONTAINER}
                            component={ReportsContainer}
                        ></Route>
                        <ProtectedRoute
                            exact
                            path={Routes.ASSIGN}
                            component={Tabset}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            exact
                            path={`${Routes.APPLICATION_ROUTE}/:userGroupId`}
                            component={Tabset}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            exact
                            path={Routes.ANNOUNCEMENTS}
                            component={Report}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            exact
                            path={Routes.DASHBOARD_NEW}
                            component={Report}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            exact
                            path={Routes.REPORTS}
                            component={Report}
                        ></ProtectedRoute>
                        <ProtectedRoute
                            exact
                            path={`${Routes.TASK_LIST}/:tasklistId`}
                            component={TaskContainer}
                        />
                        <ProtectedRoute
                            exact
                            path={`${Routes.ADD_NEW_WORK_Item}/:isEdit/:workItemId`}
                            component={AddWorkItemComponent}
                        />
                        <ProtectedRoute
                            exact
                            path={`${Routes.ADD_NEW_WORK_Item}/:isEdit/:workItemId/:id/:guid/:parentWorkItemId/:parentWorkItemGuid/:isTemplate/:name`}
                            component={AddWorkItemComponent}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
//ResourceManagerTool  ResourceList
const mapStateToProps = (authState: AuthState, UIState) => {
    return { authState, UIState }
}

const mapDispatchToProps = (dispatch) => ({
    setAuthData: (data) => dispatch(setAuthData(data)),
    setModalState: (modalState) => dispatch(setModalState(modalState)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
