import ButtonComponent from 'Components/Button/Button'
import MyStatefulEditor from 'Components/Text-Editor/TextEditor'
import './ResourceManagerTool.css'
import { ButtonName, Routes } from 'Constants'
import moment from 'moment'
import React, { useEffect } from 'react'
import axios from "axios";
import { API_URL } from 'Services/Constants'
import { Multiselect } from 'multiselect-react-dropdown';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import {
    Badge,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    ListGroup,
    ListGroupItem,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
    Tab,
} from 'react-bootstrap'
import { useState } from 'react'
import { assignGroupsToUser, assignOrganisationToUser,
     createZohoUser, getGroups, getOrganisations, getProjects,
      getReportingManagers, getTeamId, sendResourceItemForm ,assignProjectToUser
    ,getUserDetails,
    updateUser} from 'Services/ResourceService/ResourceService'

interface State {
    filterValue: string
    isSaveDisabled: boolean
    inputDisabled: boolean
    isAdmin: boolean
    // userList: Array<any>;
    resourceItem: any
    errorMessage:string
    errorMessageZoho:string
    teamId:string
}


export enum resourceTypeEnum {
    Employee = 2,
    Contractor = 1,
}

// export enum accessLevelEnum {
//     Basic = 1,
//     Stakeholder = 2,
//      Visual_Studio_Subscriber=3
// }
let accessLevelArray=[
    "Basic",
    "Stakeholder",
    "Visual Studio Subscriber"
]
function ResourceMangerEdit(state: State) {
    const history = useHistory();
    state = {
        filterValue: 'Ramneek',
        isSaveDisabled: true,
        inputDisabled: false,
        isAdmin: false,
        errorMessage:"",
        errorMessageZoho:"",
        teamId:'',
        resourceItem: {
            firstName: '',
            lastName: '',
            email: '',
            personalEmail: '',
            phoneNum: '',
            employeeID:'',
            zohoEmployeeRecordId:'',

            resourceType: {
                id: 0,
                name: 'Resource Type',
            },
            reportingTo: {
                id: 0,
                name: 'Reporting To',
            },
            accessLevel: {
                id: 0,
                name: 'Access Level',
            },
            organisation: {
                id: 0,
                name: 'Organisation',
            },
            projectAssignedTo: {
                id: 0,
                name: 'Projects',
            },
           groupsAssigned:{
               id:[0],
               name:'Groups'
           }
           
        },
        // errors: null
    }
    const[resourceItemState,setResourceItem]= useState(state);
    const [groupList, setGroupList] :any= useState([]);
    const[groups,setGroups]= useState([]);
    const[projects,setProjects]= useState([]);
    const[reportingManagers,setReportingManagers]= useState([]);
    const[organisations,setOrganisations]= useState([]);
    const[teamsList,setTeamsList]:any= useState([]);
    const[teams,setTeams]= useState([]);
    const[zohoUserStatus,setZohoUserStatus]= useState("");
    const[azureUserStatus,setAzureUserStatus]= useState("");
    const [showAlert, setShowAlert]=useState(false);
    // const [errorMessage, setErrorMessage]=useState("");
    const [userDetails, setUserDetails]:any=useState();
    const[reportingManagerError,setReportingManagerError]=useState(["Not found"])
    const [uiLoader,setUiLoader]=useState(true);
    
    const setResourceObj=(userData)=>{
    let resourceItem = {
        ...resourceItemState.resourceItem,
        firstName: userData.userAD_Details.givenName,
        email:userData.userAD_Details.userPrincipalName,
        phoneNum:userData.userAD_Details.mobilePhone,
        personalEmail:userData.userAD_Details.mail,
        lastName:userData.userAD_Details.surname, 
        employeeID:userData.zohoUserAccount?userData.zohoUserAccount.employeeID:"",
        zohoEmployeeRecordId:userData.zohoUserAccount?userData.zohoUserAccount.zohoEmployeeRecordId:"",
        reportingTo:{
            id:userData.zohoUserAccount?userData.zohoUserAccount.reportingManagerRecordId:"",
            name:userData.zohoUserAccount?userData.zohoUserAccount.reportingManagerName:""},
        groupsAssigned:{
            id:userData.userAD_Details.assignedGroups}
    }
    setResourceItem({
        ...resourceItemState,
        resourceItem: resourceItem
    })
}

    const location = useLocation();
    useEffect(() => {

        getUserDetails(location.state.userId).then((response: any) => { 
            
             
            // setUserDetails(response.data);
            setResourceObj(response.data.data)
            setUiLoader(false)
           
       }).catch((error) => console.log(error, ' error'))
      
        getGroups().then((response: any) => {
           
              
            setGroups(response.data);
       }).catch((error) => console.log(error, ' error'))

    //    getProjects().then((response: any) => {
    //      setProjects(response.data.value);  
    //     })

        getReportingManagers().then((response: any) => {
           
            
            setReportingManagers(response.data);  
           }).catch((error) => console.log(error, ' error'))
           getOrganisations().then((response: any) => { 
               setOrganisations(response.data.dataProviders.msVssFeaturesMyOrganizationsDataProvider.organizations);  
           }).catch((error) => console.log(error, ' error'))

         
    }, [])

    
    
    
    

    
    const onSelect = (selectedList, selectedItem) => {
        setGroupList(selectedList);
      
        
    
    }
     
    const onRemove = (selectedList, removedItem) => {
        setGroupList(selectedList);
       
        
    }

    const onSelectTeams = (selectedList, selectedItem) => {
        setTeamsList(selectedList);
      
        
    
    }
     
    const onRemoveTeams = (selectedList, removedItem) => {
        setTeamsList(selectedList);
       
        
    }

    const onSelectProject = (selectedList, selectedItem) => {

           let resourceItem = {
                ...resourceItemState.resourceItem,
                projectAssignedTo: selectedList
            }
            setResourceItem({
                ...resourceItemState,
                resourceItem: resourceItem
            })

            
    
    }
     
    const onRemoveProject = (selectedList, removedItem) => {
        let resourceItem = {
            ...resourceItemState.resourceItem,
            projectAssignedTo: selectedList
        }
        setResourceItem({
            ...resourceItemState,
            resourceItem: resourceItem
        })
       
        
    }
    
    const postResourceItemFormData = () => {
        const { resourceItem } = resourceItemState
        
                        let userGroups:any=[];

                        for (let i = 0; i < groupList.length; i++) {
                            let groupPostObj:any={"id":`member_${groupList[i].id}_${location.state.userId}`,
                        "method":"POST","url":`/groups/${groupList[i].id}/members/$ref`,
                        "headers":{"Content-Type":"application/json"},"body":
                        {"@odata.id":`https://graph.microsoft.com/beta/directoryObjects/${location.state.userId}`}}
                                userGroups.push(groupPostObj);
                         }
                        
                        
                         let userGroupObject={
                            "requests":
                                userGroups
                            
                        }

        let updateUserObj={
            "userAD_Details": {    
                'displayName':resourceItem.firstName +' '+  resourceItem.lastName,
                "givenName": resourceItem.firstName,
                'userPrincipalName':resourceItem.email,
                "surname":resourceItem.lastName,
                'mobilePhone':resourceItem.phoneNum,
              },
              "userZohoAccountDetails": {
                "zohoEmployeeRecordId": resourceItem.zohoEmployeeRecordId,
                "employeeId": resourceItem.employeeID,
                "reportingManagerRecordId": resourceItem.reportingTo.id
                
              },
              
              "groupAssigned":{
              
                            "requests":
                                userGroups
                            
                        }
                                               
              }
        
        updateUser(updateUserObj).then((response: any) => {
                
                if(response.status=="200"){
                   
                    
                history.push(Routes.RESOURCE_MANAGER_TOOLS)
            }
             else{
                 setAzureUserStatus(" Some Error Occurred ")
             }
        })

        
    }

const setButtonStatus=()=>{
    if( !resourceItemState.resourceItem.firstName ||
     !resourceItemState.resourceItem.lastName ||
         !resourceItemState.resourceItem.email  || !resourceItemState.resourceItem.phoneNum  || (resourceItemState.resourceItem.reportingTo.id==0)
          ){
       return true
    }
}

   const handleResourceItem=(type: string, event)=> {
       
        let resourceItem;
        switch (type) {
            case 'firstName':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    firstName: event.target.value
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem
                })
                break;
            case 'lastName':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    lastName: event.target.value
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem
                })
                break;
            case 'email':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    email: event.target.value
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem
                })
                break;
            
                    case 'personalEmail':
                        resourceItem = {
                            ...resourceItemState.resourceItem,
                            personalEmail: event.target.value
                        }
                        setResourceItem({
                            ...resourceItemState,
                            resourceItem: resourceItem
                        })
                        break;
                        case 'phoneNum':
                            resourceItem = {
                                ...resourceItemState.resourceItem,
                                phoneNum: event.target.value
                            }
                            setResourceItem({
                                ...resourceItemState,
                                resourceItem: resourceItem
                            })
                            break;
                            case 'employeeID':
                                
                            resourceItem = {
                                ...resourceItemState.resourceItem,
                                employeeID: event.target.value
                            }
                            setResourceItem({
                                ...resourceItemState,
                                resourceItem: resourceItem
                            })
                            break;
                              
        }
        // setResourceItem({
        //     ...resourceItemState,isSaveDisabled: false
        // })
    }

   const setDropDownValue=(type: string, value: any)=> {
        if (type === "resourceType") {
            
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    resourceType: {
                        id: +resourceTypeEnum[value],
                        name: value
                    }
                }
            }
            setResourceItem(dropDownValue);
        }  else if (type === 'reportingTo') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    reportingTo: {
                        id: value.pkId,
                        name: value.firstName
                    }
                }
            }
            setResourceItem(dropDownValue);
            
        } 
        else if (type === 'accessLevel') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    accessLevel: {
                        id: 1,
                        name: value
                    }
                }
            }
            setResourceItem(dropDownValue);

        } 
        else if (type === 'organisation') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    organisation: {
                        id: value.id,
                        name: value.name
                    }
                }
            }
            setProjects([]);
            setTeamsList([])
            getProjects(value.name).then((response: any) => {
                if(response.status=="200"){
                setProjects(response.data.value);  }
               })
            setResourceItem(dropDownValue);

        } 
        else if (type === 'projectAssignedTo') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    projectAssignedTo: {
                        id: value.id,
                        name: value.name
                    }
                }
            }
            setTeamsList([]);
            getTeamId(resourceItemState.resourceItem.organisation.name,value.id).then((response: any) => {
               
                if(response.status=="200"){
                setTeams(response.data.value);  }
               })
            setResourceItem(dropDownValue);

        } 

    
    }
    return (
        uiLoader?(<div className="loader-list">Loading....</div>):(
        <div>
            <Container>
                <Row id="top-bar" className="edit">
                    <Col md={{ span: 3 }} lg={{ span: 3 }} sm={{ span: 3 }}>
                        <span id="work-item-text">
                            <h5>Edit Resource</h5>
                        </span>
                    </Col>
                    <div className="right-holder">
                        {
                            <React.Fragment>
                                <Col
                                    md={{ span: 2 }}
                                    lg={{ span: 2 }}
                                    sm={{ span: 3 }}
                                    className=" pr-0 save-btn-align pr-0"
                                >
                                    <ButtonComponent
                                        style={{
                                            fontSize: '14px',
                                            width: '80px',
                                        }}
                                        name={ButtonName.SAVE}
                                        disabled={
                                            // this.props.match.params.isEdit === "false" ?
                                            // JSON.stringify(this.state.initialWorkItem) === JSON.stringify(this.state.workItem)
                                            // resourceItemState.isSaveDisabled
                                            setButtonStatus()
                                        }
                                        onClick={() => postResourceItemFormData()}
                                    />
                                </Col>
                            </React.Fragment>
                        }
                    </div>
                </Row>
                <Row className="mt-5">
                    
                    <Col>
                    {showAlert && <div> {azureUserStatus}</div>}
                        <Card
                            id="text-editor-form1"
                            className="shadow p-3 mb-5 bg-white rounded"
                        >
                            <Card.Body>
                                <Form>
                                
                                    <Row className="pl-1">
                                        <Col>
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter First Name"
                                                value={
                                                    resourceItemState.resourceItem.firstName
                                                }
                                                required
                                                onChange={(event) => handleResourceItem('firstName', event)}
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                         <Col>
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Last Name"
                                                value={
                                                    resourceItemState.resourceItem.lastName
                                                }
                                                required
                                                onChange={(event) => handleResourceItem('lastName', event)}
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="pl-1">
                                        <Col>
                                            <Form.Label>
                                                Email Address*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Email"
                                                disabled
                                                value={
                                                    resourceItemState.resourceItem.email
                                                }
                                                required
                                                onChange={(event) => handleResourceItem('email', event)}
                                            />
                                            <span className="invalid-feedback">
                                                {resourceItemState.errorMessage}
                                            </span>
                                            </Col>
                                   {/* <Col>
                                        
                                            <Form.Label>
                                                Personal Email
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Personal Email"
                                                value={
                                                    resourceItemState.resourceItem.personalEmail
                                                }
                                                onChange={(event) => handleResourceItem('personalEmail', event)}
                                            />
                                           
                                            </Col> */}
                                    </Row> 
                                    <Row className="pl-1">
                                    <Col>
                                            <Form.Label>
                                                Phone Number*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Phone Number"
                                                type="number"
                                                value={
                                                    resourceItemState.resourceItem.phoneNum
                                                }
                                                required
                                            
                                                onChange={(event) => handleResourceItem('phoneNum', event)}
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Resource ID*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Resource ID"
                                                value={
                                                    resourceItemState.resourceItem.employeeID
                                                }
                                                required
                                                disabled
                                                onChange={(event) => handleResourceItem('employeeID', event)}
                                            />
                                            <span className="invalid-feedback">
                                                {resourceItemState.errorMessageZoho}
                                            </span>
                                            </Col>
                                    </Row>
                                    <Row id ="group-id"className="pl-1">
                                        <React.Fragment>
                                            <Form.Label>
                                               Groups
                                            </Form.Label>
                                           
                                               <Multiselect
                                                options={groups} // Options to display in the dropdown
                                                 selectedValues={groupList} // Preselected value to persist in dropdown
                                               onSelect={onSelect} // Function will trigger on select event
                                               onRemove={onRemove} // Function will trigger on remove event
                                               displayValue= "displayName" // Property name to display in the dropdown options
                                               /> 
                                            
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </React.Fragment>
                                    </Row> 
                                    <Row id="project-data" className="pl-1">
                                        
                                        {/* <Col>
                                            <Form.Label>
                                                Resource Type
                                            </Form.Label>
                                            {
                                                <DropdownButton
                                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                    key={'secondary'}
                                                    id={`dropdown-variants-secondary`}
                                                    variant={'secondary'}
                                                    title={
                                                        resourceItemState.resourceItem.resourceType.name
                                                    }
                                                >
                                                    {[
                                                        resourceTypeEnum[
                                                            resourceTypeEnum
                                                                .Employee
                                                        ],
                                                        resourceTypeEnum[
                                                            resourceTypeEnum
                                                                .Contractor
                                                        ],
                                                    ].map((data, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                eventKey={`${
                                                                    index + 1
                                                                }`}
                                                                // onBlur={() => this.handleOnBlur('Visibility')}
                                                                  onClick={() => setDropDownValue('resourceType', data)}
                                                            >
                                                                {data}
                                                            </Dropdown.Item>
                                                        )
                                                    })}
                                                </DropdownButton>
                                            }
                                        </Col> */}
                                        <Col>
                                            <Form.Label>
                                                Reporting To*
                                            </Form.Label>
                                            {
                                                <DropdownButton
                                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                    key={'secondary'}
                                                    id={`dropdown-variants-secondary`}
                                                    variant={'secondary'}
                                                    title={
                                                        resourceItemState.resourceItem.reportingTo.name
                                                    }
                                                >
                                                    {
                                                    reportingManagers?
                                                    reportingManagers.map((data:any, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={resourceItemState.resourceItem.reportingTo.id?
                                                                     resourceItemState.resourceItem.reportingTo.id:
                                                                    data.pkId}
                                                                eventKey={`${
                                                                    index + 1
                                                                }`}
                                                                // onBlur={() => this.handleOnBlur('Visibility')}
                                                                 onClick={() => setDropDownValue('reportingTo', data)}
                                                            >
                                                                {data.firstName + ' '+ data.lastName  + ' (' + data.employeeID+')'}
                                                            </Dropdown.Item>
                                                        )
                                                    }):reportingManagerError.map((data:any, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={"1"}
                                                                eventKey={`${
                                                                    index + 1
                                                                }`}
                                                                // onBlur={() => this.handleOnBlur('Visibility')}
                                                                //  onClick={() => setDropDownValue('reportingTo', data)}
                                                            >
                                                                {data}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                                </DropdownButton>
                                            }
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    )
}

export default ResourceMangerEdit
