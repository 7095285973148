import React, { Component } from 'react'
import { Badge, Card, Col, Container, Dropdown, DropdownButton, Nav, OverlayTrigger, Popover, Row, Tab, Tabs } from 'react-bootstrap'
import ButtonComponent from '../../Components/Button/Button';
import SearchComponent from '../../Components/Search/Search';
import TaskList from '../../Components/TaskList/TaskList';
import { AppConstant, ButtonName, Routes } from '../../Constants';
import MyItems from './MyItems/MyItems';
import {RouteComponentProps} from "react-router-dom";
import { Link } from 'react-router-dom'
import './TaskContainer.css';
import TemplateComponent from './MyItems/Template';

interface State {
    key: string;
    searchValue: string;
    filterValue: string;
    currentTabId: string | number;
}

export class TaskContainer extends Component<RouteComponentProps, State> {
    state: State = {
        key: AppConstant.HOME,
        searchValue: '',
        filterValue: 'Filter',
        currentTabId: 1
    }

    componentDidMount() {
        if( +this.props.match.params.tasklistId === 1) {
            this.setState({currentTabId: 1})
        } else if(+this.props.match.params.tasklistId === 2) {
            this.setState({currentTabId: 2})
        } else if(+this.props.match.params.tasklistId === 3) {
            this.setState({currentTabId: 3})
        } else if(+this.props.match.params.tasklistId === 4) {
            this.setState({currentTabId: 4})
        }
    }

    setKey(key) {
        this.setState({key: key});
    }
    setSearchValue(event) {
        this.setState({searchValue: event.target.value})
    }

    static getDerivedStateFromProps(props, state) {
        if( +props.match.params.tasklistId === 1) {
            return {
                currentTabId: 1
            }
        } else if(+props.match.params.tasklistId === 2) {
            return {
                currentTabId: 2
            }        
        } else if(+props.match.params.tasklistId === 3) {
            return {
                currentTabId: 3
            }
        } else {
            return {
                currentTabId: 4
            }
        }
    }

    render() {
        return (
            <React.Fragment>
            <Nav variant="tabs" activeKey={`/tasklist/${this.state.currentTabId}`}>
            <Nav.Item>
                <Nav.Link className = {this.state.currentTabId === 1? 'customActiveRoute' : ''}
                as={Link} to={`/${AppConstant.TASK_LIST}/1`}>My Items 
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className = {this.state.currentTabId === 2? 'customActiveRoute' : ''} 
                as={Link} to="/tasklist/2">Watched  
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className = {this.state.currentTabId === 3? 'customActiveRoute' : ''}
                as={Link} to="/tasklist/3">All Items
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link className = {this.state.currentTabId === 4? 'customActiveRoute' : ''}
                as={Link} to="/tasklist/4">Templates
                </Nav.Link>
            </Nav.Item>
            <Nav.Item id = "button-nav">
                {
                    this.state.currentTabId !== 4 ? <OverlayTrigger
                    trigger="click"
                    key={'bottom'}
                    rootClose
                          placement={'bottom'}
                          overlay={
                              <Popover id={`popover-positioned-${'bottom'}`}>
                              <Popover.Content>
                                  <ul id = "popover-list">
                                      <li onClick = {() => {
                                          this.props.history.push('/addworkItem/false/3/0/null/0/null/false/null')
                                        }}>
                                          New WorkItem
                                      </li>
                                      <li onClick = {() => {
                                        this.props.history.push('/addworkItem/false/2/0/null/0/null/false/null')
                                      }}>
                                          New Task
                                      </li>
                                  </ul>
                              </Popover.Content>
                              </Popover>
                          }
                      >
                        <ButtonComponent 
                            style = {{fontSize: '14px'}}
                            name = {ButtonName.CREAT_NEW_WORKITEM} 
                            onClick = {() =>console.log('clicked')}/>
                      </OverlayTrigger>: null
                }
            </Nav.Item>
            </Nav>
                <Card id = "myItemCard">
                <Card.Body>
                    {
                        +this.props.match.params.tasklistId === 4 ? <TemplateComponent {...this.props}/> : <MyItems {...this.props}/>
                    }
                </Card.Body>
                </Card>
            </React.Fragment>
        )
    }
}

export default TaskContainer
