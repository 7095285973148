import ButtonComponent from 'Components/Button/Button'
import MyStatefulEditor from 'Components/Text-Editor/TextEditor'
import './ResourceManagerTool.css'
import { ButtonName } from 'Constants'
import moment from 'moment'
import React, { useEffect } from 'react'
import axios from 'axios'
import { API_URL } from 'Services/Constants'
import { Multiselect } from 'multiselect-react-dropdown'

import {
    Badge,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    ListGroup,
    ListGroupItem,
    Nav,
    OverlayTrigger,
    Popover,
    Row,
    Tab,
} from 'react-bootstrap'
import { useState } from 'react'
import {
    assignGroupsToUser,
    assignOrganisationToUser,
    createZohoUser,
    getGroups,
    getOrganisations,
    getProjects,
    getReportingManagers,
    getTeamId,
    sendResourceItemForm,
    assignProjectToUser,
    addToProjectWithOutOrg,
    getZohoNextEmployeeId,
} from 'Services/ResourceService/ResourceService'

interface State {
    filterValue: string
    isSaveDisabled: boolean
    inputDisabled: boolean
    isAdmin: boolean
    // userList: Array<any>;
    resourceItem: any
    errorMessage: string
    errorMessageZoho: string
    teamId: string
}

export enum resourceTypeEnum {
    Employee = 2,
    Contractor = 1,
}

let accessLevelArray = [
    {
        name: 'Basic',
        licensingSource: 1,
        accountLicenseType: 2,
        msdnLicenseType: 0,
        assignmentSource: 1,
    },

    {
        name: 'Stakeholder',
        licensingSource: 1,
        accountLicenseType: 5,
        msdnLicenseType: 0,
        assignmentSource: 1,
    },

    {
        name: 'Visual Studio Subscriber',
        licensingSource: 2,
        accountLicenseType: 0,
        msdnLicenseType: 1,
        assignmentSource: 1,
    },
]
function ResourceManagerTool(state: State) {
    state = {
        filterValue: 'Ramneek',
        isSaveDisabled: true,
        inputDisabled: false,
        isAdmin: false,
        errorMessage: '',
        errorMessageZoho: '',
        teamId: '',
        resourceItem: {
            firstName: '',
            lastName: '',
            email: '',
            personalEmail: '',
            phoneNum: '',
            employeeID: '',

            resourceType: {
                id: 0,
                name: 'Resource Type',
            },
            reportingTo: {
                id: 0,
                name: 'Reporting To',
            },
            accessLevel: {
                id: 0,
                licensingSource: 0,
                accountLicenseType: 0,
                msdnLicenseType: 0,
                assignmentSource: 0,
                name: 'Access Level',
            },
            organisation: {
                id: 0,
                name: 'Organisation',
            },
            projectAssignedTo: {
                id: 0,
                name: 'Projects',
            },
        },
        // errors: null
    }
    const [resourceItemState, setResourceItem] = useState(state)
    const [groupList, setGroupList]: any = useState([])
    const [groups, setGroups] = useState([])
    const [projects, setProjects]: any = useState([])
    const [reportingManagers, setReportingManagers]: any = useState([])
    const [organisations, setOrganisations]: any = useState([])
    const [teamsList, setTeamsList]: any = useState([])
    const [teams, setTeams]: any = useState([])
    const [zohoUserStatus, setZohoUserStatus] = useState('')
    const [azureUserStatus, setAzureUserStatus] = useState(
        'User has been created in dev-op'
    )
    const [showAlert, setShowAlert] = useState(false)
    // const [errorMessage, setErrorMessage]=useState("");
    const [uiLoader, setUiLoader] = useState(true)
    const [reportingManagerLoader, setReportingManagerLoader] = useState(true)
    const roleName = localStorage.getItem('adminRole')
    const [empId, setEmpId]: any = useState()
    useEffect(() => {
        getGroups()
            .then((response: any) => {
                setGroups(response.data)
            })
            .catch((error) => console.log(error, ' error'))

        //    getProjects().then((response: any) => {
        //      setProjects(response.data.value);
        //     })

        getReportingManagers()
            .then((response: any) => {
                setReportingManagers(response.data)
                setReportingManagerLoader(false)
            })
            .catch((error) => console.log(error, ' error'))
        getOrganisations()
            .then((response: any) => {
                setOrganisations(
                    response.data.dataProviders
                        .msVssFeaturesMyOrganizationsDataProvider.organizations
                )
            })
            .catch((error) => console.log(error, ' error'))
        getZohoNextEmployeeId()
            .then((response: any) => {
                let resourceItem = {
                    ...resourceItemState.resourceItem,
                    employeeID: response.data,
                }

                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })

                setUiLoader(false)
            })
            .catch((error) => console.log(error, ' error'))
        return () => {}
    }, [])

    const onSelect = (selectedList, selectedItem) => {
        setGroupList(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
        setGroupList(selectedList)
    }

    const onSelectTeams = (selectedList, selectedItem) => {
        setTeamsList(selectedList)
    }

    const onRemoveTeams = (selectedList, removedItem) => {
        setTeamsList(selectedList)
    }

    const onSelectProject = (selectedList, selectedItem) => {
        let resourceItem = {
            ...resourceItemState.resourceItem,
            projectAssignedTo: selectedList,
        }
        setResourceItem({
            ...resourceItemState,
            resourceItem: resourceItem,
        })
    }

    const onRemoveProject = (selectedList, removedItem) => {
        let resourceItem = {
            ...resourceItemState.resourceItem,
            projectAssignedTo: selectedList,
        }
        setResourceItem({
            ...resourceItemState,
            resourceItem: resourceItem,
        })
    }

    const postResourceItemFormData = () => {
        const { resourceItem } = resourceItemState
        const thisref = this
        // setUILoaderState(true)
        let obj = {
            forceChangePasswordNextSignIn: false,
            password: 'optimus@1234',
        }
        let formData = new FormData()

        let formObj = {
            accountEnabled: true,
            displayName: resourceItem.firstName + ' ' + resourceItem.lastName,
            givenName: resourceItem.firstName,
            surname: resourceItem.lastName,
            preferredLanguage: 'en-US',
            mobilePhone: resourceItem.phoneNum,
            userPrincipalName: resourceItem.email,
            passwordProfile: obj,
            mailNickname: resourceItem.firstName,
        }

        // if (resourceItem.project.id != 0 && resourceItem.project.id !== null)
        //     formData.append('ProjectId', resourceItem.project.id)

        // if (this.props.match.params.isEdit === "false") {
        let userZohoData = {
            employeeID: resourceItem.employeeID,
            firstName: resourceItem.firstName,
            lastName: resourceItem.lastName,
            emailID: resourceItem.email,
            Reporting_To: resourceItem.reportingTo.id,
            mobile:resourceItem.phoneNum
            
        }
        createZohoUser(userZohoData).then((response: any) => {
            if (!(response.data.response.status == '1')) {
            } else {
            }

            //     setResourceItem({
            //         ...resourceItemState,
            //         errorMessageZoho: "User already exists with this employee ID or Email-Id"
            //     })
            // }
            // else{

            // }
        })

        sendResourceItemForm(formObj, resourceItem.personalEmail)
            .then((response: any) => {
                let teamId = ''

                //     getTeamId(resourceItem.organisation.name,resourceItem.projects.id).then((response: any) => {
                //         console.log(response.data , " responseee");

                //         setResourceItem({
                //             ...resourceItemState,
                //             teamId: "User already exists"
                //         })
                //    })

                if (response.status == '200') {
                    let userData = JSON.parse(
                        response.data.replace('Created', '')
                    )

                    let userGroups: any = []

                    for (let i = 0; i < groupList.length; i++) {
                        let groupPostObj: any = {
                            id: `member_${groupList[i].id}_${userData.id}`,
                            method: 'POST',
                            url: `/groups/${groupList[i].id}/members/$ref`,
                            headers: { 'Content-Type': 'application/json' },
                            body: {
                                '@odata.id': `https://graph.microsoft.com/beta/directoryObjects/${userData.id}`,
                            },
                        }
                        userGroups.push(groupPostObj)
                    }

                    let userGroupObject = {
                        requests: userGroups,
                    }

                    let userOrganisationObject = [
                        {
                            from: '',
                            op: 0,
                            path: '',
                            value: {
                                accessLevel: {
                                    licensingSource:
                                        resourceItem.accessLevel
                                            .licensingSource,
                                    accountLicenseType:
                                        resourceItem.accessLevel
                                            .accountLicenseType,
                                    msdnLicenseType:
                                        resourceItem.accessLevel
                                            .msdnLicenseType,
                                    licenseDisplayName:
                                        resourceItem.accessLevel.name,
                                    status: 0,
                                    statusMessage: '',
                                    assignmentSource:
                                        resourceItem.accessLevel
                                            .assignmentSource,
                                },
                                user: {
                                    displayName:
                                        resourceItem.firstName +
                                        ' ' +
                                        resourceItem.lastName,
                                    origin: 'aad',
                                    principalName: resourceItem.email,
                                    subjectKind: 'user',
                                },
                            },
                        },
                    ]
                    //TODO LATER
                    // let userProjects: any = []

                    // for (let i = 0; i < teamsList.length; i++) {
                    //     let projectPostObj: any = {
                    //         from: '',
                    //         op: 0,
                    //         path: `/${userData.id}/projectEntitlements/${resourceItem.projectAssignedTo.id}/teamRefs`,
                    //         value: { id: `${teamsList[i].id}` },
                    //     }
                    //     userProjects.push(projectPostObj)
                    // }
                    let teamProjects: any = []
                    for (let i = 0; i < teamsList.length; i++) {
                        let teamPostObj: any = {
                            id: teamsList[i].id,
                        }
                        teamProjects.push(teamPostObj)
                    }
                    let userProjects = [
                        {
                            from: '',
                            op: 0,
                            path: '',
                            value: {
                                projectEntitlements: [
                                    {
                                        projectRef: {
                                            id:
                                                resourceItem.projectAssignedTo
                                                    .id,
                                        },
                                        teamRefs: teamProjects,
                                    },
                                ],
                                user: {
                                    displayName:
                                        resourceItem.firstName +
                                        ' ' +
                                        resourceItem.lastName,
                                    origin: 'aad',
                                    originId: userData.id,
                                    principalName: resourceItem.email,
                                    subjectKind: 'user',
                                },
                            },
                        },
                    ]

                    setShowAlert(true)
                    //  setResourceItem(state)
                    //  setGroupList([]);
                    assignGroupsToUser(
                        userGroupObject
                    ).then((response: any) => {})

                    setTimeout(() => {
                        //TODO LATER

                        // assignOrganisationToUser(userOrganisationObject).then(
                        //     (response: any) => {
                        //         if (response.status == '200') {
                        //             assignProjectToUser(
                        //                 userProjects
                        //             ).then((response: any) => {})
                        //         }
                        //     }
                        // )
                        addToProjectWithOutOrg(
                            userProjects
                        ).then((response: any) => {})

                        //setShowAlert(false);
                    }, 80000)

                    setTimeout(() => {
                        setShowAlert(false)
                        setResourceItem(state)
                        setGroupList([])
                        setTeamsList([])
                    }, 10000)
                    // setErrorMessage("")
                } else {
                    // setErrorMessage("User already exists")
                    setResourceItem({
                        ...resourceItemState,
                        errorMessage: 'User already exists or Invalid Email Id',
                    })
                }
            })
            .catch((error) => {
                // setErrorMessage("User already exists")
                // this is the part you need that catches 400 request //gauravtest@optimusinfo.com
            })
    }

    const setButtonStatus = () => {
        if (
            !groupList ||
            groupList.length === 0 ||
            !teamsList ||
            teamsList.length === 0 ||
            !resourceItemState.resourceItem.firstName ||
            !resourceItemState.resourceItem.personalEmail ||
            !resourceItemState.resourceItem.lastName ||
            !resourceItemState.resourceItem.email ||
            !resourceItemState.resourceItem.phoneNum ||
            !resourceItemState.resourceItem.employeeID ||
            // || resourceItemState.resourceItem.reportingTo.id == 0
            resourceItemState.resourceItem.organisation.id == 0 ||
            resourceItemState.resourceItem.projectAssignedTo.id == 0
        ) {
            return true
        }
    }

    const handleResourceItem = (type: string, event) => {
        let resourceItem
        switch (type) {
            case 'firstName':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    firstName: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break
            case 'lastName':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    lastName: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break
            case 'email':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    email: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break

            case 'personalEmail':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    personalEmail: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break
            case 'phoneNum':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    phoneNum: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break
            case 'employeeID':
                resourceItem = {
                    ...resourceItemState.resourceItem,
                    employeeID: event.target.value,
                }
                setResourceItem({
                    ...resourceItemState,
                    resourceItem: resourceItem,
                })
                break
        }
        // setResourceItem({
        //     ...resourceItemState,isSaveDisabled: false
        // })
    }

    const setDropDownValue = (type: string, value: any) => {
        if (type === 'resourceType') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    resourceType: {
                        id: +resourceTypeEnum[value],
                        name: value,
                    },
                },
            }
            setResourceItem(dropDownValue)
        } else if (type === 'reportingTo') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    reportingTo: {
                        id: value.pkId,
                        name: value.firstName,
                    },
                },
            }
            setResourceItem(dropDownValue)
        } else if (type === 'accessLevel') {
            const dropDownValue = {
                ...resourceItemState,

                resourceItem: {
                    ...resourceItemState.resourceItem,
                    accessLevel: {
                        id: 1,
                        licensingSource: value.licensingSource,
                        accountLicenseType: value.accountLicenseType,
                        msdnLicenseType: value.msdnLicenseType,
                        assignmentSource: value.assignmentSource,
                        name: value.name,
                    },
                },
            }
            setResourceItem(dropDownValue)
        } else if (type === 'organisation') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    organisation: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setProjects([])
            setTeamsList([])
            getProjects(value.name).then((response: any) => {
                if (response.status == '200') {
                    setProjects(response.data.value)
                }
            })
            setResourceItem(dropDownValue)
        } else if (type === 'projectAssignedTo') {
            const dropDownValue = {
                ...resourceItemState,
                resourceItem: {
                    ...resourceItemState.resourceItem,
                    projectAssignedTo: {
                        id: value.id,
                        name: value.name,
                    },
                },
            }
            setTeamsList([])
            getTeamId(
                resourceItemState.resourceItem.organisation.name,
                value.id
            ).then((response: any) => {
                if (response.status == '200') {
                    setTeams(response.data.value)
                }
            })
            setResourceItem(dropDownValue)
        }
    }
    return uiLoader || roleName != 'Admin' ? (
        <div className="loader-list">Loading...</div>
    ) : (
        <div>
            <Container>
                <Row id="top-bar" className="edit">
                    <Col md={{ span: 3 }} lg={{ span: 3 }} sm={{ span: 3 }}>
                        <span id="work-item-text">
                            <h5>Add Resource</h5>
                        </span>
                    </Col>
                    <div className="right-holder">
                        {
                            <React.Fragment>
                                <Col
                                    md={{ span: 2 }}
                                    lg={{ span: 2 }}
                                    sm={{ span: 3 }}
                                    className=" pr-0 save-btn-align pr-0"
                                >
                                    <ButtonComponent
                                        style={{
                                            fontSize: '14px',
                                            width: '80px',
                                        }}
                                        name={ButtonName.SAVE}
                                        disabled={
                                            // this.props.match.params.isEdit === "false" ?
                                            // JSON.stringify(this.state.initialWorkItem) === JSON.stringify(this.state.workItem)
                                            // resourceItemState.isSaveDisabled
                                            setButtonStatus()
                                        }
                                        onClick={() =>
                                            postResourceItemFormData()
                                        }
                                    />
                                </Col>
                            </React.Fragment>
                        }
                    </div>
                </Row>
                <Row className="mt-5">
                    <Col>
                        {showAlert && <div> {azureUserStatus}</div>}
                        <Card
                            id="text-editor-form1"
                            className="shadow p-3 mb-5 bg-white rounded"
                        >
                            <Card.Body>
                                <Form>
                                    <Row className="pl-1">
                                        <Col>
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter First Name"
                                                value={
                                                    resourceItemState
                                                        .resourceItem.firstName
                                                }
                                                required
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'firstName',
                                                        event
                                                    )
                                                }
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                        <Col>
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Last Name"
                                                value={
                                                    resourceItemState
                                                        .resourceItem.lastName
                                                }
                                                required
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'lastName',
                                                        event
                                                    )
                                                }
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="pl-1">
                                        <Col>
                                            <Form.Label>
                                                Email Address*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Email"
                                                type="email"
                                                value={
                                                    resourceItemState
                                                        .resourceItem.email
                                                }
                                                required
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'email',
                                                        event
                                                    )
                                                }
                                            />
                                            <span className="invalid-feedback">
                                                {resourceItemState.errorMessage}
                                            </span>
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Personal Email*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Personal Email"
                                                type="email"
                                                value={
                                                    resourceItemState
                                                        .resourceItem
                                                        .personalEmail
                                                }
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'personalEmail',
                                                        event
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pl-1">
                                        <Col>
                                            <Form.Label>
                                                Phone Number*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Phone Number"
                                                type="number"
                                                value={
                                                    resourceItemState
                                                        .resourceItem.phoneNum
                                                }
                                                required
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'phoneNum',
                                                        event
                                                    )
                                                }
                                            />
                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Resource ID*
                                            </Form.Label>
                                            <Form.Control
                                                className="set-input-width"
                                                placeholder="Enter Resource ID"
                                                value={
                                                    resourceItemState
                                                        .resourceItem.employeeID
                                                }
                                                required
                                                onChange={(event) =>
                                                    handleResourceItem(
                                                        'employeeID',
                                                        event
                                                    )
                                                }
                                            />
                                            <span className="invalid-feedback">
                                                {
                                                    resourceItemState.errorMessageZoho
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row id="group-id" className="pl-1">
                                        <React.Fragment>
                                            <Form.Label>Groups</Form.Label>

                                            <Multiselect
                                                options={groups} // Options to display in the dropdown
                                                selectedValues={groupList} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="displayName" // Property name to display in the dropdown options
                                            />

                                            <span className="invalid-feedback">
                                                {/* {errors && errors.nameError} */}
                                            </span>
                                        </React.Fragment>
                                    </Row>
                                    <Row id="project-data" className="pl-1">
                                        <Col>
                                            <Form.Label>
                                                Reporting To*
                                            </Form.Label>
                                           {reportingManagerLoader? <div>Loading..</div> :
                                                <DropdownButton
                                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                    key={'secondary'}
                                                    id={`dropdown-variants-secondary`}
                                                    variant={'secondary'}
                                                    title={
                                                        resourceItemState
                                                            .resourceItem
                                                            .reportingTo.name
                                                    }
                                                >
                                                    {reportingManagers
                                                        .sort(
                                                            (first, second) => {
                                                                return first.firstName >
                                                                    second.firstName
                                                                    ? 1
                                                                    : -1
                                                            }
                                                        )
                                                        .map(
                                                            (
                                                                data: any,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                        eventKey={`${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                        // onBlur={() => this.handleOnBlur('Visibility')}
                                                                        onClick={() =>
                                                                            setDropDownValue(
                                                                                'reportingTo',
                                                                                data
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.firstName +
                                                                            ' ' +
                                                                            data.lastName +
                                                                            ' (' +
                                                                            data.employeeID +
                                                                            ')'}
                                                                    </Dropdown.Item>
                                                                )
                                                            }
                                                        )}
                                                </DropdownButton>
                                            }
                                        </Col>
                                    </Row>
                                    <Row id="project-data2" className="pl-1">
                                        <Col>
                                            <Form.Label>
                                                Organisation
                                            </Form.Label>
                                            {
                                                <DropdownButton
                                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                    key={'secondary'}
                                                    id={`dropdown-variants-secondary`}
                                                    variant={'secondary'}
                                                    title={
                                                        resourceItemState
                                                            .resourceItem
                                                            .organisation.name
                                                    }
                                                >
                                                    {organisations
                                                        .sort(
                                                            (first, second) => {
                                                                return first.name >
                                                                    second.name
                                                                    ? 1
                                                                    : -1
                                                            }
                                                        )
                                                        .map(
                                                            (
                                                                data: any,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                        eventKey={`${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                        // onBlur={() => this.handleOnBlur('Visibility')}
                                                                        onClick={() =>
                                                                            setDropDownValue(
                                                                                'organisation',
                                                                                data
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </Dropdown.Item>
                                                                )
                                                            }
                                                        )}
                                                </DropdownButton>
                                            }
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Access Level
                                            </Form.Label>
                                            {
                                                <DropdownButton
                                                    className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                    key={'secondary'}
                                                    id={`dropdown-variants-secondary`}
                                                    variant={'secondary'}
                                                    title={
                                                        resourceItemState
                                                            .resourceItem
                                                            .accessLevel.name
                                                    }
                                                >
                                                    {accessLevelArray.map(
                                                        (data, index) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    eventKey={`${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    // onBlur={() => this.handleOnBlur('Visibility')}
                                                                    onClick={() =>
                                                                        setDropDownValue(
                                                                            'accessLevel',
                                                                            data
                                                                        )
                                                                    }
                                                                >
                                                                    {data.name}
                                                                </Dropdown.Item>
                                                            )
                                                        }
                                                    )}
                                                </DropdownButton>
                                            }
                                        </Col>
                                    </Row>
                                    <Row id="project-data2" className="pl-1">
                                        <Col>
                                            <React.Fragment>
                                                <Form.Label>
                                                    Project Assigned To
                                                </Form.Label>

                                                {/* <Multiselect
                                                options={projects} // Options to display in the dropdown
                                                  selectedValues={resourceItemState.resourceItem.projectAssignedTo} // Preselected value to persist in dropdown
                                               onSelect={onSelectProject} // Function will trigger on select event
                                               onRemove={onRemoveProject} // Function will trigger on remove event
                                               displayValue= "name" // Property name to display in the dropdown options
                                               />  */}
                                                {
                                                    <DropdownButton
                                                        className={`align-dropdown 
                                        assign-title-center dropdown-font`}
                                                        key={'secondary'}
                                                        id={`dropdown-variants-secondary`}
                                                        variant={'secondary'}
                                                        title={
                                                            resourceItemState
                                                                .resourceItem
                                                                .projectAssignedTo
                                                                .name
                                                        }
                                                    >
                                                        {projects
                                                            .sort(
                                                                (
                                                                    first,
                                                                    second
                                                                ) => {
                                                                    return first.name >
                                                                        second.name
                                                                        ? 1
                                                                        : -1
                                                                }
                                                            )
                                                            .map(
                                                                (
                                                                    data: any,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={
                                                                                index
                                                                            }
                                                                            eventKey={`${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                            // onBlur={() => this.handleOnBlur('Visibility')}
                                                                            onClick={() =>
                                                                                setDropDownValue(
                                                                                    'projectAssignedTo',
                                                                                    data
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                data.name
                                                                            }
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            )}
                                                    </DropdownButton>
                                                }

                                                <span className="invalid-feedback">
                                                    {/* {errors && errors.nameError} */}
                                                </span>
                                            </React.Fragment>
                                        </Col>
                                        <Col>
                                            <React.Fragment>
                                                <Form.Label>Teams</Form.Label>

                                                <Multiselect
                                                    options={teams} // Options to display in the dropdown
                                                    selectedValues={teamsList} // Preselected value to persist in dropdown
                                                    onSelect={onSelectTeams} // Function will trigger on select event
                                                    onRemove={onRemoveTeams} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                />

                                                <span className="invalid-feedback">
                                                    {/* {errors && errors.nameError} */}
                                                </span>
                                            </React.Fragment>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ResourceManagerTool
