export const NavigationConst = [
    {   
        imageUrl: "home",
        name: "Home",
        link: "/home"
    },
    {
        imageUrl: "apps",
        name: "Applications",
        link: "/applications"
    },
    /*{
        imageUrl: "campaign",
        name: "Announcements",
        link: "/announcements"
    },
    {
        imageUrl: "dashboard",
        name: "Dashboards",
        link: "/dashboard_new"
    },*/
    {   
        imageUrl: "bookmarks",
        name: "Work Items",
        link: "/taskklist/1"
    },
    /*{   
        imageUrl: "bar_chart",
        name: "Reports",
        link: "/reports"
    },*/
    {   
        imageUrl: "policy",
        name: "Policies",
        link: "/policies"
    },
    {   
        imageUrl: "local_activity",
        name: "Support Ticket",
        link: "/supportTicket"
    },
    // {   
    //     imageUrl: "newspaper ",
    //     name: "Projection",
    //     link: "/projections"
    // },
    // {   
    //     imageUrl: "person",
    //     name: "RM Tools",
    //     link: "/resourceManagerTools"
    // },
    
    
]